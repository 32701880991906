//@flow

import React, { Component } from 'react';
import { ScrollView, Text, Image, View } from 'react-native';
import { Images, Metrics } from '../Themes';

import FormattedMessage from '../Components/FormattedMessage';

// Styles
import styles from './Styles/ScreenStyles';

type Props = {};

export default class LaunchScreen extends Component<Props> {
  render() {
    return (
      <View style={styles.mainContainer}>
        <ScrollView style={styles.container}>
          <View style={styles.centered}>
            <Image source={Images.launch} style={styles.logo} />
          </View>

          <View style={styles.section}>
            <Image source={Images.ready} />
            <FormattedMessage
              style={styles.sectionText}
              id="progressiveImageComponent"
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}
