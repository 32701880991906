import immutablePersistenceTransform from '../Services/ImmutablePersistenceTransform';
import { AsyncStorage } from 'react-native';
import isDev from '../Lib/isDev';

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1.3', // Latest Version '1.3',
  storeConfig: {
    key: 'primary',
    storage: AsyncStorage,
    // Reducer keys that you do NOT want stored to persistence here.
    // blacklist: [],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    whitelist: ['persist', 'i18n'], // !isDev() && 'i18n'
    transforms: [immutablePersistenceTransform]
  }
};

export default REDUX_PERSIST;
