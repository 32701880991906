import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import ScanID from '../Components/ScanID';

// Styles
import styles from './Styles/ScreenStyles';
import DATA_STRUCTURE from '../Config/DataStructureConfig';

const BASE = DATA_STRUCTURE.household_members;

class HouseholdMembersIdentificationScreen extends Component {
  onComplete = data => {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;
    history.push(`/HouseholdMember/${index}/Information`);
  };

  render() {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;
    return (
      <View style={styles.mainContainer}>
        <ScanID
          base={BASE}
          index={index}
          onComplete={this.onComplete}
          onCancel={this.onComplete}
        />
      </View>
    );
  }
}

const mapStateToProps = ({
  application: { application },
  app: { initilized }
}) => ({
  application,
  initilized
});

export default connect(mapStateToProps)(HouseholdMembersIdentificationScreen);
