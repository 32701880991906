import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Image, TouchableOpacity, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';

import { I18n } from 'react-redux-i18n';

// import Icon from 'react-native-vector-icons/dist/MaterialIcons';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';
import { isKiosk } from '../Redux/AccountRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupSelect from '../Components/FormGroupSelect';
import FormGroupRadio from '../Components/FormGroupRadio';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FullButton from '../Components/FullButton';
import UploadDocumentType from '../Components/UploadDocumentType';

import {
  getBaseString,
  getPersonId,
  getBase,
  isRequired,
  isPhoneNumber,
  isEmail,
  isZipcode,
  phoneFormat,
  zipcodeFormat,
  isRequiredPhoneNumber,
  isRequiredEmail
} from '../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import {
  TRUE_OPTION,
  FALSE_OPTION,
  DEFAULT_STATE_CODE,
  ADDRESS_TYPE_PO_BOX,
  ADDRESS_TYPE_RURAL_ROUTE,
  ADDRESS_TYPE_HIGHWAY_CONTRACT,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_APARTMENT,
  YES_OPTION,
  NO_OPTION
} from '../Config/ApplicationConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import AddressForm from '../Components/AddressForm';

const BASE = DATA_STRUCTURE.lien_holders;
const DOCUMENT_TYPE = DOCUMENT_TYPES.lienDocument.code;
const DOCUMENT_KEYWORD_VALUE = '';

class LienHolderInformation extends Component {
  // static propTypes = {
  //   base: PropTypes.string.isRequired,
  //   index: PropTypes.number,
  //   navigation: PropTypes.object.isRequired,
  //   onComplete: PropTypes.func.isRequired
  // };

  state = {
    editingIndex: null,
    documentErrorText: null,
    errorText: null
  };

  refs = {};

  formInitilized = false;
  formInitilized = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;
  savingApplication = false;

  componentWillMount() {
    const { initilized, currentForm } = this.props;

    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.initilizeForm(this.props);
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      setSubmitting,
      setStatus,
      status,
      isSubmitting
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
        if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        history.push('/LienOnPropertyScreen');
      }
    }

    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.initilizeForm(nextProps);
    // }

    // if (this.loadingDocuments && !documentsFetching) {
    //   this.loadingDocuments = false;
    //   if (error) {
    //     this.setState({ doumentErrorText: documentsError });
    //   }
    // }
    // if (this.uploadingDocuments && !documentsFetching) {
    //   this.uploadingDocuments = false;
    //   if (error) {
    //     this.setState({ doumentErrorText: documentsError });
    //   }
    // }

    // if (this.savingApplication && !fetching) {
    //   this.savingApplication = false;
    //   if (error) {
    //     this.setState({ errorText: error });
    //   } else {
    //     onComplete();
    //   }
    // }
  }

  // initilizeForm(props) {
  //   const {
  //     base, index, application, change,
  //   } = props;

  //   const baseString = getBaseString(base, index);

  //   this.formInitilized = true;

  //   // Set Default State to NC
  //   if (!application || !application.lienHolder || !application.lienHolder.state) {
  //     console.log('setting default state', DEFAULT_STATE_CODE);
  //     change(`${baseString}.stateCode`, DEFAULT_STATE_CODE);
  //   }

  //   // Split Address
  //   if (R.path([base, index, 'address'], application)) {
  //     const split = R.path([base, index, 'address'], application).split('\n');
  //     change(`${baseString}.address1`, split[0]);
  //     change(`${baseString}.address2`, split[1]);
  //   }
  // }

  // handleUploadFiles = photos => {
  //   console.log(photos);

  //   const { base, index, application, uploadDocuments } = this.props;

  //   const personId = getPersonId(application, base, index);

  //   // if (token) {

  //   this.uploadDocuments = true;
  //   console.log(personId, photos, DOCUMENT_TYPE, DOCUMENT_KEYWORD_VALUE);
  //   uploadDocuments(personId, photos, DOCUMENT_TYPE, DOCUMENT_KEYWORD_VALUE);
  // };

  // handleDeleteFiles = () => {
  //   const { application, deleteDocuments, base, index } = this.props;

  //   const personId = getPersonId(application, base, index);

  //   this.deletingDocuments = true;
  //   console.log(
  //     'deleteDocuments',
  //     personId,
  //     DOCUMENT_TYPE,
  //     DOCUMENT_KEYWORD_VALUE
  //   );
  //   deleteDocuments(personId, DOCUMENT_TYPE, DOCUMENT_KEYWORD_VALUE);
  // };

  // onSubmit = (values) => {
  //   const { updateApplication, base, index } = this.props;

  //   // let newValues = Immutable.asMutable(values) // Does not work
  //   let newValues = JSON.parse(JSON.stringify(values));

  //   // Concat Address
  //   if (newValues[base][index].address1) {
  //     if (newValues[base][index].address2) {
  //       newValues[base][index].address = `${newValues[base][index].address1}\n${
  //         newValues[base][index].address2
  //       }`;
  //     } else {
  //       newValues[base][index].address = newValues[base][index].address1;
  //     }
  //     delete newValues[base][index].address1;
  //     delete newValues[base][index].address2;
  //   }

  //   this.savingApplication = true;
  //   updateApplication(newValues);
  // };

  // renderDocuments(documents) {
  //   const { docTypes } = this.props;

  //   const filteredDocuments = {};
  //   documents &&
  //     documents.forEach((ele, idx) => {
  //       if (ele.documentType === DOCUMENT_TYPE) {
  //         if (!filteredDocuments[ele.keywordValue]) {
  //           filteredDocuments[ele.keywordValue] = {
  //             keywordValue: ele.keywordValue,
  //             fileDownloadURL: ele.fileDownloadURL,
  //             count: 1
  //           };
  //         } else {
  //           filteredDocuments[ele.keywordValue].count++;
  //         }
  //       }
  //     });

  //   const rows = Object.keys(filteredDocuments).map((key, idx) => {
  //     const ele = filteredDocuments[key];
  //     const plusCount = ele.count - 1;
  //     const plusText = ele.count > 1 && (
  //       <Text
  //         style={[
  //           Fonts.style.normal,
  //           {
  //             fontFamily: Fonts.type.bold,
  //             color: Colors.white,
  //             backgroundColor: 'rgba(0,0,0,0.5)',
  //             padding: Metrics.baseMargin
  //           }
  //         ]}
  //       >
  //         + {plusCount}
  //       </Text>
  //     );

  //     return (
  //       <Row
  //         key={idx}
  //         style={{
  //           height: 90,
  //           borderWidth: 1,
  //           padding: Metrics.baseMargin,
  //           marginHorizontal: 0,
  //           borderColor: Colors.lightGrey,
  //           marginBottom: Metrics.baseMargin,
  //           justifyContent: 'center',
  //           alignItems: 'center'
  //         }}
  //       >
  //         <Col
  //           xs={3}
  //           style={{ justifyContent: 'center', alignItems: 'center' }}
  //         >
  //           {ele.fileDownloadURL && (
  //             <Image
  //               source={{ uri: ele.fileDownloadURL }}
  //               style={{
  //                 position: 'absolute',
  //                 width: 90,
  //                 height: 90,
  //                 resizeMode: 'contain'
  //               }}
  //             />
  //           )}
  //           {plusText}
  //         </Col>
  //         <Col
  //           xs={6}
  //           style={{ justifyContent: 'center', alignItems: 'flex-start' }}
  //         >
  //           <Text numberOfLines={2} style={Fonts.style.normal}>
  //             {docTypes[DOCUMENT_TYPE][ele.keywordValue]}
  //           </Text>
  //         </Col>
  //         <Col
  //           xs={3}
  //           style={{ justifyContent: 'center', alignItems: 'flex-end' }}
  //         >
  //           <TouchableOpacity
  //             style={{
  //               padding: Metrics.baseMargin,
  //               marginRight: Metrics.baseMargin,
  //               borderRadius: 5,
  //               backgroundColor: '#aaa'
  //             }}
  //             onPress={this.handleDeleteFiles.bind(this, key)}
  //           >
  //             <Icon name="md-trash" size={20} />
  //           </TouchableOpacity>
  //         </Col>
  //       </Row>
  //     );
  //     // }
  //   });

  //   return (
  //     rows && (
  //       <View>
  //         <Row style={{ padding: Metrics.baseMargin, marginHorizontal: 0 }}>
  //           <Col
  //             xs={3}
  //             style={{ justifyContent: 'center', alignItems: 'center' }}
  //           >
  //             <FormattedMessage
  //               id="Image"
  //               style={[Fonts.style.normal, { fontWeight: 'bold' }]}
  //             />
  //           </Col>
  //           <Col
  //             xs={9}
  //             style={{ justifyContent: 'center', alignItems: 'flex-start' }}
  //           >
  //             <FormattedMessage
  //               id="Type"
  //               style={[Fonts.style.normal, { fontWeight: 'bold' }]}
  //             />
  //           </Col>
  //         </Row>
  //         {rows}
  //       </View>
  //     )
  //   );
  // }

  renderAddress(addressTypeCode) {
    const {
      fetching,
      match: {
        params: { index }
      }
    } = this.props;
    let addressHeader = null;
    const baseString = getBaseString(BASE, index);
    switch (addressTypeCode) {
      default:
      case ADDRESS_TYPE_PO_BOX:
        addressHeader = (
          <Row>
            <Col sm={12}>
              <FormGroupText
                ref={ref => {
                  this._boxNumber = ref;
                }}
                field={`${baseString}.boxNumber`}
                labelId="ContactInfo.boxNumber"
                placeholderId="ContactInfo.boxNumber_placeholder"
                autoCapitalize="words"
                returnKeyType="next"
                onSubmitEditing={() => this._City.focus()}
                required
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
          </Row>
        );
        break;
      case ADDRESS_TYPE_RURAL_ROUTE:
      case ADDRESS_TYPE_HIGHWAY_CONTRACT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address1 = ref;
                  }}
                  field={`${baseString}.RRHCNumber`}
                  labelId="ContactInfo.RRHCNumber"
                  placeholderId="ContactInfo.RRHCNumber_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._boxNumber.focus()}
                  required
                  validate={isRequired}
                  disabled={fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._boxNumber = ref;
                  }}
                  field={`${baseString}.boxNumber`}
                  labelId="ContactInfo.boxNumber"
                  placeholderId="ContactInfo.boxNumber_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  required
                  validate={isRequired}
                  disabled={fetching}
                />
              </Col>
            </Row>
          </View>
        );
        break;
      case ADDRESS_TYPE_RESIDENTIAL:
      case ADDRESS_TYPE_APARTMENT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address = ref;
                  }}
                  field={`${baseString}.address`}
                  labelId="ContactInfo.address1"
                  placeholderId="ContactInfo.address1_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address2.focus()}
                  required
                  validate={isRequired}
                  disabled={fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address2 = ref;
                  }}
                  field={`${baseString}.address2`}
                  labelId="ContactInfo.address2"
                  placeholderId="ContactInfo.address2_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address3.focus()}
                  disabled={fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address3 = ref;
                  }}
                  field={`${baseString}.address3`}
                  labelId="ContactInfo.address3"
                  placeholderId="ContactInfo.address3_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  disabled={fetching}
                />
              </Col>
            </Row>
          </View>
        );
        break;
    }

    if (addressTypeCode)
      return (
        <View>
          {addressHeader}

          <Row>
            <Col sm={12}>
              <FormGroupText
                ref={ref => {
                  this._City = ref;
                }}
                field={`${baseString}.city`}
                labelId="ContactInfo.city"
                placeholderId="ContactInfo.city_placeholder"
                required
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroupSelect
                ref={ref => (this._State = ref)}
                field={`${baseString}.stateCode`}
                labelId="ContactInfo.state"
                placeholderId="ContactInfo.state_placeholder"
                optionsKey="state"
                required
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
            <Col sm={6}>
              <FormGroupText
                ref={ref => {
                  this._Zip = ref;
                }}
                field={`${baseString}.zip`}
                labelId="ContactInfo.zipcode"
                placeholderId="ContactInfo.zipcode_placeholder"
                returnKeyType="next"
                maxLength={10}
                keyboardType="numeric"
                format={zipcodeFormat}
                required
                validate={isZipcode}
                disabled={fetching}
              />
            </Col>
          </Row>
        </View>
      );
    return null;
  }

  render() {
    const {
      initilized,
      handleSubmit,
      isValid,
      isSubmitting,
      fetching,
      application,
      documents,
      documentsFetching,
      values,
      match: {
        params: { index }
      }
    } = this.props;

    const appBase = getBase(values, BASE, index);
    const baseString = getBaseString(BASE, index);

    const { documentErrorText, errorText } = this.state;

    if (!initilized) return null;

    const personId = getPersonId(application, BASE, index);

    // const lienDocuments = [];
    // documents &&
    //   documents.forEach(ele => {
    //     if (ele.personId === personId && ele.documentType === DOCUMENT_TYPE) {
    //       lienDocuments.push({ uri: ele.fileDownloadURL });
    //     }
    //   });

    const addressTypeCode = R.path(['addressTypeCode'], appBase);

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" style={styles.errorText} />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    // const documentErrorView = documentErrorText && (
    //   <View style={styles.errorContainer}>
    //     <FormattedMessage
    //       id="error.submissionError"
    //       style={styles.documentErrorText}
    //     />
    //     <Text style={styles.documentErrorText}>{documentErrorText}</Text>
    //   </View>
    // );

    const isIndividualOrCompanyOptions = {
      1: {
        code: TRUE_OPTION,
        value: I18n.t('LienHolderInformation.IsCompany')
      },
      0: {
        code: FALSE_OPTION,
        value: I18n.t('LienHolderInformation.IsIndividual')
      }
    };
    // const otherNames = R.path(['YesNo'], values) === YES_OPTION;
    const isCompany =
      R.path([BASE, index, 'isCompanyCode'], values) === TRUE_OPTION;
    const isIndividual =
      R.path([BASE, index, 'isCompanyCode'], values) === FALSE_OPTION;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="LienHolderInformation.title"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <FormattedMessage
                id="LienHolderInformation.UploadDocuments"
                style={Fonts.style.sectionHeader}
              />

              {/* <Col sm={12}>{documentErrorView}</Col> */}
            </Row>
            <Row>
              <Col sm={12}>
                <UploadDocumentType
                  base={BASE}
                  index={index}
                  documentType={DOCUMENT_TYPE}
                  documentKeywordValue={DOCUMENT_KEYWORD_VALUE}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>{errorView}</Col>
            </Row>
            {/* <Row>
              <Col sm={12}>
                <FormGroupRadio
                  field={`YesNo`}
                  labelId="LienHolderInformation.OtherNames"
                  optionsKey="yes_no"
                  disabled={fetching}
                  reverse
                  required
                  validate={isRequired}
                />
              </Col>
            </Row> */}

            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="LienHolderInformation.subtitle"
                  style={Fonts.style.subtitle}
                />
                <FormGroupRadio
                  field={`${baseString}.isCompanyCode`}
                  labelId="LienHolderInformation.IsIndividualOrCompany"
                  options={isIndividualOrCompanyOptions}
                  disabled={fetching}
                  required
                  validate={isRequired}
                />
              </Col>
            </Row>

            {isIndividual && (
              <View>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._firstName = ref;
                      }}
                      field={`${baseString}.firstName`}
                      labelId="ContactInfo.firstname"
                      autoCapitalize="words"
                      placeholderId="ContactInfo.firstname_placeholder"
                      returnKeyType="next"
                      onSubmitEditing={e => {
                        this._middleName.focus();
                      }}
                      required
                      validate={isRequired}
                      disabled={fetching}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._middleName = ref;
                      }}
                      field={`${baseString}.middleName`}
                      labelId="ContactInfo.middlename"
                      autoCapitalize="words"
                      placeholderId="ContactInfo.middlename_placeholder"
                      returnKeyType="next"
                      onSubmitEditing={() => {
                        this._lastName.focus();
                      }}
                      disabled={fetching}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._lastName = ref;
                      }}
                      field={`${baseString}.lastName`}
                      labelId="ContactInfo.lastname"
                      autoCapitalize="words"
                      placeholderId="ContactInfo.lastname_placeholder"
                      returnKeyType="next"
                      onSubmitEditing={() => this._Address1.focus()}
                      required
                      validate={isRequired}
                      disabled={fetching}
                    />
                  </Col>
                </Row>
              </View>
            )}
            {isCompany && (
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    field={`${baseString}.companyName`}
                    labelId="LienHolderInformation.CompanyName"
                    placeholderId="LienHolderInformation.CompanyName_placeholder"
                    onSubmitEditing={() => this._Address1.focus()}
                    required
                    validate={isRequired}
                    disabled={fetching}
                  />
                </Col>
              </Row>
            )}
            {(isCompany || isIndividual) && (
              <View>
                <AddressForm
                  addressString={baseString}
                  addressValues={appBase}
                  disabled={fetching}
                  optional
                />
                {/* <Row>
                  <Col sm={12}>
                    <FormGroupSelect
                      field={`${baseString}.addressTypeCode`}
                      labelId="ContactInfo.addressTypeCode"
                      placeholderId="ContactInfo.addressTypeCode_placeholder"
                      optionsKey="address_type"
                      required
                      validate={isRequired}
                      disabled={fetching}
                    />
                  </Col>
                </Row>

                {this.renderAddress(addressTypeCode)} */}

                <Row>
                  <Col sm={8}>
                    <FormGroupText
                      inputRef={ref => {
                        this._PrimaryPhone = ref;
                      }}
                      field={`${baseString}.primaryPhone`}
                      labelId="ContactInfo.PrimaryPhone"
                      placeholderId="ContactInfo.PrimaryPhone_placeholder"
                      maxLength={12}
                      keyboardType="phone-pad"
                      format={phoneFormat}
                      required
                      validate={isRequiredPhoneNumber}
                      disabled={fetching}
                    />
                  </Col>
                  <Col sm={4}>
                    <FormGroupCheckbox
                      labelId="ContactInfo.PrimaryPhoneIsMobile"
                      field={`${baseString}.primaryPhoneIsMobile`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={8}>
                    <FormGroupText
                      inputRef={ref => {
                        this._SecondaryPhone = ref;
                      }}
                      field={`${baseString}.secondaryPhone`}
                      labelId="ContactInfo.SecondaryPhone"
                      placeholderId="ContactInfo.SecondaryPhone_placeholder"
                      keyboardType="phone-pad"
                      maxLength={12}
                      validate={isPhoneNumber}
                      format={phoneFormat}
                      disabled={fetching}
                    />
                  </Col>
                  <Col sm={4}>
                    <FormGroupCheckbox
                      labelId="ContactInfo.SecondaryPhoneIsMobile"
                      field={`${baseString}.secondaryPhoneIsMobile`}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroupText
                      inputRef={ref => {
                        this._Email = ref;
                      }}
                      field={`${baseString}.email`}
                      labelId="ContactInfo.Email"
                      placeholderId="ContactInfo.Email_placeholder"
                      autoCapitalize="none"
                      keyboardType="email-address"
                      validate={isEmail}
                      disabled={fetching}
                    />
                  </Col>
                </Row>
              </View>
            )}

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

LienHolderInformation = withFormik({
  mapPropsToValues: props => {
    const {
      application,
      options,
      match: {
        params: { index }
      }
    } = props;
    const initialApplication = JSON.parse(JSON.stringify(application));
    // console.log('initialApplication', BASE, initialApplication[BASE]);
    const isCompany = R.pathOr(
      '-999',
      [BASE, index, 'isCompanyCode'],
      initialApplication
    );
    initialApplication.YesNo =
      (isCompany === TRUE_OPTION || isCompany === FALSE_OPTION) && YES_OPTION;
    // console.log('isCompany', isCompany, initialApplication.YesNo);

    // const baseString = getBaseString(base, index);

    // this.formInitilized = true;

    // Set Default State to NC
    if (
      !initialApplication ||
      !initialApplication[BASE] ||
      !initialApplication[BASE][index] ||
      !initialApplication[BASE][index].stateCode
    ) {
      console.log('setting default state', DEFAULT_STATE_CODE);
      initialApplication[BASE][index].stateCode = DEFAULT_STATE_CODE;
    }

    // Copy City String to City Code
    if (
      initialApplication[BASE][index].stateCode === DEFAULT_STATE_CODE &&
      initialApplication[BASE][index].city &&
      options &&
      options.city
    ) {
      Object.keys(options.city).forEach(cityCode => {
        const city = options.city[cityCode];
        if (city.value === initialApplication[BASE][index].city) {
          initialApplication[BASE][index].cityCode = cityCode;
        }
      });
    }

    // console.log(
    //   'loaded application',
    //   JSON.stringify(initialApplication, null, 2)
    // );
    return initialApplication;
  },

  isInitialValid: props => true,

  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const {
      updateApplication,
      options,
      match: {
        params: { index }
      }
    } = props;

    // let newValues = Immutable.asMutable(values) // Does not work
    let newValues = JSON.parse(JSON.stringify(values));

    // Concat Address
    if (newValues[BASE][index].address1) {
      if (newValues[BASE][index].address2) {
        newValues[BASE][index].address = `${newValues[BASE][index].address1}\n${
          newValues[BASE][index].address2
        }`;
      } else {
        newValues[BASE][index].address = newValues[BASE][index].address1;
      }
      delete newValues[BASE][index].address1;
      delete newValues[BASE][index].address2;
    }

    // Copy cityCode to city if DEFAULT_STATE_CODE
    if (newValues[BASE][index].stateCode === DEFAULT_STATE_CODE) {
      newValues[BASE][index].city = R.pathOr(
        '',
        ['city', newValues[BASE][index].cityCode, 'value'],
        options
      );
    }
    // console.log('updatingApplication', newValues);
    updateApplication(newValues);
    setStatus('updatingApplication');
  }
})(LienHolderInformation);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application },
  document
}) => ({
  options,
  data,
  initilized,
  fetching,
  errorText: error,

  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,

  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LienHolderInformation);
