import React, { Component } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { Grid, Row, Col } from '../Components/Grid';
import { I18n } from 'react-redux-i18n';

import FormGroupText from '../Components/FormGroupText';
import ErrorMessage from '../Components/ErrorMessage';
import FormattedMessage from '../Components/FormattedMessage';

import { isRequired } from '../Lib/Utils';

import FullButton from '../Components/FullButton';

import AccountActions from '../Redux/AccountRedux';

// Styles
import { Fonts, Metrics } from '../Themes';
import styles from './Styles/ScreenStyles';

class VerifyOtpScreen extends Component {
  state = {
    otpId: null,
    otp: '',
    errorText: null
  };

  requestedOtp = false;
  requestingOtp = false;
  verifyingOtp = false;

  componentDidMount() {
    this.handleResendOtp();
  }

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      status,
      isSubmitting,
      setStatus,
      setFieldValue,
      data,
      setSubmitting,
      application
    } = nextProps;

    if (this.requestingOtp && fetching === false) {
      // We have the results from requesing OTP
      this.requestingOtp = false;
      this.requestedOtp = true;
      if (data && data.otpId) {
        console.log(data);
        setFieldValue('otpId', data.otpId);
        this.setState({ otpId: data.otpId, errorText: null });
      } else if (errorText) {
        this.setState({ errorText });
      } else {
        this.setState({ errorText: 'Unknown Error' });
      }
    }

    if (isSubmitting && status === 'verifyingOtp' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        const pathArray = history.location.pathname.split('/');
        // If path ends with VerifyOtpScreen, go to it's parent path
        if (pathArray[pathArray.length - 1] === 'VerifyOtpScreen') {
          pathArray.pop();
          history.push(pathArray.join('/'));
        } else if (application && application.id) {
          history.push('ApplicantIdentificationScreen');
        } else {
          history.push('SelectApplicationScreen');
        }
      }
    }
  }

  handleResendOtp = () => {
    const { account, requestOtp, setFieldValue } = this.props;

    this.requestingOtp = true;
    this.setState({ errorText: null });
    setFieldValue('otpId', '');
    requestOtp(account.userId);
  };

  // onSubmit = values => {
  //   const { account, verifyOtp } = this.props;
  //   const { otpId } = this.state;
  //   const { otp } = values;

  //   this.verifyingOtp = true;
  //   verifyOtp(account.userId, otpId, otp);
  // };

  render() {
    const {
      handleSubmit,
      isSubmitting,
      isValid,
      history,
      fetching
    } = this.props;
    const { errorText } = this.state;
    const disabled = isSubmitting;

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.container}
        >
          <KeyboardAwareScrollView>
            <Grid>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="menu.VerifyOtp"
                    style={Fonts.style.title}
                  />
                  <FormattedMessage
                    id="Login.verifyOtpSubtitle"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <ErrorMessage errorText={errorText} />

                  <FormGroupText
                    field="otp"
                    labelId="Login.otp"
                    placeholderId="Login.otp_placeholder"
                    autoCapitalize="none"
                    keyboardType="numeric"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t('Login.verifyOtp')}
                    onPress={handleSubmit}
                    disabled={!isValid || fetching}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <TouchableOpacity
                    onPress={this.handleResendOtp}
                    style={{ marginTop: Metrics.doubleBaseMargin }}
                  >
                    <FormattedMessage
                      id="Login.resendOtp"
                      style={[
                        Fonts.style.base,
                        {
                          textAlign: 'center',
                          textDecorationLine: 'underline'
                        }
                      ]}
                    />
                  </TouchableOpacity>
                </Col>
              </Row>
            </Grid>
          </KeyboardAwareScrollView>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { account, application },
  account: { data, fetching, error }
}) => ({
  account,
  application,
  initilized,
  data,
  fetching,
  errorText: error
});

const mapDispatchToProps = dispatch => ({
  requestOtp: userId => dispatch(AccountActions.AccountRequestOtp(userId)),
  verifyOtp: (userId, otpId, otp) =>
    dispatch(AccountActions.AccountVerifyOtp(userId, otpId, otp))
});

VerifyOtpScreen = withFormik({
  mapPropsToValues: props => ({
    otp: '',
    otpId: ''
  }),
  validationSchema: yup.object().shape({
    otp: yup
      .string()
      .min(1, 'error.required')
      .required('error.required'),
    otpId: yup.string().required('error.required')
  }),
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { account } = props;
    const { otp, otpId } = values;

    props.verifyOtp(account.userId, otpId, otp);
    setStatus('verifyingOtp');
  }
})(VerifyOtpScreen);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyOtpScreen);
