import React, { Component } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import * as R from 'ramda';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import { Grid, Row, Col } from '../Components/Grid';

// import ScanID from './ScanID';
// import ContactInfo from './ContactInfo';
// import UploadTaxReturn from './Income/UploadTaxReturn';
// import RetrieveTaxReturn from './Income/RetrieveTaxReturn';
// import IRS4506T from './Income/IRS4506T';
// import IRS4506TSignature from './Income/IRS4506TSignature';
// import OtherIncome from './Income/OtherIncome';
// import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

// import TopNav from '../Components/TopNav';
import FullButton from '../Components/FullButton';

import { getBase, getPersonId, hasDocument, isNumeric } from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Colors, Metrics } from '../Themes/';

import {
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE,
  YES_OPTION,
  TRUE_OPTION
} from '../Config/ApplicationConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { setApplication } from '../Redux/PersistRedux';

const BASE = DATA_STRUCTURE.lien_holders;
const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;

const DOCUMENT_TYPE = DOCUMENT_TYPES.lienDocument.code;

export const LienOnPropertyScreenComplete = (application, documents) => {
  let lienOnPropertyGood = true;
  if (application[BASE]) {
    application[BASE].forEach((member, idx) => {
      const personId = getPersonId(application, BASE, idx);
      const hasProof = hasDocument(documents, personId, DOCUMENT_TYPE);
      if (!hasProof && lienOnPropertyGood === true) lienOnPropertyGood = false;
    });
  }
  return lienOnPropertyGood;
};

class LienOnPropertyScreen extends Component {
  state = {
    currentScreen: null,
    editingIndex: null
  };

  creatingLienHolder = false;
  deletingHouseholdMember = false;

  componentDidMount() {
    this.props.loadDocuments(DOCUMENT_TYPE);
  }

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'creatingLienHolder' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        console.log('DONE', application[BASE].length - 1);
        // history.push('CoapplicantIdentificationScreen');
        this.handleEdit(application[BASE].length - 1);
      }
    } else if (isSubmitting && status === 'doneLienHolder' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        if (
          R.path([BASE_DAMAGED_ADDRESS, 'propertyType'], application) ===
          MOBILE_HOME_CODE
        ) {
          // Is Mobile Hole
          history.push('OwnsLandScreen');
        } else {
          // Is not Mobile Home
          history.push('OwnHomeScreen');
        }
      }
    }

    // if (this.creatingLienHolder && !fetching) {
    //   this.creatingLienHolder = false;
    //   if (errorText) {
    //     this.setState({ errorText });
    //   } else if (application && application[BASE]) {
    //     console.log(application);
    //     this.setState({ editingIndex: application[BASE].length - 1 });
    //   } else {
    //     this.setState({ errorText: 'Error Adding Household Member' });
    //   }
    // } else if (this.deletingHouseholdMember && !fetching) {
    //   this.deletingHouseholdMember = false;
    // }
  }

  getNextIndex() {
    const { application } = this.props;

    if (application[BASE] && Object.keys(application[BASE]).length) {
      console.log('next', Object.keys(application[BASE]).length);
      return Object.keys(application[BASE]).length;
    }
    return 0;
  }

  handleAddMember = () => {
    const { application, updateApplication, setStatus } = this.props;

    // this.creatingLienHolder = true;
    const newApplication = JSON.parse(JSON.stringify(application));

    if (!newApplication[BASE]) {
      newApplication[BASE] = [];
    }
    newApplication[BASE].push({ id: '-1' });
    // console.log('handleAddMember', JSON.stringify(newApplication));
    setStatus('creatingLienHolder');
    updateApplication(newApplication);
  };

  handleEdit(editingIndex) {
    this.props.history.push(`/LienHolder/${editingIndex}/Information`);
  }

  handleDelete(personId, key) {
    const { deleteRecord, setStatus } = this.props;
    console.log('handleDelete', personId, key);
    // Remove application.BASE[idx]
    // this.deletingHouseholdMember = true;
    setStatus('deletingHouseholdMember');
    deleteRecord(personId, key);
  }

  handleNextScreen = () => {
    const { application, history } = this.props;
    if (
      R.path([BASE_DAMAGED_ADDRESS, 'propertyType'], application) ===
      MOBILE_HOME_CODE
    ) {
      // Is Mobile Hole
      history.push('OwnsLandScreen');
    } else {
      // Is not Mobile Home
      history.push('OwnHomeScreen');
    }
  };

  renderLeinHolders = () => {
    const { application, documents } = this.props;

    let idx = 1;
    const members = [];
    const iconStyle = {
      color: 'red',
      marginHorizontal: Metrics.baseMargin
    };

    Object.keys(application[BASE]).forEach((key, idx) => {
      if (isNumeric(key)) {
        const member = application[BASE][key] || {};
        const personId = getPersonId(application, BASE, key);
        const documentUploaded = hasDocument(
          documents,
          personId,
          DOCUMENT_TYPE
        );

        let name = `Lein #${idx + 1}`;
        if (member.isCompany === TRUE_OPTION) {
          name = member.companyName;
        } else if (member.firstName && member.lastName) {
          name = `${member.firstName} ${member.lastName}`;
        }

        members.push(
          <View
            key={`member_${key}`}
            style={{
              flex: 1,
              flexDirection: 'column',
              borderWidth: 1,
              padding: Metrics.baseMargin,
              marginHorizontal: 0,
              borderColor: Colors.lightGrey,
              marginBottom: Metrics.baseMargin
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Text
                style={[Fonts.style.normal, { fontWeight: 'bold', flex: 1 }]}
              >
                {name} {member.primaryPhone} {member.email}
              </Text>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa'
                }}
                onPress={this.handleEdit.bind(this, key)}
              >
                <Icon name="md-create" size={20} />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  padding: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa'
                }}
                onPress={this.handleDelete.bind(this, member.id, key)}
              >
                <Icon name="md-trash" size={20} />
              </TouchableOpacity>
            </View>
            {!documentUploaded && (
              <TouchableOpacity
                onPress={this.handleEdit.bind(this, key)}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <Icon name="md-close-circle" size={20} style={iconStyle} />
                <FormattedMessage
                  id="error.uploadMissing"
                  style={Fonts.style.normal}
                />
              </TouchableOpacity>
            )}
          </View>
        );
        idx++;
      }
    });

    return members;
  };

  render() {
    const {
      application,
      initilized,
      fetching,
      history,
      options,
      handleSubmit,
      values
    } = this.props;

    const { editingIndex, currentScreen, errorText } = this.state;

    if (!initilized) return null;

    // console.log('values', JSON.stringify(values, null, 2));

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    if (
      application &&
      application[BASE] &&
      Object.keys(application[BASE]).length > 0
    ) {
      return (
        <View style={styles.flex}>
          <ScrollView
            ref={scroll => {
              this._scroll = scroll;
            }}
            style={styles.scrollViewContainer}
          >
            <Grid>
              <Row>
                <Col>
                  <FormattedMessage
                    id={`LeinHolder.title`}
                    style={Fonts.style.title}
                  />
                  <FormattedMessage
                    id={`LeinHolder.subtitle`}
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {errorView}
                  {this.renderLeinHolders()}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t(`LeinHolder.AddAnother`)}
                    onPress={this.handleAddMember}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t('form.continueNextStep')}
                    onPress={this.handleNextScreen}
                  />
                </Col>
              </Row>
            </Grid>
          </ScrollView>
        </View>
      );
    }

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.container}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id={`${BASE}.title`}
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id={`${BASE}.subtitle`}
                  style={Fonts.style.subtitle}
                />
                <FormattedMessage
                  id={`${BASE}.subtitleDescription`}
                  style={[Fonts.style.subtitle, { fontSize: Fonts.size.pt40 }]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="hasLienCode"
                  labelId={`${BASE}.LienOnProperty`}
                  optionsKey="yes_no"
                  reverse
                  required
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="hasClearTitleCode"
                  labelId={`${BASE}.hasClearTitleCode`}
                  optionsKey="yes_no"
                  reverse
                  required
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                  disabled={fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </ScrollView>
      </View>
    );
  }
}

LienOnPropertyScreen = withFormik({
  mapPropsToValues: props => {
    const initialApplication = JSON.parse(JSON.stringify(props.application));
    // console.log(
    //   'mapPropsToValues',
    //   JSON.stringify(initialApplication, null, 2)
    // );
    return initialApplication;
  },
  isInitialValid: props =>
    props.application[BASE] && props.application[BASE].length > 0,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { application, history, updateApplication, setApplication } = props;

    let newApplication = JSON.parse(JSON.stringify(props.application));
    newApplication.hasLienCode = values.hasLienCode;
    newApplication.hasClearTitleCode = values.hasClearTitleCode;
    if (values.hasLienCode && values.hasLienCode === YES_OPTION) {
      // Yes

      if (!newApplication[BASE]) {
        newApplication[BASE] = [];
      }
      newApplication[BASE].push({ id: '-1' });
      console.log('creatingLienHolder', JSON.stringify(newApplication));
      updateApplication(newApplication);
      setStatus('creatingLienHolder');
    } else {
      // No

      updateApplication(newApplication);
      setStatus('doneLienHolder');

      // setApplication(newApplication);

      // if (
      //   R.path([BASE_DAMAGED_ADDRESS, 'propertyType'], application) ===
      //   MOBILE_HOME_CODE
      // ) {
      //   // Is Mobile Hole
      //   history.push('OwnsLandScreen');
      // } else {
      //   // Is not Mobile Home
      //   history.push('OwnHomeScreen');
      // }
    }
  }
})(LienOnPropertyScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId },
  document
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching
});

const mapDispatchToProps = dispatch => ({
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  // deleteHouseholdMember: memberId =>
  //   dispatch(ApplicationActions.ApplicationHouseholdMemberDelete(memberId)),
  setApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (memberId, key) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId, key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LienOnPropertyScreen);
