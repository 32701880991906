import { Platform } from 'react-native';
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import {
  GROUP,
  PROGRAM_CODE,
  API_VERSION,
  API_CREDENTIALS
} from '../Config/ApplicationConfig';
const { API_BASE_URL, AUTHORIZATION, WEB_BASE_URL } = API_CREDENTIALS;
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  DocumentLoadDocuments: ['docType'],
  DocumentUploadSignature: [
    'personId',
    'signature',
    'documentTypeCode',
    'keywordValueCode'
  ],
  DocumentUpload: [
    'personId',
    'documents',
    'documentTypeCode',
    'keywordValueCode'
  ],
  DocumentUploadProgress: ['file', 'progress'],
  DocumentDelete: ['personId', 'documentTypeCode', 'keywordValueCode'],
  DocumentSuccess: ['documents', 'action'],
  DocumentFailure: ['error', 'action'],
  DocumentReset: null
});

export const DocumentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  action: null,
  file: null,
  progress: null,
  documents: [],
  error: null,
  fetching: false
});

/* ------------- Reducers ------------- */

export const onProgress = (state, { file, progress }) =>
  state.merge({ file, progress });
// We are starting an API call.
export const request = state =>
  state.merge({
    file: null,
    progress: null,
    data: null,
    error: null,
    fetching: true,
    action: null
  });

// we've successfully completed an API call.
export const success = (state, { documents, action }) =>
  state.merge({ fetching: false, error: null, action, documents });

// we've had a problem with our API call.
export const failure = (state, { error, action }) =>
  state.merge({ fetching: false, error: error || 'Unknown Error', action });

export const reset = state => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DOCUMENT_LOAD_DOCUMENTS]: request,
  [Types.DOCUMENT_UPLOAD_SIGNATURE]: request,
  [Types.DOCUMENT_UPLOAD]: request,
  [Types.DOCUMENT_UPLOAD]: request,
  [Types.DOCUMENT_DELETE]: request,
  [Types.DOCUMENT_SUCCESS]: success,
  [Types.DOCUMENT_FAILURE]: failure,
  [Types.DOCUMENT_RESET]: reset
});

/* ------------- Selectors ------------- */

export const getDocumentURL = (fileDownloadURL, userId, tokenId, token) => {
  if (fileDownloadURL.indexOf('http') === 0) {
    // This is a web image
    if (fileDownloadURL.indexOf('boomi_auth') === -1) {
      return `${fileDownloadURL};boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}`;
    } else {
      // Don't add auth a second time.
      return fileDownloadURL;
    }
  } else if (Platform.OS === 'android') {
    return `file://${fileDownloadURL}`;
  } else {
    return fileDownloadURL;
  }
};

export const getDocumentIcon = filename => {
  if (!filename) return null;
  if (
    filename.toLowerCase().endsWith('.png') ||
    filename.toLowerCase().endsWith('.jpg') ||
    filename.toLowerCase().endsWith('.jpeg')
  ) {
    return 'file-image-o';
  } else if (filename.toLowerCase().endsWith('.pdf')) {
    return 'file-pdf-o';
  } else if (
    filename.toLowerCase().endsWith('.doc') ||
    filename.toLowerCase().endsWith('.docx')
  ) {
    return 'file-word-o';
  } else {
    return 'file-o';
  }
};
// fileDownloadURL.indexOf('http') === 0
//   ? `${fileDownloadURL};boomi_auth=${AUTHORIZATION}?userId=${userId}&tokenId=${tokenId}&token=${token}`
//   : Platform.OS === 'android'
//   ? `file://${fileDownloadURL}`
//   : `${fileDownloadURL}`;
