import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { View, Text, Platform } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import { isKiosk } from '../Redux/AccountRedux';
import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

import { Grid, Row, Col } from '../Components/Grid';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
// import FormGroupSelect from '../Components/FormGroupSelect';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  getPersonId,
  hasDocument,
  hasSignatureDocument
} from '../Lib/Utils';

// Styles
import { Fonts, Metrics, Colors } from '../Themes/';
import styles from './Styles/ScreenStyles';

import {
  DEFAULT_STATE_CODE,
  RENTAL_PROGRAM_CODE
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const POA_BASE = DATA_STRUCTURE.powerOfAttorney;
const COMMS_BASE = DATA_STRUCTURE.communicationsDesignee;

export const PoaDesigneeScreenComplete = (application, documents) => {
  const applicantPersonId = getPersonId(application, APPLICANT_BASE);
  const poaGood = hasSignatureDocument(
    documents,
    applicantPersonId,
    DOCUMENT_TYPES.powerOfAttorney.code
  );

  return R.pathOr('N/A', [POA_BASE], application) === 'N/A' || poaGood;
};

class PoaDesigneeScreen extends Component {
  state = {
    errorText: null
  };

  deletingPoa = false;
  deletingComms = false;
  updatingApplication = false;

  componentWillReceiveProps(nextProps) {
    const { fetching } = nextProps;

    if (this.deletingPoa && !fetching) {
      this.deletingPoa = false;
      this.handleNextScreen(nextProps);
    } else if (this.deletingComms && !fetching) {
      this.deletingComms = false;
      this.handleNextScreen(nextProps);
    } else if (this.updatingApplication && !fetching) {
      this.updatingApplication = false;
      this.handleNextScreen(nextProps);
    }
  }

  handleNextScreen(props) {
    const { application, history, deleteRecord, updateApplication } = props;

    console.log(
      'handleNextScreen',
      this.deletingPoa,
      this.deletingComms,
      this.updatingApplication
    );
    if (this.deletingPoa) {
      console.log('deletingPoa');
      deleteRecord(POA_BASE, this.deletingPoa);
    } else if (this.deletingComms) {
      console.log('deletingComms');
      deleteRecord(COMMS_BASE, this.deletingComms);
    } else if (this.updatingApplication) {
      console.log('updatingApplication');
      updateApplication(this.updatingApplication);
    } else {
      // Move On
      console.log(
        'move on',
        application.powerOfAttorney,
        application.communicationsDesignee
      );

      if (application.powerOfAttorney) {
        history.push('PoaScreen');
      } else if (application.communicationsDesignee) {
        history.push('CommsDesigneeScreen');
      } else {
        if (Platform.OS === 'web') {
          history.push('ApplicationChecklistScreen');
        } else {
          history.push('ConsentToShareDataScreen');
        }
      }
    }
  }

  onSubmit = () => {
    const { values } = this.props;

    console.log('onSubmit values', values);

    let newValues = JSON.parse(JSON.stringify(values));
    this.deletingPoa = false;
    this.deletingComms = false;
    this.updatingApplication = false;
    switch (newValues.poaDesignee) {
      case 'poa':
        if (!newValues.powerOfAttorney) {
          this.updatingApplication = true;
          newValues.powerOfAttorney = { id: '-1' };
        }
        if (newValues.communicationsDesignee) {
          this.deletingComms = newValues.communicationsDesignee.id;
        }
        break;
      case 'comms':
        if (newValues.powerOfAttorney) {
          this.deletingPoa = newValues.powerOfAttorney.id;
          delete newValues.powerOfAttorney;
        }
        if (!newValues.communicationsDesignee) {
          this.updatingApplication = true;
          newValues.communicationsDesignee = { id: '-1' };
        }
        break;
      case 'both':
        if (!newValues.powerOfAttorney) {
          this.updatingApplication = true;
          newValues.powerOfAttorney = { id: '-1' };
        }
        if (!newValues.communicationsDesignee) {
          this.updatingApplication = true;
          newValues.communicationsDesignee = { id: '-1' };
        }
        break;
      default:
      case 'neither':
        if (newValues.powerOfAttorney) {
          this.deletingPoa = newValues.powerOfAttorney.id;
        }
        if (newValues.communicationsDesignee) {
          this.deletingComms = newValues.communicationsDesignee.id;
        }
        break;
    }

    if (this.updatingApplication === true) {
      delete newValues.poaDesignee;
      this.updatingApplication = newValues;
    }

    console.log(
      'starting handleNextScreen',
      this.deletingPoa,
      this.deletingComms,
      this.updatingApplication
    );
    this.handleNextScreen(this.props);
  };

  render() {
    const {
      options,
      errors,
      base,
      index,
      application,
      initilized,
      handleSubmit,
      documents,
      isValid,

      fetching,
      values
    } = this.props;

    const poaOptions = {
      poa: { code: 'poa', value: I18n.t('PoaDesignee.option1') },
      comms: { code: 'comms', value: I18n.t('PoaDesignee.option2') },
      both: { code: 'both', value: I18n.t('PoaDesignee.option3') },
      neither: { code: 'neither', value: I18n.t('PoaDesignee.option4') }
    };

    const { errorText } = this.state;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <KeyboardAwareScrollView style={styles.scrollViewContainer}>
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id="PoaDesignee.title"
                style={Fonts.style.title}
              />
              <FormattedMessage
                id="PoaDesignee.subtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field={`poaDesignee`}
                labelId=""
                options={poaOptions}
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                onPress={() => {
                  // this.props.updateApplication(this.props.application);
                  console.log('onSubmit');
                  this.onSubmit();
                }}
                disabled={!isValid || fetching}
              />

              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
      </KeyboardAwareScrollView>
    );
  }
}

// const validationSchema = yup.object().shape({
//   YesNo: yup.string().required('error.required')
// });

PoaDesigneeScreen = withFormik({
  mapPropsToValues: props => {
    const { application } = props;
    let poaDesignee = 'neither';
    if (application[POA_BASE] && application[COMMS_BASE]) {
      poaDesignee = 'both';
    } else if (application[POA_BASE]) {
      poaDesignee = 'poa';
    } else if (application[COMMS_BASE]) {
      poaDesignee = 'comms';
    }
    let initialApplication = JSON.parse(JSON.stringify(application));
    initialApplication.poaDesignee = poaDesignee;
    return initialApplication;
  },
  isInitialValid: props => true
  // validationSchema,
  // Submission handler
  // handleSubmit: (values, { setStatus, props }) => {
  //   const {
  //     base,
  //     index,
  //     history,
  //     application,
  //     updateApplication,
  //     deleteRecord
  //   } = props;

  //   let newApplication = JSON.parse(JSON.stringify(application));
  //   let needToUpdate = false;

  //   const newState = values.YesNo;

  //   const hasPoa = application[POA_BASE];
  //   const hasComms = application[COMMS_BASE];

  //   const needToCreatePoa =
  //     !hasPoa && (newState === 'both' || newState === 'poa');
  //   const needToCreateComms =
  //     !hasComms && (newState === 'both' || newState === 'comms');

  //   const needToDeletePoa =
  //     hasPoa && (newState === 'neither' || newState === 'comms');
  //   const needToDeleteComms =
  //     hasComms && (newState === 'neither' || newState === 'poa');

  //   if (needToCreatePoa) {
  //     newApplication[POA_BASE] = { id: '-1' };
  //     needToUpdate = true;
  //   }
  //   if (needToCreateComms) {
  //     newApplication[COMMS_BASE] = { id: '-1' };
  //     needToUpdate = true;
  //   }

  //   if (needToUpdate) {
  //   }

  //   if (!hasPoa && !hasComms && newState === 'both') {
  //     // Need to create both
  //   } else if (hasPoa && hasComms && newState === 'neither') {
  //     // Need to delete both
  //   } else if (!hasPoa && newState === 'poa') {
  //     // Need to create poa
  //   } else if (!hasComms && newState === 'comms') {
  //   }

  //   setStatus({ a: 1, b: 2, c: 3 });

  //   // Need to create comms

  //   // Need to delete poa

  //   // Need to delete comms

  //   // console.log('handleSubmit', JSON.stringify(values, null, 2));

  //   const newValues = Object.assign({}, application);

  //   // if (values.YesNo && values.YesNo === '2') {
  //   //   // Yes
  //   //   if (!newValues[BASE]) {
  //   //     // Create CoApplicant
  //   //     newValues[BASE] = { id: '-1' };
  //   //     updateApplication(newValues);
  //   //     setStatus('creatingCoApplicant');
  //   //   } else {
  //   //     history.push('CoapplicantIdentificationScreen');
  //   //   }
  //   // } else if (values.YesNo && values.YesNo === '1') {
  //   //   // No
  //   //   if (newValues[BASE] && newValues[BASE].id) {
  //   //     // Delete CoApplicant
  //   //     // this.deletingCoApplicant = true;
  //   //     console.log('deleteRecord', BASE, newValues[BASE].id);
  //   //     deleteRecord(newValues[BASE].id);
  //   //     setStatus('deletingCoApplicant');
  //   //   } else {
  //   //     if (
  //   //       newValues.programCode.toString() === RENTAL_PROGRAM_CODE.toString()
  //   //     ) {
  //   //       history.push('TenantsScreen');
  //   //     } else {
  //   //       history.push('HouseholdMembersScreen');
  //   //     }
  //   //   }
  //   // }
  // }
})(PoaDesigneeScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId }
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: (base, memberId) =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(base, memberId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PoaDesigneeScreen);
