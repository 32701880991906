import Immutable from 'seamless-immutable';
import { call, put, select } from 'redux-saga/effects';
import PersistActions from '../Redux/PersistRedux';

const getPersist = state => state.persist;

/*
 * When we set the application, save the applicaiton Id as well.
 */
export function* setApplication({ application }) {
  if (application && application.id) {
    yield put(PersistActions.PersistSetApplicationId(application.id));
  } else {
    yield put(PersistActions.PersisitSetApplicationId(null));
  }
}

/*
 * When we set the local, update the persistant options.
 */
export function* setLocale(_, { locale }) {
  const persist = yield select(getPersist);
  const {
    application,
    allOptions,
    allDocTypes,
    allScreeningQuestions
  } = persist;

  yield put(PersistActions.PersistSetOptions(allOptions[locale]));

  const programCode = application && application.programCode;

  if (programCode && allDocTypes[programCode]) {
    yield put(
      PersistActions.PersistSetDocTypes(allDocTypes[programCode][locale])
    );
  }

  if (programCode && allScreeningQuestions[programCode]) {
    yield put(
      PersistActions.PersistSetScreeningQuestions(
        allScreeningQuestions[programCode][locale]
      )
    );
  }
}
