import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTIC_CODE } from '../Config/ApplicationConfig';

export const withGaTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    if (GOOGLE_ANALYTIC_CODE) {
      ReactGA.set({
        page,
        ...options
      });
      ReactGA.pageview(page);
    }
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
