import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { Image, View, Text, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';
import { isRequired } from '../Lib/Utils';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';
import SelectUploadDocumentType from '../Components/SelectUploadDocumentType';

import DocumentActions, { getDocumentURL } from '../Redux/DocumentRedux';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

import {
  RENTAL_PROGRAM_CODE,
  MOBILE_HOME_CODE
} from '../Config/ApplicationConfig';
import { getPersonId } from '../Lib/Utils';

const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfPrimaryResidence.code;
const PROOF_OF_RESIDENCE_DRIVERS_LICENSE_KEYWORD_VALUE =
  DOCUMENT_TYPES.proofOfPrimaryResidence.keywords.DriversLicense;

const PROOF_OF_IDENTITY_DOCUMENT_TYPE =
  DOCUMENT_TYPES.proofOfIdentification.code;
const PROOF_OF_IDENTITY_DRIVERS_LICENSE_KEYWORD_VALUE =
  DOCUMENT_TYPES.proofOfIdentification.keywords.DriversLicense;

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;
const BASE = 'isPrimaryResidenceCode';

class PrimaryResidenceProofScreen extends Component {
  state = {
    loadingDriversLicense: true,
    driversLicense: []
  };
  onComplete = () => {
    const { application, history } = this.props;

    // const isRentalProgram =
    //   application.programCode.toString() === RENTAL_PROGRAM_CODE.toString();

    history.push('/FloodWaterEnterHomeScreen');
    // history.push(
    //   isRentalProgram ? 'FloodWaterEnterHomeScreen' : 'PrimaryResidenceScreen'
    // );
  };

  componentDidMount() {
    // Load User's Drivers License if avialable.
    // this.props.loadDocuments(PROOF_OF_IDENTITY_DOCUMENT_TYPE);
    const { application, documents, userId, tokenId, token } = this.props;

    const personId = getPersonId(application, APPLICANT_BASE);
    let found = null;

    // console.log(
    //   'componentDidMount',
    //   documents,
    //   PROOF_OF_IDENTITY_DOCUMENT_TYPE,
    //   PROOF_OF_IDENTITY_DRIVERS_LICENSE_KEYWORD_VALUE
    // );
    documents &&
      documents.forEach(ele => {
        if (ele) {
          // console.log(
          //   'checking ',
          //   ele.documentType,
          //   PROOF_OF_IDENTITY_DOCUMENT_TYPE,
          //   ele.keywordValue,
          //   PROOF_OF_IDENTITY_DRIVERS_LICENSE_KEYWORD_VALUE,
          //   ele.personId,
          //   personId
          // );
          if (
            ele.documentType === PROOF_OF_IDENTITY_DOCUMENT_TYPE &&
            ele.keywordValue ===
              PROOF_OF_IDENTITY_DRIVERS_LICENSE_KEYWORD_VALUE &&
            ele.personId === personId
          ) {
            found = getDocumentURL(ele.fileDownloadURL, userId, tokenId, token);
            console.log('FOUND Drivers License', found);
          }
        }
      });

    if (Platform.OS !== 'web' && found) {
      this.setState({
        loadingDriversLicense: false,
        driversLicense: [{ uri: found }]
      });
    } else {
      this.setState({ loadingDriversLicense: false, driversLicense: [] });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { application, documents, fetching, errorText } = nextProps;
    // if (this.state.loadingDriversLicense && !fetching) {
    //   // console.log('done fetching', documents);

    //   const personId = getPersonId(application, APPLICANT_BASE);
    //   let found = null;

    //   documents.forEach(ele => {
    //     if (ele) {
    //       console.log(
    //         'checking ',
    //         ele.documentType,
    //         PROOF_OF_IDENTITY_DOCUMENT_TYPE,
    //         ele.keywordValue,
    //         PROOF_OF_IDENTITY_DRIVERS_LICENSE_KEYWORD_VALUE,
    //         ele.personId,
    //         personId
    //       );
    //       if (
    //         ele.documentType === PROOF_OF_IDENTITY_DOCUMENT_TYPE &&
    //         ele.keywordValue ===
    //           PROOF_OF_IDENTITY_DRIVERS_LICENSE_KEYWORD_VALUE &&
    //         ele.personId === personId
    //       ) {
    //         console.log('FOUND Drivers License', ele.fileDownloadURL);
    //         found = ele;
    //       }
    //     }
    //   });

    //   if (found) {
    //     this.setState({
    //       loadingDriversLicense: false,
    //       driversLicense: [{ uri: found.fileDownloadURL }]
    //     });
    //   } else {
    //     this.setState({ loadingDriversLicense: false, driversLicense: [] });
    //   }
    // }
  }

  render() {
    const { application, documents, initilized } = this.props;
    const { loadingDriversLicense, driversLicense } = this.state;

    if (!initilized) return null;

    // if (loadingDriversLicense) return <Text>Loading Drivers License</Text>;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DamagedAddress.ProofOfPrimaryResidence"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormattedMessage
                  id="DamagedAddress.ProofOfPrimaryResidenceDescriptionDriversLicense"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                {!loadingDriversLicense && (
                  <SelectUploadDocumentType
                    documentType={DOCUMENT_TYPE}
                    scrollToTop={() => this._scroll.scrollTo({ x: 0, y: 0 })}
                    base={APPLICANT_BASE}
                    defaultDocuments={driversLicense}
                    defaultKeywordValue={
                      driversLicense.length > 0
                        ? PROOF_OF_RESIDENCE_DRIVERS_LICENSE_KEYWORD_VALUE
                        : ''
                    }
                    onComplete={this.onComplete}
                  />
                )}
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, application, account, tokenId, token },
  document: { documents, fetching, error }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryResidenceProofScreen);
