import '../Config';
import React, { Component } from 'react';
import { Platform, View } from 'react-native';
import { Provider } from 'react-redux';
import codePush from 'react-native-code-push';

import createBrowserHistory from 'history/createBrowserHistory';
import createMemoryHistory from 'history/createMemoryHistory';

import RootContainer from './RootContainer';
import createStore from '../Redux';

import isDev from '../Lib/isDev';

// Create history once
export const history =
  Platform.OS === 'web' ? createBrowserHistory() : createMemoryHistory();

// create our store
const store = createStore(history);

/**
 * Provides an entry point into our application.  Both index.ios.js and index.android.js
 * call this component first.
 *
 * We create our Redux store here, put it into a provider and then bring in our
 * RootContainer.
 *
 * We separate like this to play nice with React Native's hot reloading.
 */
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer history={history} />
      </Provider>
    );
  }
}

const dev = isDev();
// Don't use CodePush for DEV
if ((Platform.OS === 'ios' || Platform.OS === 'android') && !dev) {
  App = codePush({
    checkFrequency: codePush.CheckFrequency.ON_APP_RESUME
    // updateDialog: true,
    // installMode: codePush.InstallMode.IMMEDIATE
  })(App);
}

export default App;
