const colors = {
  notchDefaultHeaderBackground: '#333333',
  notchDefaultFooterBackground: '#FFFFFF',

  red: '#333333',

  lightBlue: '#AFC2D4',
  blue: '#6996BE',
  darkBlue: '#090636',

  lightestGrey: '#DDDDDD',
  lightGrey: '#B3BDD2',
  grey: '#8B92A2',
  darkGrey: '#545861',

  logoText: '#FFFFFF',

  buttonText: '#FFFFFF',
  background: '#FFFFFF',
  headerBackground: '#333333',
  drawerBackground: '#EFEFEF',
  drawerActiveItemBackground: '#C5C5C5',

  buttonBlueDark: '#003366',
  buttonBlue: '#1e60aa',
  buttonBlueLight: '#1B66B1',

  buttonGreyDark: '#686868',
  buttonGrey: '#333333',
  buttonGreyLight: '#B7B7B7',

  buttonBorderDisabled: '#9B9B9B',
  buttonDisabled: '#E5E5E5',

  textColor: '#4A4A4A',
  textColorLight: '#8A8989',
  textColorBlue: '#093B6C',

  fieldBackground: '#F2F2F2',
  fieldBorder: '#979797',
  fieldColor: '#000000',
  fieldColorDisabled: '#4A4A4A',

  radioOutline: '#979797',
  radioColor: '#333333',
  checkboxOutline: '#979797',
  checkboxColor: '#333333',

  white: '#FFFFFF',

  error: '#e73536',

  clear: 'rgba(0,0,0,0)',
  facebook: '#3b5998',
  transparent: 'rgba(0,0,0,0)',
  silver: '#F7F7F7',
  steel: '#CCCCCC',
  ricePaper: 'rgba(255,255,255, 0.75)',
  frost: '#D8D8D8',
  cloud: 'rgba(200,200,200, 0.35)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
  panther: '#161616',
  charcoal: '#595959',
  coal: '#2d2d2d',
  bloodOrange: '#fb5f26',
  snow: 'white',
  ember: 'rgba(164, 0, 48, 0.5)',
  fire: '#e73536',
  drawer: 'rgba(30, 30, 29, 0.95)',
  eggplant: '#251a34',
  border: '#483F53',
  banner: '#5F3E63',
  text: '#E0D7E5'
};

export default colors;
