import React, { Component } from 'react';
import { ScrollView, View, Text, KeyboardAvoidingView } from 'react-native';
import { connect } from 'react-redux';
import { Grid, Row, Col } from '../Components/Grid';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import FormGroupText from '../Components/FormGroupText';
import { isRequired, isRequiredEmail } from '../Lib/Utils';

import ErrorMessage from '../Components/ErrorMessage';
import FullButton from '../Components/FullButton';
import FormattedMessage from '../Components/FormattedMessage';

import AccountActions from '../Redux/AccountRedux';

// Styles
import { Fonts } from '../Themes/';
import styles from './Styles/ScreenStyles';

class CreateAccountScreen extends Component {
  static navigationOptions = {
    title: I18n.t('menu.CreateAccount')
  };

  state = {
    firstName: 'alex',
    middleName: '',
    lastName: 'chong',
    DateOfBirth: '12/02/1975',
    SSN: '5555555555',
    errorText: null
  };

  hasSubmitted = false;
  isSubmitting = false;

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      status,
      isSubmitting,
      setStatus,
      setSubmitting
    } = nextProps;

    if (isSubmitting && status === 'creatingAccount' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('VerifyOtpScreen');
      }
    }
  }

  // componentWillReceiveProps(newProps) {
  //   console.log('componentWillReceiveProps', JSON.stringify(newProps, null, 2));

  //   const { data, fetching, errorText, history } = newProps;

  //   if (this.isSubmitting && fetching === false) {
  //     this.isSubmitting = false;

  //     if (data && data.userId) {
  //       // Successful Login
  //       history.push('VerifyOtpScreen');
  //     } else if (errorText) {
  //       this.setState({ errorText });
  //     } else {
  //       this.setState({ errorText: 'Unknown Error' });
  //     }
  //   }
  // }

  // onSubmit = values => {
  //   const { email, password1, password2 } = values;

  //   const credentials = { emailAddress: email, password: password1 };

  //   if (password1 !== password2) {
  //     this.setState({ errorText: I18n.t('error.passwordsDontMatch') });
  //   } else {
  //     // Send Login Request
  //     this.hasSubmitted = true;
  //     this.isSubmitting = true;
  //     this.props.create(credentials);
  //   }
  // };

  render() {
    const {
      handleSubmit,
      initilized,
      fetching,
      invalid,
      history,
      isValid,
      isSubmitting
    } = this.props;

    if (!initilized) return null;

    const { errorText } = this.state;

    const disabled = fetching;

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.container}
        >
          <KeyboardAvoidingView behavior="position">
            <Grid>
              <Row>
                <Col xs={12}>
                  <FormattedMessage
                    id="CreateAccount.title"
                    style={Fonts.style.title}
                  />
                  <FormattedMessage
                    id="CreateAccount.subtitle"
                    style={Fonts.style.subtitle}
                  />
                  <FormattedMessage
                    id="CreateAccount.help"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <ErrorMessage errorText={errorText} />

                  <FormGroupText
                    ref="Email"
                    field="email"
                    labelId="ContactInfo.Email"
                    placeholderId="ContactInfo.Email_placeholder"
                    autoCapitalize="none"
                    keyboardType="email-address"
                    validate={isRequiredEmail} // isEmail
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroupText
                    ref="password1"
                    field="password1"
                    labelId="Login.password"
                    placeholderId="Login.password"
                    autoCapitalize="none"
                    secureTextEntry
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroupText
                    ref="password2"
                    field="password2"
                    labelId="Login.repeatPassword"
                    placeholderId="Login.password"
                    autoCapitalize="none"
                    secureTextEntry
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FullButton
                    text={I18n.t('Login.createAccount')}
                    onPress={handleSubmit}
                    disabled={invalid || fetching}
                  />
                  <FullButton
                    text={I18n.t('Login.login')}
                    onPress={() => history.push('LoginScreen')}
                    type="link"
                    disabled={!isValid || isSubmitting}
                  />
                </Col>
              </Row>
            </Grid>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    );
  }
}

CreateAccountScreen = withFormik({
  mapPropsToValues: props => ({
    email: '',
    password1: '',
    password2: ''
  }),
  isInitialValid: props => true,
  // validationSchema,
  validate: (values, props) => {
    let errors = {};
    if (values.password1 !== values.password2) {
      errors.password2 = 'error.passwordsDontMatch';
    }
    return errors;
  },
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { email, password1 } = values;
    const credentials = { emailAddress: email, password: password1 };
    props.create(credentials);
    setStatus('creatingAccount');
  }
})(CreateAccountScreen);

const mapStateToProps = ({
  persist: { account },
  app: { initilized },
  account: { data, fetching, error }
}) => ({
  initilized,
  account,
  data,
  fetching,
  errorText: error
});

const mapDispatchToProps = dispatch => ({
  create: credentails => dispatch(AccountActions.AccountCreate(credentails))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountScreen);
