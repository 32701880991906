import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';
import { path, pathOr } from 'ramda';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

// Styles
import { Fonts } from '../Themes/';
import styles from './Styles/ScreenStyles';

const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;
const BASE_APPLICANT = DATA_STRUCTURE.applicant;

class ConsentToShareDataForm extends Component {
  render() {
    const { locale, application, options } = this.props;

    console.log(application[BASE_DAMAGED_ADDRESS].stateCode);
    console.log(options);
    let addressString = '';
    if (path([BASE_DAMAGED_ADDRESS, 'boxNumber'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].boxNumber}, `;
    if (path([BASE_DAMAGED_ADDRESS, 'RRHCNumber'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].RRHCNumber}, `;
    if (path([BASE_DAMAGED_ADDRESS, 'address'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].address}, `;
    if (path([BASE_DAMAGED_ADDRESS, 'address2'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].address2}, `;
    if (path([BASE_DAMAGED_ADDRESS, 'address3'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].address3}, `;
    if (path([BASE_DAMAGED_ADDRESS, 'city'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].city}, `;
    if (path([BASE_DAMAGED_ADDRESS, 'stateCode'], application))
      addressString += `${path(
        ['state', application[BASE_DAMAGED_ADDRESS].stateCode, 'value'],
        options
      )} `;
    if (path([BASE_DAMAGED_ADDRESS, 'zip'], application))
      addressString += `${application[BASE_DAMAGED_ADDRESS].zip}`;
    return (
      <View>
        <Row>
          <Col sm={12}>
            {/* <Text
              style={[
                styles.block,
                { textAlign: 'right', fontWeight: Fonts.weight.bold }
              ]}
            >
              {I18n.t('ConsentToShareData.addressName1')}
            </Text>
            <Text
              style={[
                styles.block,
                { textAlign: 'right', fontWeight: Fonts.weight.bold }
              ]}
            >
              {I18n.t('ConsentToShareData.addressName2')}
            </Text>
            <Text style={[styles.block, { textAlign: 'right' }]}>
              {I18n.t('ConsentToShareData.address1')}
            </Text>
            <Text style={[styles.block, { textAlign: 'right' }]}>
              {I18n.t('ConsentToShareData.address2')}
            </Text> */}

            {/* <Text
              style={[
                Fonts.style.sectionHeader,
                styles.bold,
                styles.underline,
                styles.center,
                { fontWeight: Fonts.weight.bold }
              ]}
            >
              {I18n.t('ConsentToShareData.title')}
            </Text> */}
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section0')}
              {`${pathOr(
                'N/A',
                [BASE_APPLICANT, 'firstName'],
                application
              )} ${pathOr('N/A', [BASE_APPLICANT, 'lastName'], application)}`}
              {I18n.t('ConsentToShareData.section1')}
            </Text>

            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet1')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet2')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet3')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet4')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet5')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2022'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.bullet6')}
              </Text>
            </View>
            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.section3')}
            </Text>

            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check1')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check2')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check3a')}
                {addressString}
                {I18n.t('ConsentToShareData.check3b')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check4')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check5')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check6')}
              </Text>
            </View>
            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check7a')}
                <Text style={styles.underline}>
                  {I18n.t('ConsentToShareData.check7b')}
                </Text>
                {I18n.t('ConsentToShareData.check7c')}
              </Text>
            </View>

            <View style={{ marginLeft: 60, marginTop: 10, marginBottom: 10 }}>
              <Text style={styles.block}>
                {I18n.t('ConsentToShareData.addressName1')}
              </Text>
              <Text style={styles.block}>
                {I18n.t('ConsentToShareData.addressName2')}
              </Text>
              <Text style={styles.block}>
                {I18n.t('ConsentToShareData.address1')}
              </Text>
              <Text style={styles.block}>
                {I18n.t('ConsentToShareData.address2')}
              </Text>
            </View>

            <View style={styles.liWrapper}>
              <Text style={styles.liBullet}>{'\u2713'}</Text>
              <Text style={styles.liText}>
                {I18n.t('ConsentToShareData.check8')}
              </Text>
            </View>

            <Text style={styles.p}>
              {I18n.t('ConsentToShareData.signBelow')}
            </Text>
          </Col>
        </Row>
      </View>
    );
  }
}

const mapStateToProps = ({
  i18n: { locale },
  app: { initilized },
  persist: { application, options }
}) => ({
  locale,
  options,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentToShareDataForm);
