import React, { Component } from 'react';
import {
  TouchableOpacity,
  TextInput,
  Button,
  View,
  Text,
  Platform
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  getPersonId,
  getBase,
  getBaseString
} from '../Lib/Utils';

import FormGroupText from '../Components/FormGroupText';
import ErrorMessage from '../Components/ErrorMessage';
import SuccessMessage from '../Components/SuccessMessage';
import FormattedMessage from '../Components/FormattedMessage';

import FullButton from '../Components/FullButton';

import AccountActions from '../Redux/AccountRedux';
import PersistActions from '../Redux/PersistRedux';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Metrics } from '../Themes';
import { setKiosk } from '../Redux/PersistRedux';

class LoginScreen extends Component {
  state = {
    errorText: null
  };

  componentWillReceiveProps(nextProps) {
    const {
      history,
      data,
      fetching,
      errorText,
      setKiosk,
      status,
      isSubmitting,
      setStatus,
      setSubmitting
    } = nextProps;

    if (isSubmitting && status === 'loggingIn' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        if (Platform.OS === 'web' && data.isActiveKioskUser === '1') {
          this.setState({ errorText: 'kiosk users cannot be used on web' });
        } else if (data.isActiveKioskUser === '1') {
          setKiosk(true);

          const pathArray = history.location.pathname.split('/');
          // If path ends with LoginScreen, go to it's parent path
          if (pathArray[pathArray.length - 1] === 'LoginScreen') {
            pathArray.pop();
            history.push(pathArray.join('/'));
          } else {
            history.push('/');
          }
        } else {
          // Continue to Login
          history.push('VerifyOtpScreen');
        }
        console.log('data', data);
      }
    }
  }

  render() {
    const {
      handleSubmit,
      isSubmitting,
      submitCount,
      isValid,
      history,
      fetching,
      match: {
        params: { changedPassword }
      }
    } = this.props;
    const { errorText } = this.state;
    const disabled = fetching;

    // console.log('changedPassword', changedPassword);
    const successMessage =
      changedPassword === 'changedPassword' &&
      I18n.t('ChangePassword.successMessage');

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.container}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage id="Login.title" style={Fonts.style.title} />
                <FormattedMessage
                  id="Login.loginSubtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {submitCount === 0 && <SuccessMessage text={successMessage} />}
                <ErrorMessage errorText={!isSubmitting && errorText} />
                <FormGroupText
                  inputRef={ref => {
                    this._email = ref;
                  }}
                  field="email"
                  labelId="ContactInfo.Email"
                  placeholderId="ContactInfo.Email_placeholder"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  spellCheck={false}
                  required
                  validate={isRequired} // isRequiredEmail
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  inputRef={ref => {
                    this._password = ref;
                  }}
                  field="password"
                  labelId="Login.password"
                  placeholderId="Login.password"
                  autoCapitalize="none"
                  required
                  validate={isRequired}
                  secureTextEntry
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('Login.login')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TouchableOpacity
                  onPress={() => history.push('/ForgotPasswordScreen')}
                  style={{ marginTop: Metrics.doubleBaseMargin }}
                >
                  <FormattedMessage
                    id="Login.forgotPassword"
                    style={[
                      Fonts.style.base,
                      {
                        textAlign: 'center',
                        textDecorationLine: 'underline'
                      }
                    ]}
                  />
                </TouchableOpacity>
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

LoginScreen = withFormik({
  mapPropsToValues: props => ({
    email: '',
    password: ''
  }),
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { email, password } = values;
    const credentials = { emailAddress: email, password };
    props.login(credentials);
    setStatus('loggingIn');
  }
})(LoginScreen);

const mapStateToProps = ({
  persist: { account },
  app: { initilized },
  account: { data, fetching, error }
}) => ({
  initilized,
  account,
  data,
  fetching,
  errorText: error
});

const mapDispatchToProps = dispatch => ({
  login: credentails => dispatch(AccountActions.AccountLogin(credentails)),
  setKiosk: isKiosk => dispatch(PersistActions.PersistSetKiosk(isKiosk))
});

LoginScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen);

export default LoginScreen;
