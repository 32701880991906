import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, Image, View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { RNCamera } from 'react-native-camera';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import FormattedMessage from '../Components/FormattedMessage';
import FullButton from '../Components/FullButton';
import { Grid, Row, Col } from '../Components/Grid';

import PersistActions from '../Redux/PersistRedux';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/FormStyles';

class TakePicture extends Component {
  static propTypes = {
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    instructions: PropTypes.string
  };

  state = {
    image: null,
    images: [],
    takingPhoto: false
  };

  formInitilized = false;

  componentDidMount() {
    const { initilized } = this.props;

    if (initilized && !this.formInitilized) {
      this.initilizeForm(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { initilized, torch } = nextProps;

    if (initilized && !this.formInitilized) {
      this.initilizeForm(nextProps);
    }

    if (torch !== null && this.state.torchOn !== torch) {
      this.setState({ torchOn: torch });
    }
  }

  initilizeForm(props) {
    const { torch } = props;

    this.formInitilized = true;
    this.setState({ torchOn: torch });
  }

  handleTakePicture = async function(camera) {
    const options = { quality: 0.5, base64: true };

    if (!this.state.takingPhoto) {
      this.setState({ takingPhoto: true });
      const data = await camera.takePictureAsync(options);
      this.setState({ takingPhoto: false });

      this.state.images.push(data);
      this.setState({
        takingPhoto: false,
        image: data,
        images: this.state.images
      });

      //  eslint-disable-next-line
      // console.log(JSON.stringify(data, null, 2));
    }

    // options.location = ...
    // if (!this.state.takingPhoto) {
    //   this.setState({ takingPhoto: true });
    //   camera
    //     .capture({ metadata: options })
    //     .then(data => {
    //       this.state.images.push(data.path);
    //       this.setState({
    //         takingPhoto: false,
    //         image: data.path,
    //         images: this.state.images
    //       });
    //     })
    //     .catch(err => {
    //       this.setState({ takingPhoto: false });
    //       console.error(err);
    //     });
    // }
  };

  handleSavePhotos = () => {
    this.props.onComplete(this.state.images);
  };

  handleDiscardPhoto = () => {
    this.setState({ image: null });
  };

  handleDelete(idx) {
    const newImages = this.state.images.filter((ele, i) => idx !== i && ele);
    this.setState({ images: newImages });
  }

  handleToggleTorch = () => {
    this.props.setTorch(!this.props.torch);
  };

  render() {
    const { onClose, multiple, hasCamera, torch, initilized } = this.props;

    if (!initilized) return null;

    const { image, images, takingPhoto, torchOn } = this.state;

    // if (!hasCamera) {
    //   return (
    //     <View
    //       style={{
    //         flexDirection: 'column',
    //         flex: 1,
    //         justifyContent: 'center',
    //         alignItems: 'center'
    //       }}
    //     >
    //       <FormattedMessage
    //         id="error.noCamera"
    //         style={[
    //           Fonts.style.subtitle,
    //           { textAlign: 'center', margin: Metrics.doubleBaseMargin }
    //         ]}
    //       />
    //       <View style={{ marginTop: Metrics.doubleBaseMargin }} />
    //       <FullButton text={I18n.t('buttons.Close')} onPress={onClose} />
    //     </View>
    //   );
    // }

    if (image) {
      if (multiple) {
        // console.log(images);
        const renderedImages = images.map((ele, idx) => (
          <Col
            key={`column_${idx}`}
            xs={6}
            style={{
              flexDirection: 'row',
              marginBottom: Metrics.doubleBaseMargin
            }}
          >
            <View
              style={{
                height: 200,
                flex: 1,
                borderWidth: 1,
                borderColor: Colors.grey
              }}
            >
              <Image
                source={{ uri: ele.uri }}
                style={{ flex: 1, resizeMode: 'contain' }}
              />
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  top: Metrics.baseMargin,
                  right: Metrics.baseMargin,
                  borderRadius: 5,
                  backgroundColor: '#aaa',
                  padding: Metrics.baseMargin
                }}
                onPress={this.handleDelete.bind(this, idx)}
              >
                <Icon name="md-trash" size={20} />
              </TouchableOpacity>
            </View>
          </Col>
        ));
        return (
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <ScrollView
              // ref={scroll => {
              //   this._scroll = scroll;
              // }}
              style={{ flex: 1, marginTop: Metrics.doubleBaseMargin }}
            >
              <Grid>
                <Row>{renderedImages}</Row>
              </Grid>
            </ScrollView>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: Metrics.doubleBaseMargin
              }}
            >
              <View
                style={[
                  styles.flex,
                  {
                    marginLeft: Metrics.baseMargin,
                    marginRight: Metrics.baseMargin / 2
                  }
                ]}
              >
                <FullButton
                  text={I18n.t('buttons.AddPhoto')}
                  onPress={this.handleDiscardPhoto}
                />
              </View>
              <View
                style={[
                  styles.flex,
                  {
                    marginLeft: Metrics.baseMargin / 2,
                    marginRight: Metrics.baseMargin
                  }
                ]}
              >
                <FullButton
                  text={I18n.t('buttons.Done')}
                  onPress={this.handleSavePhotos}
                />
              </View>
            </View>
          </View>
        );
      }
      return (
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <Image
            source={{ uri: image.uri }}
            style={{
              minHeight: 100,
              flex: 1,
              resizeMode: 'contain',
              margin: Metrics.doubleBaseMargin,
              marginBottom: 0
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              marginBottom: Metrics.doubleBaseMargin
            }}
          >
            <View
              style={[
                styles.flex,
                {
                  marginLeft: Metrics.baseMargin,
                  marginRight: Metrics.baseMargin / 2
                }
              ]}
            >
              <FullButton
                text={I18n.t('buttons.Discard')}
                onPress={this.handleDiscardPhoto}
              />
            </View>
            <View
              style={[
                styles.flex,
                {
                  marginLeft: Metrics.baseMargin / 2,
                  marginRight: Metrics.baseMargin
                }
              ]}
            >
              <FullButton
                text={I18n.t('buttons.UsePhoto')}
                onPress={this.handleSavePhotos}
              />
            </View>
          </View>
        </View>
      );
    }

    const torchMode = torchOn
      ? RNCamera.Constants.FlashMode.on
      : RNCamera.Constants.FlashMode.off;

    return (
      <RNCamera
        ref={cam => {
          this.camera = cam;
        }}
        torchMode={torchMode}
        // captureTarget={RNCamera.Constants.CaptureTarget.disk}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }}
        playSoundOnCapture={false}
        // aspect={RNCamera.Constants.Aspect.fill}
      >
        {({ camera, status }) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                padding: Metrics.doubleBaseMargin,
                justifyContent: 'center',
                flex: 1
              }}
            >
              <View style={{ flexDirection: 'column', flex: 0.4 }}>
                <View
                  style={{
                    flex: 0.4,
                    borderColor: '#FFF',
                    borderLeftWidth: 1,
                    borderTopWidth: 1
                  }}
                />
                <View style={{ flex: 1 }} />
                <View
                  style={{
                    flex: 0.4,
                    borderColor: '#FFF',
                    borderLeftWidth: 1,
                    borderBottomWidth: 1
                  }}
                />
              </View>
              <View style={{ flex: 1 }} />
              <View style={{ flexDirection: 'column', flex: 0.4 }}>
                <View
                  style={{
                    flex: 0.4,
                    borderColor: '#FFF',
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end'
                  }}
                >
                  <TouchableOpacity
                    style={{
                      padding: Metrics.baseMargin
                    }}
                    onPress={onClose}
                  >
                    <Icon
                      name="ios-close"
                      size={50}
                      style={{
                        color: 'white',
                        backgroundColor: Colors.transparent
                      }}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: Metrics.baseMargin
                    }}
                    onPress={this.handleToggleTorch}
                  >
                    <Icon
                      name={torch ? 'ios-flash' : 'ios-flash-off'}
                      size={50}
                      style={{
                        color: 'white',
                        backgroundColor: Colors.transparent
                      }}
                    />
                  </TouchableOpacity>
                </View>
                <View style={{ flex: 1 }} />
                <View
                  style={{
                    flex: 0.4,
                    borderColor: '#FFF',
                    borderRightWidth: 1,
                    borderBottomWidth: 1
                  }}
                />
              </View>

              <View
                style={{
                  position: 'absolute',
                  bottom: Metrics.doubleBaseMargin * 5,
                  width: 100,
                  height: 100,
                  borderRadius: 100 / 2,
                  borderWidth: 5,
                  borderColor: takingPhoto ? Colors.grey : Colors.white,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <TouchableOpacity
                  onPress={() => this.handleTakePicture(camera)}
                >
                  <Icon
                    name="md-camera"
                    size={60}
                    style={{
                      color: takingPhoto ? Colors.grey : Colors.white,
                      backgroundColor: Colors.transparent
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
          );
        }}
      </RNCamera>
    );
  }
}

const mapStateToProps = ({
  app: { initilized, hasCamera },
  persist: { torch }
}) => ({
  initilized,
  hasCamera,
  torch
});

const mapDispatchToProps = dispatch => {
  return {
    setTorch: torch => dispatch(PersistActions.PersistSetTorch(torch))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TakePicture);
