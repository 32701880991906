import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import { I18n } from 'react-redux-i18n';

import { Grid, Row, Col } from '../Components/Grid';

import { isRequired } from '../Lib/Utils';

import ApplicationActions from '../Redux/ApplicationRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';

import { YES_OPTION, NO_OPTION } from '../Config/ApplicationConfig';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

const BASE = 'ownLandCode';
class OwnsLandScreen extends Component {
  static navigationOptions = {
    title: I18n.t('menu.OwnsLand')
  };
  state = {
    errorText: null
  };

  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else if (application[BASE] === YES_OPTION) {
        history.push('OwnLandProofScreen');
      } else if (application[BASE] === NO_OPTION) {
        history.push('LandOwnerContactInformationScreen');
      } else {
        this.setState({ errorText: 'Error saving application' });
      }
    }
  }

  render() {
    const { initilized, handleSubmit, isValid, fetching } = this.props;

    const { errorText } = this.state;

    if (!initilized) return null;

    const disabled = false;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="OwnsLand.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="OwnsLand.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupRadio
                  field="ownLandCode"
                  labelId="OwnsLand.OwnsLand"
                  optionsKey="yes_no"
                  reverse
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit(this.onSubmit)}
                  disabled={!isValid || fetching}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

OwnsLandScreen = withFormik({
  mapPropsToValues: props => {
    const { application } = props;
    return application;
  },
  isInitialValid: props => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { updateApplication } = props;

    console.log('updatingApplication', values);
    setStatus('updatingApplication');
    updateApplication(values);
  }
})(OwnsLandScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error },
  persist: { options, application }
}) => ({
  options,
  initilized,
  fetching,
  errorText: error,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnsLandScreen);
