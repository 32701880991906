// @flow

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import * as R from 'ramda';

import {
  parsePickerJson,
  parseDocTypesJson,
  parseScreeningQuestionsJson
} from './OptionsRedux';

import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';

import DefaultPickerEn from './DefaultPickerEn.json';
import DefaultPickerEs from './DefaultPickerEs.json';
import DefaultDocTypes_4_En from './DefaultDocTypes_4_En.json';
import DefaultDocTypes_4_Es from './DefaultDocTypes_4_Es.json';
import DefaultScreeningQuestions_4_En from './DefaultScreeningQuestions_4_En.json';
import DefaultScreeningQuestions_4_Es from './DefaultScreeningQuestions_4_Es.json';

const languageCode = DEFAULT_LANGUAGE;
const allOptions = parsePickerJson(DefaultPickerEn, DefaultPickerEs);
const allDocTypes = {
  4: parseDocTypesJson(DefaultDocTypes_4_En, DefaultDocTypes_4_Es)
};
const allScreeningQuestions = {
  4: parseScreeningQuestionsJson(
    DefaultScreeningQuestions_4_En,
    DefaultScreeningQuestions_4_Es
  )
};

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  PersistSetApplications: ['applications'],
  PersistSetApplication: ['application'],
  PersistSetAccount: ['account'],
  PersistSetLocale: ['locale'],
  PersistSetOfflineDocuments: ['offlineDocuments'],

  PersistSetKiosk: ['isKiosk'],
  PersistSetToken: ['tokenId', 'token'],
  PersistSetScreen: ['screen'],
  PersistSetTorch: ['torch'],
  PersistSetProgramCode: ['programCode'],
  PersistSetApplicationId: ['applicationId'],

  PersistSetAllOptions: ['allOptions'],
  PersistSetAllDocTypes: ['allDocTypes'],
  PersistSetAllScreeningQuestions: ['allScreeningQuestions'],

  PersistSetOptions: ['options'],
  PersistSetDocTypes: ['docTypes'],
  PersistSetScreeningQuestions: ['screeningQuestions'],

  PersistReset: null
});

export const PersistTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  applications: {},
  application: null,
  isKiosk: false,
  locale: null,
  account: null,
  screen: null,
  torch: false,
  token: null,
  tokenId: null,
  programCode: null, // This needs to be saved before we are logged in or have an application.
  applicationId: null,
  options: allOptions[languageCode],
  allOptions,
  docTypes: {},
  allDocTypes,
  screeningQuestions: {},
  allScreeningQuestions,
  offlineDocuments: {}
});

/* ------------- Reducers ------------- */

export const setApplications = (state, { applications }) =>
  state.merge({ applications });
export const setApplication = (state, { application }) => {
  if (R.path(['id'], application)) {
    return state
      .merge({ application, applicationId: R.path(['id'], application) })
      .setIn(['applications', application.id], application);
  }
  return state.merge({
    application,
    applicationId: R.path(['id'], application)
  });
};

export const setAccount = (state, { account }) => state.merge({ account });
export const setKiosk = (state, { isKiosk }) => state.merge({ isKiosk });

export const setOfflineDocuments = (state, { offlineDocuments }) =>
  state.merge({ offlineDocuments });

export const setToken = (state, { tokenId, token }) =>
  state.merge({ tokenId, token });
export const setLocale = (state, { locale }) => state.merge({ locale });
export const setScreen = (state, { screen }) => state.merge({ screen });
export const setTorch = (state, { torch }) => state.merge({ torch });
export const setProgramCode = (state, { programCode }) =>
  state.merge({ programCode });

export const setApplicationId = (state, { applicationId }) =>
  state.merge({ applicationId });

export const setAllOptions = (state, { allOptions }) =>
  state.merge({ allOptions });
export const setAllDocTypes = (state, { allDocTypes }) =>
  state.merge({ allDocTypes });
export const setAllScreeningQuestions = (state, { allScreeningQuestions }) =>
  state.merge({ allScreeningQuestions });

export const setOptions = (state, { options }) => state.merge({ options });
export const setDocTypes = (state, { docTypes }) => state.merge({ docTypes });
export const setScreeningQuestions = (state, { screeningQuestions }) =>
  state.merge({ screeningQuestions });

// Save Applications State for Kiosk Mode
export const reset = state =>
  INITIAL_STATE.merge({
    applications: state.applications || {},
    offlineDocuments: state.offlineDocuments || {}
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PERSIST_SET_APPLICATIONS]: setApplications,
  [Types.PERSIST_SET_APPLICATION]: setApplication,
  [Types.PERSIST_SET_ACCOUNT]: setAccount,
  [Types.PERSIST_SET_LOCALE]: setLocale,
  [Types.PERSIST_SET_KIOSK]: setKiosk,
  [Types.PERSIST_SET_OFFLINE_DOCUMENTS]: setOfflineDocuments,

  [Types.PERSIST_SET_TOKEN]: setToken,
  [Types.PERSIST_SET_SCREEN]: setScreen,
  [Types.PERSIST_SET_TORCH]: setTorch,
  [Types.PERSIST_SET_PROGRAM_CODE]: setProgramCode,
  [Types.PERSIST_SET_APPLICATION_ID]: setApplicationId,

  [Types.PERSIST_SET_ALL_OPTIONS]: setAllOptions,
  [Types.PERSIST_SET_ALL_DOC_TYPES]: setAllDocTypes,
  [Types.PERSIST_SET_ALL_SCREENING_QUESTIONS]: setAllScreeningQuestions,
  [Types.PERSIST_SET_OPTIONS]: setOptions,
  [Types.PERSIST_SET_DOC_TYPES]: setDocTypes,
  [Types.PERSIST_SET_SCREENING_QUESTIONS]: setScreeningQuestions,

  [Types.PERSIST_RESET]: reset
});

/* ------------- Selectors ------------- */
