import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import * as R from 'ramda';
import { connect } from 'react-redux';
// import SignatureCapture from 'react-native-signature-capture';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../../Components/Grid';

import ApplicationActions from '../../Redux/ApplicationRedux';
import DocumentActions, { getDocumentURL } from '../../Redux/DocumentRedux';

import CaptureSignature from '../../Components/CaptureSignature';
import FormattedMessage from '../../Components/FormattedMessage';
import FullButton from '../../Components/FullButton';

import { getPersonId } from '../../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../../Themes/';
import styles from '../Styles/ScreenStyles';

import DOCUMENT_TYPES from '../../Config/DocumentTypesConfig';

const DOCUMENT_TYPE = DOCUMENT_TYPES.signatureNoIncome.code;
const DOCUMENT_KEYWORD_VALUE = '';

class NoIncomeAffidavit extends Component {
  state = {
    errorText: null,
    signed: false
  };

  loadedDocuments = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  componentWillReceiveProps(nextProps) {
    const { fetching, error, onComplete } = nextProps;

    if (this.uploadingDocuments && !fetching) {
      this.uploadingDocuments = false;
      if (error) {
        this.setState({ errorText: error });
      } else {
        onComplete();
      }
    }
  }

  handleDeleteFiles = () => {
    const { application, deleteDocuments, base, index } = this.props;

    const personId = getPersonId(application, base, index);

    this.deletingDocuments = true;
    console.log(
      'deleteDocuments',
      personId,
      DOCUMENT_TYPE,
      DOCUMENT_KEYWORD_VALUE
    );
    deleteDocuments(personId, DOCUMENT_TYPE, DOCUMENT_KEYWORD_VALUE);
  };

  handleDragEvent = () => {
    if (!this.state.signed) {
      this.setState({ signed: true });
    }
  };

  handleResetSign = () => {
    this.handleDeleteFiles();
    this.refs.sign.resetImage();
    this.setState({ signed: false });
  };

  handleSaveSign = () => {
    const { documents, onComplete, application, base, index } = this.props;

    const personId = getPersonId(application, base, index);
    let signatureImage = null;
    documents.forEach(ele => {
      if (
        ele &&
        ele.documentType === DOCUMENT_TYPE &&
        ele.personId === personId
      ) {
        signatureImage = ele.fileDownloadURL;
      }
    });

    if (signatureImage) {
      onComplete();
    } else {
      this.refs.sign.saveImage();
    }
  };

  handleSavedSign = result => {
    const { application, uploadSignature, base, index } = this.props;

    const personId = getPersonId(application, base, index);
    console.log(
      'handleSavedSign personId',
      personId,
      application,
      base,
      index,
      result
    );

    this.uploadingDocuments = true;
    uploadSignature(personId, result, DOCUMENT_TYPE, DOCUMENT_KEYWORD_VALUE);
  };

  render() {
    const {
      application,
      base,
      index,
      userId,
      tokenId,
      token,
      initilized,
      fetching,
      errorText,
      onComplete,
      documents
    } = this.props;

    const { signed } = this.state;

    if (!initilized) return null;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" style={styles.errorText} />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    const personId = getPersonId(application, base, index);

    let signatureImage = null;
    documents &&
      documents.forEach(ele => {
        if (
          ele &&
          ele.documentType === DOCUMENT_TYPE &&
          ele.personId === personId
        ) {
          signatureImage = getDocumentURL(
            ele.fileDownloadURL,
            userId,
            tokenId,
            token
          );
          console.log('found signatureImage', signatureImage);
        }
      });

    return (
      <KeyboardAwareScrollView
        ref={scroll => {
          this._scroll = scroll;
        }}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
      >
        <Grid>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="NoIncomeAffidavit.title"
                style={Fonts.style.title}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id="NoIncomeAffidavit.subtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col>{errorView}</Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id={
                  Platform.OS === 'web'
                    ? 'form.SignWithFingerWeb'
                    : 'form.SignWithFinger'
                }
                style={Fonts.style.sectionHeader}
              />
            </Col>
          </Row>

          <CaptureSignature
            base={base}
            index={index}
            documentType={DOCUMENT_TYPE}
            onComplete={onComplete}
            labelId="form.continueNextStep"
          />

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('buttons.SkipThisStep')}
                onPress={onComplete}
                type="link"
                disabled={fetching}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
      </KeyboardAwareScrollView>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { application, account, tokenId, token },
  document: { options, documents, error, fetching }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  documents,
  error,
  fetching,
  options,
  initilized,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadSignature: (personId, signature, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentUploadSignature(
        personId,
        signature,
        documentTypeCode,
        keywordValueCode
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoIncomeAffidavit);
