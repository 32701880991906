import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import diff from 'deep-diff';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { View, Text, Platform, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

import { Grid, Row, Col } from '../Components/Grid';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FormGroupSelect from '../Components/FormGroupSelect';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import SelectUploadDocumentType from '../Components/SelectUploadDocumentType';
import AddressForm from '../Components/AddressForm';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  zipcodeFormat,
  getPersonId,
  getBase,
  getBaseString
} from '../Lib/Utils';
import validationSchema from '../Lib/ValidationSchema';

// Styles
import { Fonts, Metrics, Colors } from '../Themes/';
import styles from './Styles/ScreenStyles';

import {
  DEFAULT_STATE_CODE,
  ADDRESS_TYPE_PO_BOX,
  ADDRESS_TYPE_RURAL_ROUTE,
  ADDRESS_TYPE_HIGHWAY_CONTRACT,
  ADDRESS_TYPE_RESIDENTIAL,
  ADDRESS_TYPE_APARTMENT,
  YES_OPTION
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;
const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const TENANTS_BASE = DATA_STRUCTURE.tenants;

const CITIZENSHIP_DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfCitizenship.code;
const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfIdentification.code;
const DRIVERS_LICENSE_KEYWORD_VALUE =
  DOCUMENT_TYPES.proofOfIdentification.keywords.DriversLicense;

export const ContactInfoComplete = (application, documents, base, index) => {
  const appBase = getBase(application, base, index);
  return R.pathOr('N/A', ['ssn'], appBase) !== 'N/A';
};

class ContactInfo extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.number,
    // navigation: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired
  };

  state = {
    errorText: null,
    photo: null,
    width: null,
    height: null
  };

  refs = {};

  updatingApplication = false;

  loadedDocuments = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  formInitilized = false;

  componentDidMount() {
    const { initilized, loadDocuments } = this.props;
    // loadDocuments(DOCUMENT_TYPE);
    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.formInitilized = true;
    //   this.initilizeForm(this.props);
    // }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isSubmitting,
      setSubmitting,
      data,
      errorText,
      fetching,
      initilized,
      submitCount,
      onComplete,
      status,
      setStatus
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setSubmitting(false);
      setStatus('');
      console.log('submitted', errorText);
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        // if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        // setSubmitting(false);
        // console.log('onComplete', data);
        onComplete(data);
      }
    } else if (
      submitCount > 0 &&
      submitCount !== this.props.submitCount &&
      !this.props.isValid
    ) {
      this.setState({ errorText: errorText || I18n.t('error.formError') });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.submitCount > 0 &&
      prevProps.submitCount !== this.props.submitCount &&
      !this.props.isValid
    ) {
      console.log(
        'this.props.submitCount',
        prevProps.submitCount,
        this.props.submitCount
      );
      if (Platform.OS === 'web') {
        if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        setTimeout(() => {
          if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0 });
        }, 300);
      }
    }
  }

  handleUploadFiles = photos => {
    // console.log(photos);

    const { application, index, base, uploadDocuments } = this.props;

    const personId = getPersonId(application, base, index);
    // index >= 0
    //   ? R.pathOr(0, [base, index, 'id'], application) > 0
    //     ? R.path([base, index, 'id'], application)
    //     : `${base}_${index}`
    //   : R.pathOr(0, [base, 'id'], application) > 0
    //   ? R.path([base, 'id'], application)
    //   : base;

    this.uploadDocuments = true;
    // console.log(personId, photos, DOCUMENT_TYPE, DRIVERS_LICENSE_KEYWORD_VALUE);
    uploadDocuments(
      personId,
      photos,
      DOCUMENT_TYPE,
      DRIVERS_LICENSE_KEYWORD_VALUE
    );
  };

  handleDeleteFiles = () => {
    const { application, index, base, deleteDocuments } = this.props;

    const personId = getPersonId(application, base, index);
    // index >= 0
    //   ? R.pathOr(0, [base, index, 'id'], application) > 0
    //     ? R.path([base, index, 'id'], application)
    //     : `${base}_${index}`
    //   : R.pathOr(0, [base, 'id'], application) > 0
    //   ? R.path([base, 'id'], application)
    //   : base;

    this.deletingDocuments = true;
    console.log(
      'deleteDocuments',
      personId,
      DOCUMENT_TYPE,
      DRIVERS_LICENSE_KEYWORD_VALUE
    );
    deleteDocuments(personId, DOCUMENT_TYPE, DRIVERS_LICENSE_KEYWORD_VALUE);
  };

  renderAddress(addressTypeCode) {
    const { isSubmitting } = this.props;
    let addressHeader = null;
    switch (addressTypeCode) {
      default:
      case ADDRESS_TYPE_PO_BOX:
        addressHeader = (
          <Row>
            <Col sm={12}>
              <FormGroupText
                ref={ref => {
                  this._boxNumber = ref;
                }}
                field={`${CONTACT_BASE}.boxNumber`}
                labelId="ContactInfo.boxNumber"
                placeholderId="ContactInfo.boxNumber_placeholder"
                autoCapitalize="words"
                returnKeyType="next"
                onSubmitEditing={() => this._City.focus()}
                required
                validate={isRequired}
                disabled={isSubmitting}
              />
            </Col>
          </Row>
        );
        break;
      case ADDRESS_TYPE_RURAL_ROUTE:
      case ADDRESS_TYPE_HIGHWAY_CONTRACT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address1 = ref;
                  }}
                  field={`${CONTACT_BASE}.RRHCNumber`}
                  labelId="ContactInfo.RRHCNumber"
                  placeholderId="ContactInfo.RRHCNumber_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._boxNumber.focus()}
                  required
                  validate={isRequired}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._boxNumber = ref;
                  }}
                  field={`${CONTACT_BASE}.boxNumber`}
                  labelId="ContactInfo.boxNumber"
                  placeholderId="ContactInfo.boxNumber_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  required
                  validate={isRequired}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </View>
        );
        break;
      case ADDRESS_TYPE_RESIDENTIAL:
      case ADDRESS_TYPE_APARTMENT:
        addressHeader = (
          <View>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address = ref;
                  }}
                  field={`${CONTACT_BASE}.address`}
                  labelId="ContactInfo.address1"
                  placeholderId="ContactInfo.address1_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address2.focus()}
                  required
                  validate={isRequired}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address2 = ref;
                  }}
                  field={`${CONTACT_BASE}.address2`}
                  labelId="ContactInfo.address2"
                  placeholderId="ContactInfo.address2_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._Address3.focus()}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref={ref => {
                    this._Address3 = ref;
                  }}
                  field={`${CONTACT_BASE}.address3`}
                  labelId="ContactInfo.address3"
                  placeholderId="ContactInfo.address3_placeholder"
                  autoCapitalize="words"
                  returnKeyType="next"
                  onSubmitEditing={() => this._City.focus()}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </View>
        );
        break;
    }

    if (addressTypeCode)
      return (
        <View>
          {addressHeader}

          <Row>
            <Col sm={12}>
              <FormGroupText
                ref={ref => {
                  this._City = ref;
                }}
                field={`${CONTACT_BASE}.city`}
                labelId="ContactInfo.city"
                placeholderId="ContactInfo.city_placeholder"
                required
                validate={isRequired}
                disabled={isSubmitting}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroupSelect
                ref={ref => (this._State = ref)}
                field={`${CONTACT_BASE}.stateCode`}
                labelId="ContactInfo.state"
                placeholderId="ContactInfo.state_placeholder"
                optionsKey="state"
                required
                validate={isRequired}
                disabled={isSubmitting}
              />
            </Col>
            <Col sm={6}>
              <FormGroupText
                ref={ref => {
                  this._Zip = ref;
                }}
                field={`${CONTACT_BASE}.zip`}
                labelId="ContactInfo.zipcode"
                placeholderId="ContactInfo.zipcode_placeholder"
                returnKeyType="next"
                maxLength={10}
                keyboardType="numeric"
                format={zipcodeFormat}
                required
                validate={isZipcode}
                disabled={isSubmitting}
              />
            </Col>
          </Row>
        </View>
      );
    return null;
  }

  render() {
    const {
      errors,
      base,
      index,
      application,
      isKiosk,
      initilized,
      handleSubmit,
      documents,
      documentsFetching,
      documentsError,
      values,
      isValid,
      fetching,
      isSubmitting
    } = this.props;

    const { errorText } = this.state;

    if (!initilized) return null;

    const appBase = getBase(values, base, index);
    const baseString = getBaseString(base, index);

    const disabled = isSubmitting;

    const kiosk = isKiosk;

    const validateEmail = kiosk ? isEmail : isRequiredEmail;

    const personId = getPersonId(application, base, index);
    // index >= 0
    //   ? R.pathOr(0, [base, index, 'id'], application) > 0
    //     ? R.path([base, index, 'id'], application)
    //     : `${base}_${index}`
    //   : R.pathOr(0, [base, 'id'], application) > 0
    //   ? R.path([base, 'id'], application)
    //   : base;

    const identityDocuments = [];
    documents &&
      documents.forEach(ele => {
        if (
          ele &&
          ele.personId === personId &&
          ele.documentType === DOCUMENT_TYPE &&
          ele.keywordValue === DRIVERS_LICENSE_KEYWORD_VALUE
        ) {
          identityDocuments.push({ uri: ele.fileDownloadURL });
        }
      });

    const addressTypeCode = R.path([CONTACT_BASE, 'addressTypeCode'], values);

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        ref={scroll => {
          this._scroll = scroll;
        }}
        style={styles.scrollViewContainer}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id={`${base}.infoTitle`}
                style={Fonts.style.title}
              />
              <FormattedMessage
                id={
                  Platform.OS === 'web' ||
                  (base !== APPLICANT_BASE && base !== CO_APPLICANT_BASE)
                    ? 'ContactInfo.subtitleWeb'
                    : 'ContactInfo.subtitle'
                }
                style={Fonts.style.subtitle}
              />
              {errorView}
            </Col>
          </Row>

          {/**
           * Begin Household Members Edit ONLY fields *
           *                                          */
          base === HOUSEHOLD_MEMBERS_BASE && appBase && appBase.ssn && (
            <Row>
              <Col sm={12}>
                <SelectUploadDocumentType
                  base={base}
                  index={index}
                  documentType={DOCUMENT_TYPE}
                  buttonLabelId=""
                  hideButton
                  hideDocTypes
                  multiple
                  horiz
                />
              </Col>
            </Row>
          )}
          {/**
           * End Household Members Edit ONLY fields *
           *                                        */}

          {/**
           * Start Common Information *
           *                          */}
          <Row>
            <Col sm={12}>
              <FormattedMessage
                id={
                  base === TENANTS_BASE
                    ? 'tenants.personalInformation'
                    : 'ContactInfo.contactname'
                }
                style={Fonts.style.sectionHeader}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormGroupText
                inputRef={ref => {
                  this._firstName = ref;
                }}
                field={`${baseString}.firstName`}
                labelId="ContactInfo.firstname"
                autoCapitalize="words"
                placeholderId="ContactInfo.firstname_placeholder"
                returnKeyType="next"
                required
                validate={isRequired}
                onSubmitEditing={e => {
                  this._middleName.focus();
                }}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormGroupText
                inputRef={ref => {
                  this._middleName = ref;
                }}
                field={`${baseString}.middleName`}
                labelId="ContactInfo.middlename"
                autoCapitalize="words"
                placeholderId="ContactInfo.middlename_placeholder"
                returnKeyType="next"
                onSubmitEditing={() => {
                  this._lastName.focus();
                }}
                disabled={disabled}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FormGroupText
                inputRef={ref => {
                  this._lastName = ref;
                }}
                field={`${baseString}.lastName`}
                labelId="ContactInfo.lastname"
                autoCapitalize="words"
                placeholderId="ContactInfo.lastname_placeholder"
                returnKeyType="next"
                onSubmitEditing={() => this._DateOfBirthMonth.focus()}
                required
                validate={isRequired}
                disabled={disabled}
              />
            </Col>
          </Row>

          {/**
           * End Common Information *
           *                        */}

          {/**
           * Start Everyone but Tenants *
           *                           */}
          {base !== TENANTS_BASE && (
            <View>
              <Row>
                <Col xs={12}>
                  <FormattedMessage
                    id="ContactInfo.dateofbirth"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <FormGroupText
                    inputRef={ref => {
                      this._DateOfBirthMonth = ref;
                    }}
                    field={`tmp.DateOfBirthMonth`}
                    labelId="ContactInfo.dateofbirthmonth"
                    placeholderId="ContactInfo.dateofbirthmonth_placeholder"
                    keyboardType="numeric"
                    returnKeyType="next"
                    maxLength={2}
                    onMaxLength={() => this._DateOfBirthDay.focus()}
                    onSubmitEditing={() => this._DateOfBirthDay.focus()}
                    validate={isMonth}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={4}>
                  <FormGroupText
                    inputRef={ref => {
                      this._DateOfBirthDay = ref;
                    }}
                    field={`tmp.DateOfBirthDay`}
                    labelId="ContactInfo.dateofbirthday"
                    placeholderId="ContactInfo.dateofbirthday_placeholder"
                    maxLength={2}
                    keyboardType="numeric"
                    returnKeyType="next"
                    onMaxLength={() => this._DateOfBirthYear.focus()}
                    onSubmitEditing={() => this._DateOfBirthYear.focus()}
                    validate={isDay}
                    disabled={disabled}
                  />
                </Col>
                <Col xs={4}>
                  <FormGroupText
                    inputRef={ref => {
                      this._DateOfBirthYear = ref;
                    }}
                    field={`tmp.DateOfBirthYear`}
                    labelId="ContactInfo.dateofbirthyear"
                    placeholderId="ContactInfo.dateofbirthyear_placeholder"
                    maxLength={4}
                    keyboardType="numeric"
                    returnKeyType="next"
                    onMaxLength={() => this._SSN.focus()}
                    onSubmitEditing={() => this._SSN.focus()}
                    validate={isPastYear}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {errors.DateOfBirth && (
                <Row>
                  <Col xs={12}>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                      }}
                    >
                      <Icon
                        name="md-alert"
                        size={20}
                        style={{
                          color: Colors.error,
                          margin: Metrics.baseMargin
                        }}
                      />
                      <FormattedMessage
                        id={errors.DateOfBirth}
                        style={[
                          Fonts.type.normal,
                          {
                            color: Colors.error,
                            paddingRight: Metrics.baseMargin
                          }
                        ]}
                      />
                    </View>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={ref => {
                      this._SSN = ref;
                    }}
                    field={`${baseString}.ssn`}
                    labelId="ContactInfo.ssn"
                    placeholderId="ContactInfo.ssn_placeholder"
                    format={ssnFormat}
                    maxLength={11}
                    onMaxLength={() =>
                      this._PrimaryPhone && this._PrimaryPhone.focus()
                    }
                    // keyboardType="numeric"
                    returnKeyType={
                      base === APPLICANT_BASE && this._PrimaryPhone
                        ? 'next'
                        : 'default'
                    }
                    onSubmitEditing={() => {
                      base === APPLICANT_BASE &&
                        this._PrimaryPhone &&
                        this._PrimaryPhone.focus();
                    }}
                    required
                    validate={isSsnNumber}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </View>
          )}

          {/**
           * Begin Applicant ONLY fields *
           *                             */
          base === APPLICANT_BASE && (
            <View>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="ContactInfo.contactinformation"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <FormGroupText
                    inputRef={ref => {
                      this._PrimaryPhone = ref;
                    }}
                    field={`${CONTACT_BASE}.primaryPhone`}
                    labelId="ContactInfo.PrimaryPhone"
                    placeholderId="ContactInfo.PrimaryPhone_placeholder"
                    maxLength={12}
                    onMaxLength={() =>
                      this._SecondaryPhone && this._SecondaryPhone.focus()
                    }
                    keyboardType="phone-pad"
                    format={phoneFormat}
                    validate={isPhoneNumber}
                    disabled={disabled}
                  />
                </Col>
                <Col sm={4}>
                  <FormGroupCheckbox
                    labelId="ContactInfo.PrimaryPhoneIsMobile"
                    field={`${CONTACT_BASE}.primaryPhoneIsMobile`}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={8}>
                  <FormGroupText
                    inputRef={ref => {
                      this._SecondaryPhone = ref;
                    }}
                    field={`${CONTACT_BASE}.secondaryPhone`}
                    labelId="ContactInfo.SecondaryPhone"
                    placeholderId="ContactInfo.SecondaryPhone_placeholder"
                    keyboardType="phone-pad"
                    maxLength={12}
                    onMaxLength={() => this._Email && this._Email.focus()}
                    validate={isPhoneNumber}
                    format={phoneFormat}
                    disabled={disabled}
                  />
                </Col>
                <Col sm={4}>
                  <FormGroupCheckbox
                    labelId="ContactInfo.SecondaryPhoneIsMobile"
                    field={`${CONTACT_BASE}.secondaryPhoneIsMobile`}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupText
                    inputRef={ref => {
                      this._Email = ref;
                    }}
                    field={`${CONTACT_BASE}.email`}
                    labelId="ContactInfo.Email"
                    placeholderId="ContactInfo.Email_placeholder"
                    autoCapitalize="none"
                    keyboardType="email-address"
                    forceTouched
                    required={!kiosk}
                    validate={validateEmail}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`${CONTACT_BASE}.preferredContactMethodCode`}
                    labelId="ContactInfo.PerferredContactMethod"
                    optionsKey="contact_method"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </View>
          )

          /**
           * End Applicant ONLY fields *
           *                           */
          }

          {/**
           * Begin Applicant and Tenant Information *
           *                                        */
          (base === APPLICANT_BASE || base === TENANTS_BASE) && (
            <View>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id={
                      base === TENANTS_BASE
                        ? 'tenants.mailingAddress'
                        : 'ContactInfo.mailingaddress'
                    }
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>

              <AddressForm
                addressString={base === APPLICANT_BASE ? CONTACT_BASE : baseString}
                addressValues={base === APPLICANT_BASE ? values[CONTACT_BASE] : appBase}
                disabled={fetching}
              />

              {/* <Row>
                <Col sm={12}>
                  <FormGroupSelect
                    field={`${CONTACT_BASE}.addressTypeCode`}
                    labelId="ContactInfo.addressTypeCode"
                    placeholderId="ContactInfo.addressTypeCode_placeholder"
                    optionsKey="address_type"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              {this.renderAddress(addressTypeCode)} */}
            </View>
          )
          /**
           * End Applicant and Tenant Information *
           *                                      */
          }

          {/**
           * Begin Homeowner ONLY fields *
           *                             */
          base === APPLICANT_BASE && (
            <View>
              {/* <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="ContactInfo.contactinformation"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row> */}
              {/* <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field="isUSCitizenCode"
                    labelId="ProveIdentity.isUSCitizenCode"
                    optionsKey="yes_no"
                    required
                    reverse
                    validate={isRequired}
                    disabled={fetching}
                  />
                </Col>
              </Row> */}
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field="isLawfullyPresentInUSCode"
                    labelId="ProveIdentity.isLawfullyPresentInUSCode"
                    optionsKey="lawful_presence_type"
                    required
                    reversed
                    validate={isRequired}
                    disabled={fetching}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="ProveIdentity.citizenshipProof"
                    style={Fonts.style.subtitle}
                  />
                  <SelectUploadDocumentType
                    base={base}
                    index={index}
                    onComplete={() => {}}
                    documentType={CITIZENSHIP_DOCUMENT_TYPE}
                    buttonLabelId=""
                    hideButton
                    multiple
                    horiz
                  />
                </Col>
              </Row>

              {/* <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    // TODO: Update to Real Code
                    field="placeholderWhoIsCitizenCode"
                    labelId="CertificationOfCitizenship.whoIsCitizen"
                    // TODO: Update to real picker code
                    // optionsKey="yes_no"
                    options={tempOptions}
                    required
                    validate={isRequired}
                    disabled={fetching}
                  />
                </Col>
              </Row> */}
            </View>
          )
          /**
           * End Homeowner ONLY fields *
           *                           */
          }

          {/**
           * Begin Homeowner, Co-Homeowner and Household Members ONLY fields *
           *                                                                 */
          (base === APPLICANT_BASE ||
            base === CO_APPLICANT_BASE ||
            base === HOUSEHOLD_MEMBERS_BASE) && (
            <View>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="ContactInfo.demographicInformation"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`${baseString}.genderCode`}
                    labelId="ContactInfo.gender"
                    optionsKey="gender"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`${baseString}.raceCode`}
                    labelId="ContactInfo.race"
                    optionsKey="race"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`${baseString}.ethnicityCode`}
                    labelId="ContactInfo.ethnicity"
                    optionsKey="ethnicity"
                    required
                    validate={isRequired}
                    disabled={disabled}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field={`${baseString}.disabilityCode`}
                    labelId="ContactInfo.disabilityCode"
                    optionsKey="disability"
                    required
                    validate={isRequired}
                    reverse
                    disabled={disabled}
                  />
                </Col>
              </Row>
              {appBase.disabilityCode === YES_OPTION && (
                <Row>
                  <Col sm={12}>
                    <FormGroupRadio
                      field={`${baseString}.specialAccomodationsRequiredCode`}
                      labelId="ContactInfo.specialAccomodationsRequiredCode"
                      optionsKey="yes_no"
                      required
                      validate={isRequired}
                      reverse
                      disabled={disabled}
                    />
                  </Col>
                </Row>
              )}
            </View>
          )
          /**
           * Begin Homeowner, Co-Homeowner and Household Members ONLY fields *
           *                                                                 */
          }

          {/**
           * Begin Homeowner ONLY fields *
           *                             */
          base === APPLICANT_BASE && (
            <View>
              <Row>
                <Col sm={12}>
                  <FormattedMessage
                    id="ContactInfo.incomeInformation"
                    style={Fonts.style.sectionHeader}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroupRadio
                    field="householdIncomeCode"
                    labelId="ContactInfo.income"
                    optionsKey="household_income"
                    required
                    validate={isRequired}
                    disabled={fetching}
                  />
                </Col>
              </Row>
            </View>
          )
          /**
           * End Homeowner ONLY fields *
           *                           */
          }

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                onPress={handleSubmit}
                disabled={!isValid || fetching}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
      </KeyboardAwareScrollView>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

ContactInfo = withFormik({
  mapPropsToValues: props => {
    const {
      base,
      index,
      application,
      token,
      account,
      isKiosk,
      options
    } = props;
    const appBase = getBase(application, base, index);

    let newApplication = JSON.parse(JSON.stringify(application));
    // console.log('mapPropsToValues', JSON.stringify(newApplication, null, 2));

    if (!newApplication[CONTACT_BASE]) {
      newApplication[CONTACT_BASE] = {};
    }

    // Set Default State to NC
    if (
      !newApplication ||
      !newApplication[CONTACT_BASE] ||
      !newApplication[CONTACT_BASE].stateCode
    ) {
      // console.log('setting default state', CONTACT_BASE, DEFAULT_STATE_CODE);
      newApplication[CONTACT_BASE].stateCode = DEFAULT_STATE_CODE;
      // change(`${CONTACT_BASE}.stateCode`, DEFAULT_STATE_CODE);
    }
    // Copy City String to City Code
    if (
      newApplication[CONTACT_BASE].stateCode === DEFAULT_STATE_CODE &&
      newApplication[CONTACT_BASE].city &&
      options &&
      options.city
    ) {
      Object.keys(options.city).forEach(cityCode => {
        const city = options.city[cityCode];
        if (city.value === newApplication[CONTACT_BASE].city) {
          newApplication[CONTACT_BASE].cityCode = cityCode;
        }
      });
    }
    // console.log(options.city);

    if (base === APPLICANT_BASE) {
      // // Split Address
      // if (
      //   newApplication[CONTACT_BASE] &&
      //   newApplication[CONTACT_BASE].address
      // ) {
      //   const split = newApplication[CONTACT_BASE].address.split('\n');

      //   newApplication[CONTACT_BASE].address1 = split[0];
      //   newApplication[CONTACT_BASE].address2 = split[2];
      //   // change(`${CONTACT_BASE}.address1`, split[0]);
      //   // change(`${CONTACT_BASE}.address2`, split[1]);
      // }

      // Set Default EMail if not Set
      if (
        !isKiosk &&
        token &&
        account.emailAddress &&
        (!newApplication[CONTACT_BASE] || !newApplication[CONTACT_BASE].email)
      ) {
        newApplication[CONTACT_BASE].email = account.emailAddress;
        // change(`${CONTACT_BASE}.email`, account.emailAddress);
      }
    }

    if (appBase && appBase.dateOfBirth) {
      const dob = moment(appBase.dateOfBirth, 'M-D-YYYY');
      newApplication.tmp = {
        DateOfBirthMonth: dob.format('M'),
        DateOfBirthDay: dob.format('D'),
        DateOfBirthYear: dob.format('YYYY')
      };
      // change('DateOfBirthMonth', dob.format('M'));
      // change('DateOfBirthDay', dob.format('D'));
      // change('DateOfBirthYear', dob.format('YYYY'));
      // console.log('loading DOB', appBase.dateOfBirth, dob.format('MM-DD-YYYY'));
    } else {
      newApplication.tmp = {
        DateOfBirthMonth: '',
        DateOfBirthDay: '',
        DateOfBirthYear: ''
      };
      // change('DateOfBirthMonth', '');
      // change('DateOfBirthDay', '');
      // change('DateOfBirthYear', '');
    }

    // console.log('loaded application', JSON.stringify(newApplication, null, 2));
    return newApplication;
  },

  validate: (values, props) => {
    // console.log('validating', values);
    const errors = {};
    // if (
    //   R.path(['contact', 'preferredContactMethod'], values) &&
    //   values.contact.preferredContactMethod === '3' &&
    //   !R.path(['contact', 'email'], values)
    // ) {
    //   errors[CONTACT_BASE] = {};
    //   errors[CONTACT_BASE].email = 'emailRequiredMethod';
    // }
    const { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear } = values.tmp;
    if (DateOfBirthMonth && DateOfBirthDay && DateOfBirthYear) {
      const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;
      const dateOfBirth = moment(dob, 'M-D-YYYY');
      if (!dateOfBirth.isValid()) {
        errors.DateOfBirth = 'error.invalidDate';
      }
    }

    return errors;
  },
  isInitialValid: props => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props, dirty }) => {
    const { application, base, index, options } = props;

    // let newValues = Immutable.asMutable(values) // Does not work
    const newValues = Object.assign({}, values);

    const {
      tmp: { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear }
    } = values;

    const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;

    if (index >= 0) {
      newValues[base][index].dateOfBirth = dob;
    } else {
      newValues[base].dateOfBirth = dob;
    }

    // Remove Temp Values
    delete newValues.tmp;

    // Copy cityCode to city if DEFAULT_STATE_CODE
    if (newValues[CONTACT_BASE].stateCode === DEFAULT_STATE_CODE) {
      newValues[CONTACT_BASE].city = R.pathOr(
        '',
        ['city', newValues[CONTACT_BASE].cityCode, 'value'],
        options
      );
    }

    // console.log(
    //   'ContactInfo updateApplication',
    //   JSON.stringify(newValues, null, 2)
    // );

    // const diffResults = diff(application, newValues);
    // console.log(JSON.stringify(diffResults, null, 2));

    props.updateApplication(newValues);
    setStatus('updatingApplication');
  }
})(ContactInfo);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { application, account, token, tokenId, isKiosk, options },
  document,
  form
}) => ({
  account,
  isKiosk,
  options,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application,
  documents: document.documents,
  documentsError: document.error,
  documentsFetching: document.fetching,
  initialValues: application
  // currentForm: form.ContactInfo
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);
