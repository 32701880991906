import React, { Component } from 'react';
import {
  Platform,
  Image,
  Text,
  View,
  TouchableOpacity,
  ScrollView
} from 'react-native';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as R from 'ramda';
import moment from 'moment';

import DeviceInfo from 'react-native-device-info';
import codePush from 'react-native-code-push';

import { ApplicantIdentificationScreenComplete } from '../Containers/ApplicantIdentificationScreen';
import { ApplicantIncomeScreenComplete } from '../Containers/ApplicantIncomeScreen';
import { ApplicantInformationScreenComplete } from '../Containers/ApplicantInformationScreen';

import { CoapplicantIncomeScreenComplete } from '../Containers/CoapplicantIncomeScreen';
import { CoapplicantInformationScreenComplete } from '../Containers/CoapplicantInformationScreen';
import { CoapplicantIdentificationScreenComplete } from '../Containers/CoapplicantIdentificationScreen';

import { HouseholdMembersScreenComplete } from '../Containers/HouseholdMembersScreen';
import { TenantsScreenComplete } from '../Containers/TenantsScreen';

import { LienOnPropertyScreenComplete } from '../Containers/LienOnPropertyScreen';
import { DamagedAddressScreenComplete } from '../Containers/DamagedAddressScreen';
import { OwnHomeScreenComplete } from '../Containers/OwnHomeScreen';
import { PrimaryResidenceScreenComplete } from '../Containers/PrimaryResidenceScreen';
import { FloodWaterEnterHomeScreenComplete } from '../Containers/FloodWaterEnterHomeScreen';
import { InsuranceScreenComplete } from '../Containers/InsuranceScreen';
import { OtherFundsScreenComplete } from '../Containers/OtherFundsScreen';

import { PoaDesigneeScreenComplete } from '../Containers/PoaDesigneeScreen';
import { CommsDesigneeScreenComplete } from '../Containers/CommsDesigneeScreen';

import {
  getPersonId,
  hasSignatureDocument,
  hasDocument,
  personHasIncome
} from '../Lib/Utils';
import isDev from '../Lib/isDev';

import { Router, Route, Link } from './Router';

import FullButton from '../Components/FullButton';
import PrivacyPolicyLink from '../Components/PrivacyPolicyLink';

// Styles
import styles from './Styles/NavigationStyles';
import { Images, Fonts, Metrics } from '../Themes/';

import {
  RENTAL_PROGRAM_CODE,
  YES_OPTION,
  NO_OPTION,
  TRUE_OPTION,
  FALSE_OPTION,
  STATUS_IN_PROGRESS,
  API_ENVIROMENT
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;
const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const LEIN_BASE = DATA_STRUCTURE.lien_holders;
const TENANTS_BASE = DATA_STRUCTURE.tenants;
const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;
const INSURANCE_BASE = DATA_STRUCTURE.insurance;

const DrawerItem = ({
  closeDrawer,
  componentName,
  activeItemKey,
  style,
  indent,
  title,
  complete,
  clickable
}) => (
  <View
    style={[
      activeItemKey === componentName
        ? styles.itemContainerActive
        : styles.itemContainer,
      indent && styles.itemIndent
    ]}
  >
    <Link
      to={componentName === 'HomeScreen' ? '/' : `/${componentName}`}
      onClick={() => {
        if (Platform.OS === 'web') {
          console.log(`/${componentName}`);
          // history.push(`/${componentName}`);
          console.log('DrawerItem onClick closeDrawer');
          closeDrawer();
        }
      }}
      onPress={() => {
        if (Platform.OS !== 'web') {
          console.log(`/${componentName}`);
          // history.push(`/${componentName}`);
          console.log('DrawerItem onPress closeDrawer');
          closeDrawer();
        }
      }}
    >
      <Text
        style={[
          activeItemKey === componentName
            ? styles.itemTextActive
            : styles.itemText,
          indent
            ? complete
              ? styles.itemIndentTextBold
              : styles.itemIndentText
            : {}
        ]}
      >
        {title
          ? I18n.t(`menu.${title}`)
          : I18n.t(`menu.${componentName.replace('Screen', '')}`)}
      </Text>
    </Link>
  </View>
);

export const getProgress = (application, documents) => {
  const progress = {};

  const applicantPersonId = getPersonId(application, APPLICANT_BASE);
  const coApplicantPersonId = getPersonId(application, CO_APPLICANT_BASE);
  let personId = null;

  progress.CertificationOfCitizenshipScreen = hasSignatureDocument(
    documents,
    applicantPersonId,
    DOCUMENT_TYPES.signatureLawfulPresence.code
  );

  progress.ConsentToShareDataScreen = hasSignatureDocument(
    documents,
    applicantPersonId,
    DOCUMENT_TYPES.signatureSubrogationAgreement.code
  );

  progress.CommsDesigneeScreen = CommsDesigneeScreenComplete(
    application,
    documents
  );

  progress.PoaDesigneeScreen = PoaDesigneeScreenComplete(
    application,
    documents
  );

  progress.OtherFundsScreen = OtherFundsScreenComplete(application, documents);

  progress.InsuranceScreen = InsuranceScreenComplete(application, documents);

  progress.FloodWaterEnterHomeScreen = FloodWaterEnterHomeScreenComplete(
    application,
    documents
  );

  progress.PrimaryResidenceScreen = PrimaryResidenceScreenComplete(
    application,
    documents
  );
  progress.OwnHomeScreen = OwnHomeScreenComplete(application, documents);

  progress.LienOnPropertyScreen = LienOnPropertyScreenComplete(
    application,
    documents
  );

  progress.DamagedAddressScreen = DamagedAddressScreenComplete(
    application,
    documents
  );

  progress.TenantsScreen = TenantsScreenComplete(application, documents);

  progress.HouseholdMembersScreen = HouseholdMembersScreenComplete(
    application,
    documents
  );

  progress.CoapplicantExistsScreen =
    progress.DamagedAddressScreen ||
    R.pathOr('N/A', [CO_APPLICANT_BASE], application) !== 'N/A';

  progress.CoapplicantIdentificationScreen = CoapplicantIdentificationScreenComplete(
    application,
    documents
  );

  progress.CoapplicantInformationScreen = CoapplicantInformationScreenComplete(
    application,
    documents
  );

  progress.CoapplicantIncomeScreen = CoapplicantIncomeScreenComplete(
    application,
    documents
  );

  progress.ApplicantIdentificationScreen = ApplicantIdentificationScreenComplete(
    application,
    documents
  );

  progress.ApplicantInformationScreen = ApplicantInformationScreenComplete(
    application,
    documents
  );

  progress.ApplicantIncomeScreen = ApplicantIncomeScreenComplete(
    application,
    documents
  );

  return progress;
};

class DrawerContents extends Component {
  state = { codePushUpdate: {} };

  componentDidMount() {
    codePush.getUpdateMetadata &&
      codePush.getUpdateMetadata().then(codePushUpdate => {
        console.log(codePushUpdate);
        this.setState({ codePushUpdate });
      });
  }
  // componentWillReceiveProps(nextProps) {
  //   // If the path has changed, then close the drawer.
  //   if (this.props.location.pathname !== nextProps.location.pathname) {
  //     nextProps.closeDrawer();
  //   }
  // }

  render() {
    const { application, documents, closeDrawer, push, isKiosk } = this.props;
    const { codePushUpdate } = this.state;

    // console.log(this.props);
    const kiosk = isKiosk;
    const dev = isDev();
    const activeItemKey = '';

    const codePushVersion =
      (codePushUpdate && codePushUpdate.label && `-${codePushUpdate.label}`) ||
      '';

    const env =
      API_ENVIROMENT !== 'PROD' ? `${API_ENVIROMENT.toLowerCase()}` : '';
    const build =
      (Platform.OS === 'ios' || Platform.OS === 'android') &&
      `${
        isKiosk ? 'kiosk-' : ''
      }${DeviceInfo.getReadableVersion()}${codePushVersion}`;
    const dash = env && build ? '-' : '';

    if (
      !application ||
      !application.programCode ||
      application.status !== STATUS_IN_PROGRESS
    ) {
      return (
        <ScrollView style={styles.background}>
          <View style={styles.logoContainer}>
            <Image source={Images.logo} style={styles.logo} />
          </View>
          <DrawerItem
            closeDrawer={closeDrawer}
            componentName="HomeScreen"
            title="Home"
            activeItemKey={activeItemKey}
          />
          {kiosk && (
            <DrawerItem
              closeDrawer={closeDrawer}
              componentName="SyncStatusScreen"
              activeItemKey={activeItemKey}
            />
          )}
          <PrivacyPolicyLink />

          <Text
            style={{
              color: '#aaa',
              textAlign: 'right',
              padding: 10
            }}
          >
            {env}
            {dash}
            {build}
          </Text>
        </ScrollView>
      );
    }

    const isRentalProgram =
      application.programCode.toString() === RENTAL_PROGRAM_CODE.toString();

    const progress = getProgress(application, documents);

    return (
      <ScrollView style={styles.background}>
        <View style={styles.logoContainer}>
          <Image source={Images.logo} style={styles.logo} />
        </View>
        <DrawerItem
          closeDrawer={closeDrawer}
          componentName="HomeScreen"
          activeItemKey={activeItemKey}
        />

        <DrawerItem
          closeDrawer={closeDrawer}
          title="Applicant"
          componentName="ApplicantIdentificationScreen"
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="ApplicantIdentificationScreen"
          // complete={progress.ApplicantIdentificationScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="ApplicantInformationScreen"
          // complete={progress.ApplicantInformationScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="ApplicantIncomeScreen"
          // complete={progress.ApplicantIncomeScreen}
          activeItemKey={activeItemKey}
        />

        <DrawerItem
          closeDrawer={closeDrawer}
          title="Coapplicant"
          componentName="CoapplicantExistsScreen"
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="CoapplicantExistsScreen"
          // complete={progress.CoapplicantExistsScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="CoapplicantIdentificationScreen"
          // complete={progress.CoapplicantIdentificationScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="CoapplicantInformationScreen"
          // complete={progress.CoapplicantInformationScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="CoapplicantIncomeScreen"
          // complete={progress.CoapplicantIncomeScreen}
          activeItemKey={activeItemKey}
        />

        <DrawerItem
          closeDrawer={closeDrawer}
          componentName="HouseholdMembersScreen"
          // complete={progress.HouseholdMembersScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          componentName="TenantsScreen"
          // complete={progress.TenantsScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          title="DamagedAddress"
          componentName="DamagedAddressScreen"
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          title="DamagedAddressInformation"
          componentName="DamagedAddressScreen"
          // complete={progress.DamagedAddressScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="LienOnPropertyScreen"
          // complete={progress.LienOnPropertyScreen}
          activeItemKey={activeItemKey}
        />

        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          title="OwnHome"
          componentName="OwnHomeScreen"
          // complete={progress.OwnHomeScreen}
          activeItemKey={activeItemKey}
        />
        {!isRentalProgram && (
          <DrawerItem
            closeDrawer={closeDrawer}
            indent
            title="PrimaryResidence"
            componentName="PrimaryResidenceScreen"
            // complete={progress.PrimaryResidenceScreen}
            activeItemKey={activeItemKey}
          />
        )}

        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="FloodWaterEnterHomeScreen"
          // complete={progress.FloodWaterEnterHomeScreen}
          activeItemKey={activeItemKey}
        />

        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="InsuranceScreen"
          // complete={progress.InsuranceScreen}
          activeItemKey={activeItemKey}
        />

        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="OtherFundsScreen"
          // complete={progress.OtherFundsScreen}
          activeItemKey={activeItemKey}
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          title="Communication"
          componentName="PoaDesigneeScreen"
        />
        <DrawerItem
          closeDrawer={closeDrawer}
          indent
          componentName="PoaDesigneeScreen"
          // complete={progress.PoaDesigneeScreen}
          activeItemKey={activeItemKey}
        />
        {Platform.OS !== 'web' && (
          <DrawerItem
            closeDrawer={closeDrawer}
            componentName="ConsentToShareDataScreen"
            // complete={progress.ConsentToShareDataScreen}
          />
        )}
        {Platform.OS !== 'web' && (
          <DrawerItem
            closeDrawer={closeDrawer}
            componentName="CertificationOfCitizenshipScreen"
            // complete={progress.ConsentToShareDataScreen}
          />
        )}
        <DrawerItem
          closeDrawer={closeDrawer}
          componentName="ApplicationChecklistScreen"
          // complete={progress.ApplicationChecklistScreen}
          activeItemKey={activeItemKey}
        />
        {/* TEMP
        {kiosk && (
          <DrawerItem
            closeDrawer={closeDrawer}
            componentName="SyncStatusScreen"
            activeItemKey={activeItemKey}
          />
        )} */}

        <PrivacyPolicyLink />

        <Text
          style={{
            color: '#aaa',
            textAlign: 'right',
            padding: 10
          }}
        >
          {env}
          {dash}
          {build}
        </Text>
      </ScrollView>
    );
  }
}

const mapStateToProps = ({
  persist: { application, documents, isKiosk },
  app: { initilized },
  router: { location },
  i18n: { locale }
}) => ({
  initilized,
  application,
  isKiosk,
  location,
  documents,
  locale
});

DrawerContents = connect(
  mapStateToProps,
  { push }
)(DrawerContents);

export default DrawerContents;
