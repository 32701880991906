import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import Immutable from 'seamless-immutable';
import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FullButton from '../Components/FullButton';

import {
  RENTAL_PROGRAM_CODE,
  YES_OPTION,
  TRUE_OPTION
} from '../Config/ApplicationConfig';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

class DoIQualifyScreen extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    application: PropTypes.shape().isRequired
  };

  renderCheckboxes(screeningQuestions) {
    // Filter out objects without SortOrder
    const newArray = Immutable.asMutable(
      screeningQuestions.filter(ele => ele.SortOrder)
    );
    // Sort
    newArray.sort((a, b) =>
      a.SortOrder > b.SortOrder ? 1 : b.SortOrder > a.SortOrder ? -1 : 0
    );

    return newArray.map((ele, idx) => {
      return (
        <FormGroupCheckbox
          key={`FormGroupCheckbox_${idx}`}
          field={`requirement_${idx}`}
          label={ele.ScreeningQuestion}
        />
      );
    });
  }

  render() {
    const { handleSubmit, application, screeningQuestions } = this.props;

    const isRentalProgram =
      application.programCode.toString() === RENTAL_PROGRAM_CODE.toString();

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="DoIQualify.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="DoIQualify.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>{this.renderCheckboxes(screeningQuestions)}</Col>
            </Row>

            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={handleSubmit}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

DoIQualifyScreen = withFormik({
  // mapPropsToValues: props => {

  //   ({
  //   requirement_1: '',
  //   requirement_2: ''
  // })},
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { props }) => {
    const { history, screeningQuestions } = props;

    let checkedAll = true;
    // Filter out objects without SortOrder
    const newArray = Immutable.asMutable(
      screeningQuestions.filter(ele => ele.SortOrder)
    );
    // console.log(values, newArray);
    const unchecked = [];
    newArray.forEach((ele, idx) => {
      if (
        values[`requirement_${idx}`] &&
        values[`requirement_${idx}`] === TRUE_OPTION
      ) {
      } else {
        unchecked.push(idx);
        checkedAll = false;
      }
    });

    if (checkedAll) {
      // console.log('ProgramReferralScreen');
      history.push('/ProgramReferralScreen');
    } else {
      // console.log('YouDontQualifyScreen');
      history.push('/YouDontQualifyScreen?unchecked=' + unchecked.join(','));
    }
  }
})(DoIQualifyScreen);

const mapStateToProps = ({ persist: { application, screeningQuestions } }) => ({
  application,
  screeningQuestions
});

export default connect(mapStateToProps)(DoIQualifyScreen);
