import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
// import Icon from 'react-native-vector-icons/dist/Ionicons';
import { I18n } from 'react-redux-i18n';
import Icon from 'react-native-vector-icons/dist/Ionicons';
import { withRouter } from 'react-router';

import styles from './Styles/TopNavStyles';
import ExamplesRegistry from '../Services/ExamplesRegistry';

import { Colors, Metrics } from '../Themes/';

// Note that this file (App/Components/TopNav) needs to be
// imported in your app somewhere, otherwise your component won't be
// compiled and added to the examples dev screen.

// Ignore in coverage report
/* istanbul ignore next */
ExamplesRegistry.addComponentExample('Top Navigation', () => <TopNav />);

class TopNav extends Component {
  static propTypes = {
    title: PropTypes.string,
    onToggleDrawer: PropTypes.func.isRequired
  };

  static defaultProps = {
    title: ''
  };

  render() {
    const { title, onToggleDrawer } = this.props;
    // console.log(this.props);
    return (
      <View style={styles.topContainer}>
        <View style={styles.barContainer}>
          <TouchableOpacity
            style={styles.iconContainer}
            onPress={onToggleDrawer}
          >
            <Icon
              name="md-menu"
              size={Metrics.icons.medium}
              color={Colors.white}
            />
          </TouchableOpacity>
          <Text style={styles.titleLogoText}>{I18n.t('header.title')}</Text>
          <View style={styles.iconContainer}>
            <Icon
              name="md-menu"
              size={Metrics.icons.small}
              color={Colors.transparent}
            />
          </View>
        </View>
      </View>
    );
  }
}

export default withRouter(TopNav);
