import React, { Component } from 'react';

/**
 * Mirror react-native-permissions request function
 */

export const constants = {
  Aspect: { fill: 'fill' },
  TorchMode: { on: 'on', off: 'off' },
  CaptureTarget: { disk: 'disk' }
};
export const RNCamera = () => <div />;
export default () => <div />;
