import * as yup from 'yup';
import moment from 'moment';
import { REFERRAL_VOAD, REFERRAL_OTHER } from '../Config/ApplicationConfig';

const validationSchema = {
  programCode: yup.string().required('error.required'),

  referralCode: yup.string().required('error.required'),
  referralOther: yup.string().when('referralCode', {
    is: REFERRAL_OTHER,
    then: s => s.required('error.required')
  }),
  voadCode: yup.string().when('referralCode', {
    is: REFERRAL_VOAD,
    then: s => s.required('error.required')
  }),
  voadNotify: yup.string(),
  applicant: {
    firstName: yup.string().required('error.required')
  }
};

export default validationSchema;
