import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { Grid, Row, Col } from '../../Components/Grid';

import FormattedMessage from '../../Components/FormattedMessage';
import FormGroupRadio from '../../Components/FormGroupRadio';
import FullButton from '../../Components/FullButton';
import { isRequired } from '../../Lib/Utils';

import PersistActions from '../../Redux/PersistRedux';

// Styles
import styles from '../Styles/ScreenStyles';
import { Fonts, Metrics } from '../../Themes';

class SelectAlternateIncome extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.number,
    onComplete: PropTypes.func.isRequired
  };

  // onSubmit = values => {
  //   const { base, index, onComplete, setApplication, application } = this.props;

  //   switch (values.incomeType) {
  //     case '1':
  //       if (index >= 0) {
  //         setApplication(
  //           application.setIn([base, index, 'isTaxReturn4506TRequested'], 1)
  //         );
  //       } else {
  //         setApplication(
  //           application.setIn([base, 'isTaxReturn4506TRequested'], 1)
  //         );
  //       }

  //       onComplete('IRS4506T');
  //       break;
  //     case '2':
  //       if (index >= 0) {
  //         setApplication(
  //           application.setIn([base, index, 'isTaxReturn4506TRequested'], 0)
  //         );
  //       } else {
  //         setApplication(
  //           application.setIn([base, 'isTaxReturn4506TRequested'], 0)
  //         );
  //       }
  //       onComplete('OtherIncome');
  //       break;
  //     default:
  //     case '3':
  //       if (index >= 0) {
  //         setApplication(
  //           application.setIn([base, index, 'isTaxReturn4506TRequested'], 0)
  //         );
  //       } else {
  //         setApplication(
  //           application.setIn([base, 'isTaxReturn4506TRequested'], 0)
  //         );
  //       }
  //       onComplete('NoIncomeAffidavit');
  //       break;
  //   }
  // };
  render() {
    const { invalid, handleSubmit } = this.props;

    const options = {
      1: { code: 1, value: I18n.t('RetreiveTaxReturn.RetreiveForMe') },
      2: { code: 2, value: I18n.t('RetreiveTaxReturn.UploadOther') },
      3: { code: 3, value: I18n.t('RetreiveTaxReturn.NoIncome') }
    };

    return (
      <KeyboardAwareScrollView
        ref={scroll => {
          this._scroll = scroll;
        }}
        keyboardShouldPersistTaps="handled"
        style={styles.scrollViewContainer}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id="RetreiveTaxReturn.title"
                style={Fonts.style.title}
              />
              <FormattedMessage
                id="RetreiveTaxReturn.subtitle"
                style={Fonts.style.subtitle}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field="incomeType"
                labelId=""
                options={options}
                validate={isRequired}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                onPress={handleSubmit}
                disabled={invalid}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
      </KeyboardAwareScrollView>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

SelectAlternateIncome = withFormik({
  mapPropsToValues: props => {
    return { incomeType: '' };
    //   const { application } = props;
    //   let newApplication = JSON.parse(JSON.stringify(application));
    //   // console.log('loaded application', JSON.stringify(newApplication, null, 2));
    //   return newApplication;
  },

  // validationSchema,
  // Submission handler
  handleSubmit: (values, { props }) => {
    const { base, index, onComplete, setApplication, application } = props;

    console.log('handleSubmit', JSON.stringify(values, null, 2));
    switch (values.incomeType) {
      case '1':
        if (index >= 0) {
          setApplication(
            application.setIn([base, index, 'isTaxReturn4506TRequested'], 1)
          );
        } else {
          setApplication(
            application.setIn([base, 'isTaxReturn4506TRequested'], 1)
          );
        }

        onComplete('IRS4506T');
        break;
      case '2':
        if (index >= 0) {
          setApplication(
            application.setIn([base, index, 'isTaxReturn4506TRequested'], 0)
          );
        } else {
          setApplication(
            application.setIn([base, 'isTaxReturn4506TRequested'], 0)
          );
        }
        onComplete('OtherIncome');
        break;
      default:
      case '3':
        if (index >= 0) {
          setApplication(
            application.setIn([base, index, 'isTaxReturn4506TRequested'], 0)
          );
        } else {
          setApplication(
            application.setIn([base, 'isTaxReturn4506TRequested'], 0)
          );
        }
        onComplete('NoIncomeAffidavit');
        break;
    }
  }
})(SelectAlternateIncome);

const mapStateToProps = ({
  app: { initilized },
  persist: { application }
}) => ({
  initilized,
  application,
  initialValues: application
});

const mapDispatchToProps = dispatch => ({
  setApplication: application =>
    dispatch(PersistActions.PersistSetApplication(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectAlternateIncome);
