import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import styles from './Styles/FormStyles';
import ExamplesRegistry from '../Services/ExamplesRegistry';

// Note that this file (App/Components/SuccessMessage) needs to be
// imported in your app somewhere, otherwise your component won't be
// compiled and added to the examples dev screen.

// Ignore in coverage report
/* istanbul ignore next */
ExamplesRegistry.addComponentExample('Full Button', () => (
  <SuccessMessage text="Error Text" />
));

export default class SuccessMessage extends Component {
  static propTypes = {
    text: PropTypes.string,
    onDismiss: PropTypes.func
  };
  static defaultProps = {
    text: null,
    onDismiss: () => {}
  };

  render() {
    const { text } = this.props;

    return (
      text && (
        <View style={styles.successContainer}>
          <Text style={styles.successText}>{text}</Text>
        </View>
      )
    );
  }
}
