import React, { Component } from 'react';
import { Platform, Text, View, TouchableOpacity } from 'react-native';

import Drawer from 'react-motion-drawer';
import { Colors } from '../Themes';

export default class WebDrawer extends Component {
  state = {
    drawerOpen: false
  };

  open() {
    this.setState({ drawerOpen: true });
  }
  close() {
    this.setState({ drawerOpen: false });
  }

  render() {
    const { children, content } = this.props;
    const drawerWidth = 300;
    return (
      <View style={{ flex: 1 }}>
        <Drawer
          open={this.state.drawerOpen}
          width={300}
          onChange={open => this.setState({ drawerOpen: open })}
        >
          <View
            style={{
              padding: 16,
              backgroundColor: Colors.white,
              minHeight: '100%'
            }}
          >
            {content}
          </View>
        </Drawer>
        {children}
      </View>
    );

    return (
      <Drawer
        ref={ref => (this._drawer = ref)}
        type="overlay"
        tapToClose={true}
        acceptPan={true}
        openDrawerOffset={viewport => viewport.width - drawerWidth}
        closedDrawerOffset={-3}
        elevation={2}
        // content={<DrawerContents closeDrawer={this.closeDrawer} />}
        contents={<Text>HELLO</Text>}
        styles={{
          drawer: {
            shadowColor: '#000000',
            shadowOpacity: 0.8,
            shadowRadius: 3
          },
          main: { paddingLeft: 3 }
        }}
      />
    );
  }
}
