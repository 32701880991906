// @flow

import { createReducer, createActions } from 'reduxsauce';
import { Platform } from 'react-native';
import Immutable from 'seamless-immutable';
import DeviceInfo from 'react-native-device-info';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  AccountLogin: ['credentials'],
  AccountCreate: ['credentials'],
  AccountRequestOtp: ['userId'],
  AccountVerifyOtp: ['userId', 'otpId', 'otp'],
  AccountForgotPassword: ['email'],
  AccountChangePassword: ['password', 'token'],

  AccountSuccess: ['data'],
  AccountFailure: ['error'],
  AccountReset: null
});

export const AccountTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  data: null,
  error: null,
  fetching: false
});

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = (state: Object) =>
  state.merge({ data: null, error: null, fetching: true });

// we've successfully completed an API call.
export const success = (state: Object, { data }: Object) =>
  state.merge({ fetching: false, error: null, data });

// we've had a problem with our API call.
export const failure = (state: Object, { error }: Object) =>
  state.merge({ fetching: false, error: error || 'Unknown Error', data: null });

export const reset = state => INITIAL_STATE;
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ACCOUNT_LOGIN]: request,
  [Types.ACCOUNT_CREATE]: request,
  [Types.ACCOUNT_REQUEST_OTP]: request,
  [Types.ACCOUNT_VERIFY_OTP]: request,
  [Types.ACCOUNT_FORGOT_PASSWORD]: request,
  [Types.ACCOUNT_CHANGE_PASSWORD]: request,

  [Types.ACCOUNT_SUCCESS]: success,
  [Types.ACCOUNT_FAILURE]: failure,
  [Types.ACCOUNT_RESET]: reset
});

/* ------------- Selectors ------------- */

export const isKiosk = persist => persist.isKiosk;
// Check the BundleId for the text kiosk
// export const isKiosk = () =>
//   Platform.OS !== 'web' &&
//   DeviceInfo.getBundleId()
//     .toLowerCase()
//     .indexOf('kiosk') !== -1;

export const isTablet = () => Platform.OS === 'web' || DeviceInfo.isTablet();
