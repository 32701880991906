import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/dist/Ionicons';
import { I18n } from 'react-redux-i18n';

import { Grid, Row, Col } from '../Components/Grid';
import FormattedMessage from '../Components/FormattedMessage';
import ErrorMessage from '../Components/ErrorMessage';

import { getProgress } from '../Navigation/DrawerContents';

import { FloodWaterEnterHomeScreenProgress } from '../Containers/FloodWaterEnterHomeScreen';

import FullButton from '../Components/FullButton';

import ApplicationActions from '../Redux/ApplicationRedux';

import {
  RENTAL_PROGRAM_CODE,
  STATUS_SUBMITTED,
  STATUS_SUBMITTED_PENDING_DOCUSIGN
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Metrics } from '../Themes/';

const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;

const ChecklistItem = props => {
  const { screen, progress, history, title } = props;
  const shortScreen = screen.replace('Screen', '');
  const complete = progress[screen];
  const rowStyle = {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Metrics.baseMargin
  };
  const iconStyle = {
    color: complete ? 'green' : 'red',
    marginHorizontal: Metrics.baseMargin
  };
  const textStyle = {
    ...Fonts.style.subtitle,
    fontSize: Fonts.size.pt40,
    marginTop: 0,
    flex: 1
  };
  const checkmarkIcon = (
    <Icon name="md-checkmark-circle" size={30} style={iconStyle} />
  );
  const closeIcon = <Icon name="md-close-circle" size={30} style={iconStyle} />;

  return (
    <TouchableOpacity onPress={() => history.push(screen)} style={rowStyle}>
      {complete ? checkmarkIcon : closeIcon}
      <FormattedMessage
        id={title ? `menu.${title}` : `menu.${shortScreen}`}
        style={textStyle}
      />
    </TouchableOpacity>
  );
};
ChecklistItem.propTypes = {
  screen: PropTypes.string.isRequired,
  progress: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
  style: PropTypes.object
};

class ApplicationChecklistScreen extends Component {
  state = {
    errorText: ''
  };
  updatingApplication = false;
  creatingDocusign = false;

  componentWillReceiveProps(newProps) {
    const {
      history,
      fetching,
      errorText,
      application,
      updateApplication
    } = newProps;
    if (this.updatingApplication && !fetching) {
      this.updatingApplication = false;
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        history.push('/NextStepsScreen');
      }
    } else if (this.creatingDocusign && !fetching) {
      this.creatingDocusign = false;
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        // Finally.  Set the Status to Submitted
        this.updatingApplication = true;
        updateApplication(
          application.set('status', STATUS_SUBMITTED_PENDING_DOCUSIGN)
        );
      }
    }
  }

  handleSubmitForm = () => {
    const { application, createDocusign, updateApplication } = this.props;

    if (Platform.OS === 'web') {
      this.creatingDocusign = true;
      createDocusign(application);
    } else {
      // Finally.  Set the Status to Submitted
      this.updatingApplication = true;
      updateApplication(application.set('status', STATUS_SUBMITTED));
    }
  };

  render() {
    const {
      application,
      isKiosk,
      documents,
      initilized,
      history,
      fetching
    } = this.props;
    const { errorText } = this.state;

    if (!initilized) return null;

    const progress = getProgress(application, documents);

    const kiosk = isKiosk;
    let applicationComplete = false;
    console.log('progress', JSON.stringify(progress, null, 2));
    if (kiosk) {
      // Kiosk Only Requries Applicant Driver's Licence
      applicationComplete = progress.ApplicantIdentificationScreen;
    } else if (Platform.OS === 'web') {
      applicationComplete =
        progress.ApplicantIdentificationScreen &&
        progress.ApplicantInformationScreen &&
        progress.ApplicantIncomeScreen &&
        (!application[CO_APPLICANT_BASE] ||
          (progress.CoapplicantIdentificationScreen &&
            progress.CoapplicantInformationScreen &&
            progress.CoapplicantIncomeScreen)) &&
        progress.HouseholdMembersScreen &&
        // progress.TenantsScreen &&
        progress.DamagedAddressScreen &&
        progress.LienOnPropertyScreen &&
        progress.OwnHomeScreen &&
        progress.PrimaryResidenceScreen &&
        progress.FloodWaterEnterHomeScreen &&
        progress.InsuranceScreen &&
        progress.OtherFundsScreen;
    } else {
      applicationComplete =
        progress.ApplicantIdentificationScreen &&
        progress.ApplicantInformationScreen &&
        progress.ApplicantIncomeScreen &&
        (!application[CO_APPLICANT_BASE] ||
          (progress.CoapplicantIdentificationScreen &&
            progress.CoapplicantInformationScreen &&
            progress.CoapplicantIncomeScreen)) &&
        progress.HouseholdMembersScreen &&
        // progress.TenantsScreen &&
        progress.DamagedAddressScreen &&
        progress.LienOnPropertyScreen &&
        progress.OwnHomeScreen &&
        progress.PrimaryResidenceScreen &&
        progress.FloodWaterEnterHomeScreen &&
        progress.InsuranceScreen &&
        progress.OtherFundsScreen &&
        progress.PoaDesigneeScreen &&
        progress.ConsentToShareDataScreen;
    }
    console.log(
      'TCL: ApplicationChecklistScreen -> render -> applicationComplete',
      applicationComplete
    );

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.container}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="ApplicationChecklist.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="ApplicationChecklist.subtitle"
                  style={Fonts.style.subtitle}
                />
                <ErrorMessage errorText={errorText} />
                <FormattedMessage
                  id="menu.Applicant"
                  style={Fonts.style.subtitle}
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="ApplicantIdentificationScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="ApplicantInformationScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="ApplicantIncomeScreen"
                />
                <FormattedMessage
                  id="menu.Coapplicant"
                  style={Fonts.style.subtitle}
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="CoapplicantExistsScreen"
                />
                {application[CO_APPLICANT_BASE] && (
                  <View>
                    <ChecklistItem
                      progress={progress}
                      history={history}
                      screen="CoapplicantIdentificationScreen"
                    />
                    <ChecklistItem
                      progress={progress}
                      history={history}
                      screen="CoapplicantInformationScreen"
                    />
                    <ChecklistItem
                      progress={progress}
                      history={history}
                      screen="CoapplicantIncomeScreen"
                    />
                  </View>
                )}

                <FormattedMessage
                  id="menu.HouseholdMembers"
                  style={Fonts.style.subtitle}
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  title="HouseholdMembersComplete"
                  screen="HouseholdMembersScreen"
                />
                <FormattedMessage
                  id="menu.Tenants"
                  style={Fonts.style.subtitle}
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  title="TenantsComplete"
                  screen="TenantsScreen"
                />
                <FormattedMessage
                  id="menu.DamagedAddress"
                  style={Fonts.style.subtitle}
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  title="DamagedAddressInformation"
                  screen="DamagedAddressScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="LienOnPropertyScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="OwnHomeScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="PrimaryResidenceScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="FloodWaterEnterHomeScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="InsuranceScreen"
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  screen="OtherFundsScreen"
                />
                <FormattedMessage
                  id="menu.Communication"
                  style={Fonts.style.subtitle}
                />
                <ChecklistItem
                  progress={progress}
                  history={history}
                  title="Poa"
                  screen="PoaDesigneeScreen"
                />
                {application.communicationsDesignee && (
                  <ChecklistItem
                    progress={progress}
                    history={history}
                    screen="CommsDesigneeScreen"
                  />
                )}
                {Platform.OS !== 'web' && (
                  <ChecklistItem
                    progress={progress}
                    history={history}
                    screen="ConsentToShareDataScreen"
                  />
                )}
                {Platform.OS !== 'web' && (
                  <ChecklistItem
                    progress={progress}
                    history={history}
                    screen="CertificationOfCitizenshipScreen"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <FullButton
                  styles={{ flex: 1, minWidth: 350 }}
                  text={I18n.t('buttons.SubmitApplication')}
                  disabled={!applicationComplete || fetching}
                  onPress={this.handleSubmitForm}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  document: { documents },
  application: { fetching, error },
  persist: { application, isKiosk }
}) => ({
  application,
  isKiosk,
  documents,
  fetching,
  errorText: error,
  initilized
});
const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  createDocusign: application =>
    dispatch(ApplicationActions.ApplicationCreateDocusign(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationChecklistScreen);
