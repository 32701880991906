import React, { Component } from 'react';
import * as R from 'ramda';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
// import Icon from 'react-native-vector-icons/dist/Ionicons';
import { Grid, Row, Col } from '../Components/Grid';

import { isRequired } from '../Lib/Utils';
import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentURL } from '../Redux/DocumentRedux';

import TopNav from '../Components/TopNav';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';
import SelectUploadDocumentType from '../Components/SelectUploadDocumentType';
import { getPersonId } from '../Lib/Utils';
// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;

const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfPropertyDamage.code;
const DOCUMENT_KEYWORD_VALUE = '';

class DamagedAddressImagesScreen extends Component {
  formInitilized = false;
  loadedDocuments = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  onComplete = () => {
    this.props.history.push('LienOnPropertyScreen');
  };
  onCancel = () => {
    this.props.history.push('LienOnPropertyScreen');
  };

  render() {
    const { application, initilized } = this.props;

    if (!initilized) return null;

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="DamagedAddressImages.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id={
                    Platform.OS === 'web'
                      ? 'DamagedAddressImages.subtitleWeb'
                      : 'DamagedAddressImages.subtitle'
                  }
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <SelectUploadDocumentType
              documentType={DOCUMENT_TYPE}
              scrollToTop={() => this._scroll.scrollTo({ x: 0, y: 0 })}
              base={APPLICANT_BASE}
              onComplete={this.onComplete}
            />

            {/* <Row>
              <Col sm={6}>
                <FullButton
                  text={I18n.t('form.continueNextStep')}
                  onPress={this.onComplete}
                  // disabled={photos.length === 0 || fetching}
                />
              </Col>
            </Row> */}
            <Row>
              <Col sm={12}>
                <FullButton
                  type="link"
                  text={I18n.t('DamagedAddressImages.SkipThisStep')}
                  onPress={this.onCancel}
                />
              </Col>
            </Row>
          </Grid>
          <View style={{ marginTop: Metrics.doubleBaseMargin }} />
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, account, tokenId, token, application },
  document: { documents, fetching, error }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  saveApplication: application =>
    dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DamagedAddressImagesScreen);
