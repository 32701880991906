import React, { Component } from 'react';
import { ScrollView, View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/dist/Ionicons';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../Components/Grid';

import { isRequired } from '../Lib/Utils';

import FormattedMessage from '../Components/FormattedMessage';

import FullButton from '../Components/FullButton';

import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Colors, Metrics } from '../Themes/';

class SatisfactionSurveyScreen extends Component {
  renderInput = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {
    const stars = [1, 2, 3, 4, 5].map(ele => (
      <View
        key={`survey_star_${ele}`}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <TouchableOpacity onPress={() => setFieldValue(name, ele)}>
          <Icon
            name={ele <= value ? 'md-star' : 'md-star-outline'}
            size={40}
            style={{
              color: ele <= value ? Colors.red : Colors.grey,
              padding: Metrics.baseMargin
            }}
          />
        </TouchableOpacity>
      </View>
    ));
    return <View style={{ flexDirection: 'row' }}>{stars}</View>;
  };

  state = {
    rating: 0
  };

  savedApplication = null;

  isSubmitted = false;
  isSubmitting = false;

  componentWillReceiveProps(nextProps) {
    const {
      token,
      isSubmitting,
      setSubmitting,
      data,
      errorText,
      isKiosk,
      fetching,
      initilized,
      history,
      status,
      setStatus
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setSubmitting(false);
      setStatus('');
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        // setSubmitting(false);
        console.log('onComplete', data);
        const kiosk = isKiosk;
        if (!kiosk && token) {
          this.props.resetPersist();
        }
        history.push('/');
        // onComplete(data);
      }
    }
  }

  // componentWillReceiveProps(newProps) {
  //   const {
  //     initilized, application, fetching, error,
  //   } = newProps;

  //   if (initilized && application && this.isSubmitted && !this.isSubmitting) {
  //     this.isSubmitted = true;
  //     this.isSubmitting = true;
  //     this.props.submitApplication(application.id);
  //   }

  //   if (this.isSubmitting && fetching === false) {
  //     this.isSubmitting = false;
  //     console.log('checking login', application, error);
  //   }
  // }

  handleClickStar(rating) {
    this.setState({ rating });
  }

  render() {
    const {
      fetching,
      isValid,
      isSubmitting,
      initilized,
      values,
      history,
      handleSubmit
    } = this.props;

    if (!initilized) return null;

    console.log('values', values);

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.container}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="SatisfactionSurvey.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="SatisfactionSurvey.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Field
                  name="surveyRating"
                  component={this.renderInput}
                  validate={isRequired}
                />
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <FullButton
                  styles={{ flex: 1, minWidth: 350 }}
                  text={I18n.t('buttons.CompleteAndLogout')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />
              </Col>
            </Row>
          </Grid>
        </ScrollView>
      </View>
    );
  }
}

SatisfactionSurveyScreen = withFormik({
  mapPropsToValues: props => {
    const { base, index, application, token, account } = props;
    return application;
  },

  isInitialValid: props => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props, dirty }) => {
    props.updateApplication(values);
    setStatus('updatingApplication');
  }
})(SatisfactionSurveyScreen);

const mapStateToProps = ({
  persist: { application, token, isKiosk },
  app: { initilized },
  application: { fetching, error },
  account: { screen }
}) => ({
  application,
  token,
  fetching,
  isKiosk,
  error,
  initilized,
  screen
});
const mapDispatchToProps = dispatch => ({
  setApplication: application =>
    dispatch(PersistActions.PersistSetApplication(application)),
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  resetPersist: () => dispatch(PersistActions.PersistReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SatisfactionSurveyScreen);
