import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import { withFormik, Field } from 'formik';
import { View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';

import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
import FullButton from '../Components/FullButton';

import {
  isRequired,
  isPhoneNumber,
  isEmail,
  isDay,
  isMonth,
  isPastYear,
  phoneFormat,
  isRequiredEmail,
  isRequiredPhoneNumber,
  isRequiredPastYear
} from '../Lib/Utils';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { STATUS_SUBMITTED } from '../Config/ApplicationConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;

class NotifyMeOfOtherProgramsScreen extends Component {
  state = {
    errorText: null,
    complete: false
  };

  componentDidMount() {
    const { token, history } = this.props;
    if (!token) {
      history.push('/NotifyMeOfOtherProgramsScreen/CreateAccountScreen');
    }
  }
  componentWillReceiveProps(nextProps) {
    const {
      fetching,
      errorText,
      application,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history,
      setApplication
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        setApplication(null);
        this.setState({ errorText: '', complete: true });
        history.push('/');
      }
    }
  }
  // onSubmit = (values) => {
  //   const { syncApplication } = this.props;

  //   values.id = '-1';
  //   values.notifyMeOfOtherPrograms = 1;

  //   console.log(JSON.stringify(values, null, 2));
  //   this.updatingApplication = true;
  //   syncApplication(values);
  // };

  render() {
    const { initilized, handleSubmit, isValid, fetching, history } = this.props;

    const { errorText, complete } = this.state;

    if (!initilized) return null;

    const disabled = false;

    const baseString = APPLICANT_BASE;

    if (complete) {
      return (
        <View style={styles.mainContainer}>
          <KeyboardAwareScrollView
            ref={scroll => {
              this._scroll = scroll;
            }}
            keyboardShouldPersistTaps="handled"
            style={styles.scrollViewContainer}
          >
            <Grid>
              <Row>
                <Col>
                  <FormattedMessage
                    id="NotifyMeOfOtherProgramsComplete.title"
                    style={Fonts.style.title}
                  />
                  <FormattedMessage
                    id="NotifyMeOfOtherProgramsComplete.subtitle"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <FullButton
                    text={I18n.t('buttons.GoHome')}
                    onPress={() => history.push('/')}
                  />

                  <View style={{ marginTop: Metrics.doubleBaseMargin }} />
                </Col>
              </Row>
            </Grid>
          </KeyboardAwareScrollView>
        </View>
      );
    }

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="NotifyMeOfOtherPrograms.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="NotifyMeOfOtherPrograms.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <TouchableOpacity
                  style={{
                    marginTop: Metrics.doubleBaseMargin,
                    alignItems: 'center'
                  }}
                  onPress={() => history.push('/')}
                >
                  <FormattedMessage
                    id="buttons.NoThanksCancelApplication"
                    style={[
                      Fonts.style.subtitle,
                      {
                        marginTop: 0,
                        textDecorationLine: 'underline'
                      }
                    ]}
                  />
                </TouchableOpacity>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                {errorView}
                <FormGroupText
                  ref="firstName"
                  field={`${baseString}.firstName`}
                  labelId="ContactInfo.firstname"
                  autoCapitalize="words"
                  placeholderId="ContactInfo.firstname_placeholder"
                  returnKeyType="next"
                  validate={isRequired}
                  onSubmitEditing={e => {
                    this.refs.middleName.focus();
                  }}
                  required
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref="middleName"
                  field={`${baseString}.middleName`}
                  labelId="ContactInfo.middlename"
                  autoCapitalize="words"
                  placeholderId="ContactInfo.middlename_placeholder"
                  returnKeyType="next"
                  onSubmitEditing={() => {
                    this.refs.lastName.focus();
                  }}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref="lastName"
                  field={`${baseString}.lastName`}
                  labelId="ContactInfo.lastname"
                  autoCapitalize="words"
                  placeholderId="ContactInfo.lastname_placeholder"
                  returnKeyType="next"
                  onSubmitEditing={() => this.refs.DateOfBirthMonth.focus()}
                  required
                  validate={isRequired}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="ContactInfo.dateofbirth"
                  defaultMessage="Please enter your date of birth"
                  style={Fonts.style.sectionHeader}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={4}>
                <FormGroupText
                  ref="DateOfBirthMonth"
                  field="DateOfBirthMonth"
                  labelId="ContactInfo.dateofbirthmonth"
                  placeholderId="ContactInfo.dateofbirthmonth_placeholder"
                  keyboardType="numeric"
                  returnKeyType="next"
                  maxLength={2}
                  onSubmitEditing={() => this.refs.DateOfBirthDay.focus()}
                  required
                  validate={isMonth}
                  disabled={disabled}
                />
              </Col>
              <Col xs={4} sm={4}>
                <FormGroupText
                  ref="DateOfBirthDay"
                  field="DateOfBirthDay"
                  labelId="ContactInfo.dateofbirthday"
                  placeholderId="ContactInfo.dateofbirthday_placeholder"
                  maxLength={2}
                  keyboardType="numeric"
                  returnKeyType="next"
                  onSubmitEditing={() => this.refs.DateOfBirthYear.focus()}
                  required
                  validate={isDay}
                  disabled={disabled}
                />
              </Col>
              <Col xs={4} sm={4}>
                <FormGroupText
                  ref="DateOfBirthYear"
                  field="DateOfBirthYear"
                  labelId="ContactInfo.dateofbirthyear"
                  placeholderId="ContactInfo.dateofbirthyear_placeholder"
                  maxLength={4}
                  keyboardType="numeric"
                  returnKeyType="next"
                  onSubmitEditing={() => this.refs.SSN.focus()}
                  required
                  validate={isRequiredPastYear}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FormGroupText
                  ref="PrimaryPhone"
                  field={`${CONTACT_BASE}.primaryPhone`}
                  labelId="ContactInfo.PrimaryPhone"
                  placeholderId="ContactInfo.PrimaryPhone_placeholder"
                  maxLength={12}
                  keyboardType="phone-pad"
                  format={phoneFormat}
                  required
                  validate={isRequiredPhoneNumber}
                  disabled={disabled}
                />
              </Col>
              <Col sm={4}>
                <FormGroupCheckbox
                  labelId="ContactInfo.PrimaryPhoneIsMobile"
                  field={`${CONTACT_BASE}.primaryPhoneIsMobile`}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FormGroupText
                  ref="SecondaryPhone"
                  field={`${CONTACT_BASE}.secondaryPhone`}
                  labelId="ContactInfo.SecondaryPhone"
                  placeholderId="ContactInfo.SecondaryPhone_placeholder"
                  keyboardType="phone-pad"
                  maxLength={12}
                  validate={isPhoneNumber}
                  format={phoneFormat}
                  disabled={disabled}
                />
              </Col>
              <Col sm={4}>
                <FormGroupCheckbox
                  labelId="ContactInfo.SecondaryPhoneIsMobile"
                  field={`${CONTACT_BASE}.secondaryPhoneIsMobile`}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroupText
                  ref="Email"
                  field={`${CONTACT_BASE}.email`}
                  labelId="ContactInfo.Email"
                  placeholderId="ContactInfo.Email_placeholder"
                  autoCapitalize="none"
                  keyboardType="email-address"
                  forceTouched
                  required
                  validate={isRequiredEmail}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FullButton
                  text={I18n.t('buttons.SaveAndNotifyMe')}
                  onPress={handleSubmit}
                  disabled={!isValid || fetching}
                />

                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

NotifyMeOfOtherProgramsScreen = withFormik({
  mapPropsToValues: props => {
    const { index, application, token, account, isKiosk } = props;

    let newApplication = JSON.parse(JSON.stringify(application));

    if (!newApplication[CONTACT_BASE]) {
      newApplication[CONTACT_BASE] = {};
    }

    // Set Default EMail if not Set
    if (
      !isKiosk &&
      token &&
      account.emailAddress &&
      (!newApplication[CONTACT_BASE] || !newApplication[CONTACT_BASE].email)
    ) {
      newApplication[CONTACT_BASE].email = account.emailAddress;
    }
    console.log('TCL: newApplication', newApplication);

    return newApplication;
  },

  // isInitialValid: props => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const { base, index, history, application, updateApplication } = props;
    // console.log('handleSubmit', JSON.stringify(values, null, 2));

    // let newValues = Object.assign({}, application);

    if (!values.id) values.id = '-1';
    values.notifyMeOfOtherPrograms = 1;
    values.status = STATUS_SUBMITTED;

    console.log(JSON.stringify(values, null, 2));
    // this.updatingApplication = true;
    updateApplication(values);
    setStatus('updatingApplication');
  }
})(NotifyMeOfOtherProgramsScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { account, options, application, token },
  form
}) => ({
  account,
  options,
  initilized,
  fetching,
  errorText: error,
  data,
  application,
  token
});

const mapDispatchToProps = dispatch => ({
  setApplication: application =>
    dispatch(PersistActions.PersistSetApplication(application)),

  syncApplication: application =>
    dispatch(ApplicationActions.ApplicationSync(application)),

  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotifyMeOfOtherProgramsScreen);
