/**
 * Mirror react-native-fs functions
 */
export const exists = () => {
  console.log('RNFS exists', false);
  return false;
};

export const unlink = () => {
  console.log('RNFS unlink', false);
  return false;
};

export const DocumentDirectoryPath = 'DocumentDirectoryPath';

export default () => {};
