export default {
  proofOfIdentification: {
    code: '5010',
    keywords: {
      DriversLicense: '5001'
    }
  },
  primaryResidence: {
    code: '5040',
    keywords: {
      DriversLicense: '5045'
    }
  },
  proofOfInsurance: {
    code: '5020'
  },
  proofOfOwnership: {
    code: '5030',
    traditionalDocumentsCodes: ['6200', '6201', '6202'],
    nonTraditionalDocumentsCodes: [
      '6203',
      '6204',
      '6205',
      '6206',
      '6207',
      '6208',
      '6209',
      '6210',
      '6211'
    ]
  },
  proofOfCitizenship: {
    code: '5015'
  },
  // proofOfLandOwnership: {
  //   code: '4009'
  // },
  // proofOfMobileHomeOwnership: {
  //   code: '4008'
  // },
  proofOfPrimaryResidence: {
    code: '5040',
    keywords: {
      DriversLicense: '5045'
    }
  },
  proofOfPropertyDamage: {
    code: '5050',
    keywords: {
      proofOfInsurancePayout: '5065'
    }
  },
  proofOfIncome: {
    code: '5060'
  },
  stateTaxReturn: {
    code: '5070'
  },
  federalTaxReturn: {
    code: '5080'
  },
  signatureNoIncome: {
    code: '5210'
  },
  signature4506T: {
    code: '5230'
  },
  signatureLawfulPresence: {
    code: '5200'
  },
  signatureSubrogationAgreement: {
    code: '5250'
  },
  signatureNoInsurance: {
    code: '5220'
  },
  communicationDesigneeAuthorization: {
    code: '5240'
  },
  powerOfAttorney: {
    code: '5100'
  },
  lienDocument: {
    code: '5090'
  },
  docusignNoIncome: {
    code: '5310'
  },
  docusign4506T: {
    code: '5330'
  },
  docusignLawfulPresence: {
    code: '5300'
  },
  docusignSubrogationAgreement: {
    code: '5350'
  },
  docusignNoInsurance: {
    code: '5320'
  },
  docusignCommunicationDesignee: {
    code: '5340'
  }
};
