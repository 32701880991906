import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScrollView, View, TouchableOpacity, Platform } from 'react-native';
import Icon from 'react-native-vector-icons/dist/Ionicons';
import { I18n } from 'react-redux-i18n';

import { isKiosk } from '../Redux/AccountRedux';

import { Grid, Row, Col } from '../Components/Grid';

import FormattedMessage from '../Components/FormattedMessage';
import FullButton from '../Components/FullButton';
import AppActions from '../Redux/AppRedux';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts } from '../Themes/';

class NextStepsScreen extends Component {
  savedApplication = null;

  isSubmitted = false;
  isSubmitting = false;

  // componentWillReceiveProps(newProps) {
  //   const { initilized, application, fetching, error } = newProps;

  //   if (initilized && application && this.isSubmitted && !this.isSubmitting) {
  //     this.isSubmitted = true;
  //     this.isSubmitting = true;
  //     this.props.submitApplication(application.id);
  //   }

  //   if (this.isSubmitting && fetching === false) {
  //     this.isSubmitting = false;
  //     console.log('checking login', application, error);
  //   }
  // }

  render() {
    const { application, initilized, history } = this.props;

    if (!initilized) return null;
    // console.log(JSON.stringify(application, null, 2));
    return (
      <View style={styles.mainContainer}>
        <ScrollView style={styles.container}>
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="NextSteps.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="NextSteps.subtitle"
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id={
                    Platform.OS === 'web'
                      ? 'NextSteps.nextStepsWeb'
                      : 'NextSteps.nextSteps'
                  }
                  style={Fonts.style.subtitle}
                />
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <FullButton
                  styles={{ flex: 1, minWidth: 350 }}
                  text={I18n.t('form.continueNextStep')}
                  onPress={() => history.push('/SatisfactionSurveyScreen')}
                />
              </Col>
            </Row>
          </Grid>
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({
  persist: { application },
  app: { initilized },
  application: { fetching, error },
  nav,
  account: { screen }
}) => ({
  application,
  fetching,
  error,
  initilized,
  screen,
  nav
});
const mapDispatchToProps = dispatch => ({
  submitApplication: id => dispatch(AppActions.AppApplicationSync(id, true))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NextStepsScreen);
