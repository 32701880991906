import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Text } from 'react-native';
import baseStyles from './Styles/FullButtonStyles';
import ExamplesRegistry from '../Services/ExamplesRegistry';

// Note that this file (App/Components/FullButton) needs to be
// imported in your app somewhere, otherwise your component won't be
// compiled and added to the examples dev screen.

// Ignore in coverage report
/* istanbul ignore next */
ExamplesRegistry.addComponentExample('Full Button', () => (
  <FullButton
    text="Hey there"
    onPress={() => window.alert('Full Button Pressed!')}
  />
));

export default class FullButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.string, // full, small or link
    text: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    onPress: PropTypes.func,
    styles: PropTypes.object
  };

  render() {
    const { disabled, styles, type } = this.props;

    const buttonType = type || 'full';

    const button = !disabled
      ? baseStyles[`${buttonType}Button`]
      : baseStyles[`${buttonType}ButtonDisabled`];
    const buttonText = !disabled
      ? baseStyles[`${buttonType}ButtonText`]
      : baseStyles[`${buttonType}ButtonTextDisabled`];

    return (
      <TouchableOpacity
        style={[button, styles]}
        onPress={!disabled ? this.props.onPress : undefined}
      >
        <Text style={buttonText}>{this.props.text}</Text>
      </TouchableOpacity>
    );
  }
}
