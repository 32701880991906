import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import UploadTaxReturn from './Income/UploadTaxReturn';
import SelectAlternateIncome from './Income/SelectAlternateIncome';
import IRS4506T from './Income/IRS4506T';
import IRS4506TSignature from './Income/IRS4506TSignature';
import OtherIncome from './Income/OtherIncome';
import NoIncomeAffidavit from './Income/NoIncomeAffidavit';

// Styles
import styles from './Styles/ScreenStyles';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import { NO_OPTION } from '../Config/ApplicationConfig';
import { getBase } from '../Lib/Utils';

const BASE = DATA_STRUCTURE.household_members;

class HouseholdMemberTaxReturnScreen extends Component {
  state = {
    currentScreen: 'UploadTaxReturn' // 'UploadTaxReturn',
  };

  componentDidMount() {
    const {
      application,
      match: {
        params: { index }
      }
    } = this.props;
    const appBase = getBase(application, BASE, index);
    if (appBase.doYouReceiveIncomeCode === NO_OPTION) {
      this.setState({ currentScreen: 'NoIncomeAffidavit' });
    }
  }

  onComplete = () => {
    const { history } = this.props;
    history.push('/HouseholdMembersScreen');
  };
  onCancel = () => {
    this.setState({ currentScreen: 'SelectAlternateIncome' });
  };
  onSkip = () => {
    const { history } = this.props;
    history.push('/HouseholdMembersScreen');
  };

  handleGoToScreen = currentScreen => {
    console.log('setting currentScreen', currentScreen);
    this.setState({ currentScreen });
  };

  render() {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;
    const { currentScreen } = this.state;

    if (currentScreen === 'IRS4506T') {
      return (
        <View style={styles.flex}>
          <IRS4506T
            base={BASE}
            index={index}
            onComplete={this.handleGoToScreen}
            onSkip={this.onComplete}
          />
        </View>
      );
    }
    if (currentScreen === 'IRS4506TSignature') {
      return (
        <View style={styles.flex}>
          <IRS4506TSignature
            base={BASE}
            index={index}
            onComplete={this.onComplete}
          />
        </View>
      );
    }
    if (currentScreen === 'OtherIncome') {
      return (
        <View style={styles.flex}>
          <OtherIncome base={BASE} index={index} onComplete={this.onComplete} />
        </View>
      );
    }
    if (currentScreen === 'NoIncomeAffidavit') {
      return (
        <View style={styles.flex}>
          <NoIncomeAffidavit
            base={BASE}
            index={index}
            onComplete={this.onComplete}
          />
        </View>
      );
    }

    if (currentScreen === 'SelectAlternateIncome') {
      return (
        <View style={styles.flex}>
          <SelectAlternateIncome
            base={BASE}
            index={index}
            onComplete={this.handleGoToScreen}
          />
        </View>
      );
    }

    // if (currentScreen === 'UploadTaxReturn') {
    return (
      <View style={styles.mainContainer}>
        <UploadTaxReturn
          base={BASE}
          index={index}
          onComplete={this.handleGoToScreen}
          onCancel={this.onCancel}
          onSkip={this.onSkip}
        />
      </View>
    );
    // }
  }
}

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId }
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application
});

export default connect(mapStateToProps)(HouseholdMemberTaxReturnScreen);
