import React, { Component } from 'react';
import { Image, TouchableOpacity, View, Text, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';

import * as R from 'ramda';
import { connect } from 'react-redux';
// import SignatureCapture from 'react-native-signature-capture';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import { Grid, Row, Col } from '../Components/Grid';

import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentURL } from '../Redux/DocumentRedux';

import FormGroupRadio from '../Components/FormGroupRadio';
import CaptureSignature from '../Components/CaptureSignature';
import FormattedMessage from '../Components/FormattedMessage';
import ErrorMessage from '../Components/ErrorMessage';
import FullButton from '../Components/FullButton';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  zipcodeFormat,
  getPersonId,
  getBase,
  getBaseString
} from '../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const DOCUMENT_TYPE = DOCUMENT_TYPES.signatureLawfulPresence.code;
const CONSENT_TO_SHARE_DATA_KEYWORD_VALUE = '';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;

class CertificationOfCitizenshipScreen extends Component {
  state = {
    errorText: null,
    signed: false
  };

  loadedDocuments = false;
  loadingDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  componentWillReceiveProps(nextProps) {
    const {
      isSubmitting,
      setSubmitting,
      data,
      errorText,
      fetching,
      initilized,
      history,
      status,
      setStatus
    } = nextProps;

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setSubmitting(false);
      setStatus('');
      if (errorText) {
        this.setState({ errorText });
        // Scroll to top
        this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        history.push('/ApplicationChecklistScreen');
      }
    }
  }

  renderBullets = () => {
    return [1, 2, 3, 4, 5, 6].map(i => (
      <View
        style={styles.liWrapper}
        key={`CertificationOfCitizenship_bullet_${i}`}
      >
        <Text style={styles.liBullet}>{'\u2022'}</Text>
        <Text style={styles.liText}>
          {I18n.t(`CertificationOfCitizenship.radio${i}`)}
        </Text>
      </View>
    ));
  };

  render() {
    const {
      userId,
      tokenId,
      token,
      initilized,
      fetching,
      errorText,
      documents,
      application,
      values,
      history,
      handleSubmit
    } = this.props;

    const { signed } = this.state;

    if (!initilized) return null;
    const alteredOptions = {
      '1': { code: '1', value: I18n.t('CertificationOfCitizenship.radio1') },
      '2': { code: '2', value: I18n.t('CertificationOfCitizenship.radio2') },
      '3': { code: '3', value: I18n.t('CertificationOfCitizenship.radio3') },
      '4': { code: '4', value: I18n.t('CertificationOfCitizenship.radio4') },
      '5': { code: '5', value: I18n.t('CertificationOfCitizenship.radio5') },
      '6': { code: '6', value: I18n.t('CertificationOfCitizenship.radio6') }
    };
    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col sm={12}>
                <FormattedMessage
                  id="CertificationOfCitizenship.title"
                  style={Fonts.style.title}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <Text
                  style={[
                    Fonts.style.sectionHeader,
                    styles.bold,
                    styles.underline,
                    styles.center,
                    { fontWeight: Fonts.weight.bold }
                  ]}
                >
                  {I18n.t('CertificationOfCitizenship.subtitle')}
                </Text>
                <Text style={styles.p}>
                  {I18n.t('CertificationOfCitizenship.section1')}
                </Text>
                <Text style={styles.p}>
                  {I18n.t('CertificationOfCitizenship.radioPrefix')}
                  <Text style={styles.underline}>{`${R.pathOr(
                    '',
                    [APPLICANT_BASE, 'firstName'],
                    values
                  )} ${R.pathOr(
                    '',
                    [APPLICANT_BASE, 'lastName'],
                    values
                  )}`}</Text>
                  {I18n.t('CertificationOfCitizenship.radioSuffix')}
                </Text>
                <View style={{ marginTop: Metrics.baseMargin }} />
                {this.renderBullets()}
                {/* <FormGroupRadio
                  // TODO: Update to Real Code
                  field="isLawfullyPresentInUSCode"
                  labelId=""
                  // TODO: Update to real picker code
                  // optionsKey="yes_no"
                  options={alteredOptions}
                  // required
                  // validate={isRequired}
                  // disabled={fetching}
                /> */}
                <Text style={styles.p}>
                  {I18n.t('CertificationOfCitizenship.section2')}
                </Text>
                <Text style={styles.p}>
                  {I18n.t('CertificationOfCitizenship.section3')}
                </Text>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <CaptureSignature
                  base={APPLICANT_BASE}
                  documentType={DOCUMENT_TYPE}
                  documentKeywordValue={CONSENT_TO_SHARE_DATA_KEYWORD_VALUE}
                  labelId="form.continueNextStep"
                  onComplete={handleSubmit}
                />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

CertificationOfCitizenshipScreen = withFormik({
  mapPropsToValues: props => {
    const { base, index, application, token, account } = props;
    return application;
  },
  isInitialValid: props => true,
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props, dirty }) => {
    props.updateApplication(values);
    setStatus('updatingApplication');
  }
})(CertificationOfCitizenshipScreen);

const mapStateToProps = ({
  options: { options },
  app: { initilized },
  account: { account, tokenId, token },
  persist: { application },
  document: { documents, error, fetching }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  documents,
  error,
  fetching,
  options,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationOfCitizenshipScreen);
