import React, { Component } from 'react';
import { ScrollView, View, Text } from 'react-native';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import { Grid, Row, Col } from '../Components/Grid';
import FullButton from '../Components/FullButton';
import FormattedMessage from '../Components/FormattedMessage';

import ApplicationActions from '../Redux/ApplicationRedux';

// Styles
import styles from './Styles/ScreenStyles';
import { Fonts, Metrics } from '../Themes/';
import { PROGRAM_CODE } from '../Config/ApplicationConfig';
import ErrorMessage from '../Components/ErrorMessage';

const BASE = 'GetStartedDocList';

class GetStartedDocListScreen extends Component {
  state = {
    errorText: ''
  };
  creatingApplication = false;

  componentWillReceiveProps(nextProps) {
    const {
      history,
      fetching,
      errorText,
      status,
      isSubmitting,
      setStatus,
      setSubmitting
    } = nextProps;

    if (this.creatingApplication && !fetching) {
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('DoIQualifyScreen');
      }
    }
  }

  handleCreateApplication = () => {
    this.creatingApplication = true;
    this.props.createApplication(PROGRAM_CODE);
  };

  render() {
    const { history, fetching, locale } = this.props;
    const { errorText } = this.state;

    return (
      <View style={styles.mainContainer}>
        <ScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col
                sm={12}
                style={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <FormattedMessage
                  id={`${BASE}.title`}
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id={`${BASE}.subtitle`}
                  style={[
                    Fonts.style.subtitle,
                    {
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 10,
                      paddingBottom: 10
                    }
                  ]}
                />
                <ErrorMessage errorText={errorText} />

                <FormattedMessage
                  id={`${BASE}.subtitle2`}
                  style={[Fonts.style.subtitle, { padding: 20 }]}
                />
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                style={{
                  margin: Metrics.doubleBaseMargin,
                  paddingRight: Metrics.doubleBaseMargin * 3,
                  marginBottom: 0
                }}
              >
                <FormattedMessage
                  id={`${BASE}.proofOfId`}
                  style={[Fonts.style.subtitle, { fontSize: 18 }]}
                />

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_1`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_2`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_3`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_4`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_5`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_6`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_7`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfId_8`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                style={{
                  margin: Metrics.doubleBaseMargin,
                  paddingRight: Metrics.doubleBaseMargin * 3,
                  marginBottom: 0
                }}
              >
                <FormattedMessage
                  id={`${BASE}.proofOfResidence`}
                  style={[Fonts.style.subtitle, { fontSize: 18 }]}
                />

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_1`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_2`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_3`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_4`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_5`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_6`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_7`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_8`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_9`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_10`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_11`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfResidence_12`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                style={{
                  margin: Metrics.doubleBaseMargin,
                  paddingRight: Metrics.doubleBaseMargin * 3,
                  marginBottom: 0
                }}
              >
                <FormattedMessage
                  id={`${BASE}.proofOfOwnership`}
                  style={[Fonts.style.subtitle, { fontSize: 18 }]}
                />

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.traditionalProofOfOwnership`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.traditionalProofOfOwnership_1`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.traditionalProofOfOwnership_2`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.traditionalProofOfOwnership_3`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.traditionalProofOfOwnership_4`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_1`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_2`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_3`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_4`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_5`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_6`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>

                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.nontraditionalProofOfOwnership_7`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                  <View style={styles.doubleLiWrapperSpaced}>
                    <Text style={styles.liBullet}>{'\u2022'}</Text>
                    <FormattedMessage
                      id={`${BASE}.nontraditionalProofOfOwnership_8`}
                      style={[
                        styles.liText,
                        { fontSize: 18, fontWeight: Fonts.weight.bold }
                      ]}
                    />
                  </View>

                {/* <FormattedMessage
                  id={`${BASE}.nontraditionalDisclaimer`}
                  style={[Fonts.style.subtitle, { fontSize: 18 }]}
                /> */}
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                style={{
                  margin: Metrics.doubleBaseMargin,
                  paddingRight: Metrics.doubleBaseMargin * 3,
                  marginBottom: 0
                }}
              >
                <FormattedMessage
                  id={`${BASE}.incomeInformation`}
                  style={[Fonts.style.subtitle, { fontSize: 18 }]}
                />

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_1`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                {/* <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_2`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_3`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_4`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_5`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View> */}
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_6`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_7`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_8`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_9`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_10`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_11`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_12`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.doubleLiWrapperSpaced}>
                  <Text style={styles.liBullet}>{'\u2022'}</Text>
                  <FormattedMessage
                    id={`${BASE}.incomeInformation_13`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                style={{
                  margin: Metrics.doubleBaseMargin,
                  paddingRight: Metrics.doubleBaseMargin * 3,
                  marginBottom: 0
                }}
              >
                <FormattedMessage
                  id={`${BASE}.proofOfPropertyDamage`}
                  style={[Fonts.style.subtitle, { fontSize: 18 }]}
                />

                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfPropertyDamage_1`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfPropertyDamage_2`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfPropertyDamage_3`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfPropertyDamage_4`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
                <View style={styles.liWrapperSpaced}>
                  <Text style={styles.liBullet}>-</Text>
                  <FormattedMessage
                    id={`${BASE}.proofOfPropertyDamage_5`}
                    style={[
                      styles.liText,
                      { fontSize: 18, fontWeight: Fonts.weight.bold }
                    ]}
                  />
                </View>
              </Col>
            </Row>

            <Row>
              <Col
                sm={12}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              />
            </Row>
          </Grid>
          <View style={styles.scrollViewBottomPadding} />
        </ScrollView>
        <Grid>
          <Row>
            <Col
              sm={12}
              style={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FullButton
                styles={{
                  minWidth: 350,
                  margin: Metrics.baseMargin,
                  marginBottom: Metrics.baseMargin
                }}
                disabled={fetching}
                text={I18n.t('buttons.GetStarted')}
                onPress={this.handleCreateApplication}
              />
            </Col>
          </Row>
        </Grid>
      </View>
    );
  }
}

const mapStateToProps = ({
  app: { initilized },
  persist: { application },
  application: { fetching, error },
  i18n: { locale }
}) => ({
  initilized,
  fetching,
  errorText: error,
  application,
  locale
});

const mapDispatchToProps = dispatch => ({
  createApplication: programCode =>
    dispatch(ApplicationActions.ApplicationCreate(programCode))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetStartedDocListScreen);
