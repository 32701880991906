import Immutable from 'seamless-immutable';
import DeviceInfo from 'react-native-device-info';
import { Platform } from 'react-native';
import * as R from 'ramda';
import diff from 'deep-diff';
import moment from 'moment';

import { call, put, select } from 'redux-saga/effects';
import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';
import AccountActions from '../Redux/AccountRedux';
import { getOptions, getDocTypes, getScreeningQuestions } from './OptionsSagas';

import { push } from 'connected-react-router';
// import history from '../history';
// import { history } from '../Redux/CreateStore';
import { isKiosk } from '../Redux/AccountRedux';
import { isNumeric } from '../Lib/Utils';
import { hasDocument } from '../Lib/Utils';
import {
  ORGIN_CODE_KIOSK,
  ORGIN_CODE_MOBILE,
  ORGIN_CODE_WEB,
  NO_OPTION,
  TRUE_OPTION,
  STATUS_IN_PROGRESS,
  VENDOR_NAME
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE, {
  DELETE_STRUCTURE
} from '../Config/DataStructureConfig';

import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;

const LAND_OWNER_BASE = DATA_STRUCTURE.landOwner;
const POA_BASE = DATA_STRUCTURE.powerOfAttorney;
const COMMUNICATIONS_DESIGNEE_BASE = DATA_STRUCTURE.communicationsDesignee;

const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const TENANTS_BASE = DATA_STRUCTURE.tenants;
const LIEN_HOLDERS_BASE = DATA_STRUCTURE.lien_holders;
const INSURANCE_BASE = DATA_STRUCTURE.insurance;
const ADDITIONAL_FUNDS_BASE = DATA_STRUCTURE.additionalFunds;
const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;

export const getProgramCode = state => state.options.programCode;
export const getDocumentState = state => state.document;
export const getPersist = state => state.persist;
export const getLocale = state => state.i18n.locale;

export function* getApplications(api) {
  const { account, tokenId, token } = yield select(getPersist);

  const userId = account && account.userId;

  let results = '';
  try {
    results = yield call(api.getApplications, userId, tokenId, token);
  } catch (error) {
    if (R.path(['data', 'payload'], error)) {
      results = error;
    }
  }
  if (R.path(['data', 'payload', 0, 'applicationId'], results)) {
    yield put(ApplicationActions.ApplicationSuccess(results.data.payload));
  } else if (R.path(['data', 'payload'], results)) {
    yield put(ApplicationActions.ApplicationSuccess([]));
  } else if (R.path(['data', 'error', 0, 'message'], results)) {
    yield put(
      ApplicationActions.ApplicationFailure(results.data.error[0].message)
    );
  } else {
    yield put(ApplicationActions.ApplicationFailure('Unknown Error'));
  }
}

export function* getApplication(api, { applicationId, diffApplication }) {
  const { account, tokenId, token } = yield select(getPersist);
  const userId = account && account.userId;

  let results = '';
  try {
    console.log('api.getApplication', userId, tokenId, token, applicationId);
    results = yield call(
      api.getApplication,
      userId,
      tokenId,
      token,
      applicationId
    );
    // console.log(
    //   'TCL: function*getApplication -> results',
    //   typeof results.data,
    //   results.data
    // );
  } catch (error) {
    console.log('Error api.getApplication', error);
    if (R.path(['data', 'payload', 0, 'id'], error)) {
      results = error;
    }
  }

  if (R.path(['data', 'payload', 0, 'id'], results)) {
    console.log('api.getApplication success');
    // console.log('get application', JSON.stringify(results.data.payload[0], null, 2));
    const loadedApplication = results.data.payload[0];

    // Check to see if we need to reload docTypes and screeningQuestions
    const exisitngProgramCode = yield select(getProgramCode);
    if (exisitngProgramCode !== loadedApplication.programCode) {
      console.log('starting getOptions');
      yield call(getOptions, api);
      console.log('ending getOptions');

      console.log(
        'starting getScreeningQuestions',
        loadedApplication.programCode
      );
      yield call(getScreeningQuestions, api, {
        programCode: loadedApplication.programCode
      });
      console.log('ending getScreeningQuestions');

      console.log('starting getDocTypes', loadedApplication.programCode);
      yield call(getDocTypes, api, {
        programCode: loadedApplication.programCode
      });
      console.log('ending getDocTypes');
    }

    yield put(PersistActions.PersistSetApplication(loadedApplication));
    yield put(ApplicationActions.ApplicationSuccess(loadedApplication));

    if (diffApplication) {
      const diffResults = diff(loadedApplication, diffApplication);
      if (diffResults) {
        diffResults.forEach(ele => {
          let path = ele.path.join('/');
          switch (ele.kind) {
            case 'N':
              console.log(`Added: ${path} with ${ele.rhs}`);
              break;
            case 'D':
              console.log(`Deleted: ${path} was ${ele.lhs}`);
              break;
            case 'E':
              console.log(`Changed: ${path} from ${ele.lhs} to ${ele.rhs}`);
              break;
            case 'A':
              console.log(
                `Array change: ${path} index ${ele.index} ${JSON.stringify(
                  ele.item
                )}`
              );
              break;
            default:
              break;
          }
        });
      }
    }
  } else if (
    typeof results.data === 'string' &&
    results.data.includes(
      'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.'
    )
  ) {
    console.log(
      'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.'
    );
    // Force Logout
    yield put(PersistActions.PersistReset());
    // Go Home
    yield put(push('/'));
    // Send Error Message
    ApplicationActions.ApplicationFailure(
      'Successfully found Token record with this @UserId, @TokenId, and @Token, however, it has already expired.'
    );
  } else if (R.path(['data', 'error', 0, 'message'], results)) {
    console.log(
      'TCL: function*getApplication -> results.data.error[0].message',
      results.data.error[0].message
    );
    yield put(
      ApplicationActions.ApplicationFailure(results.data.error[0].message)
    );
  } else {
    yield put(ApplicationActions.ApplicationFailure('Unknown Error'));
  }
}

export function* createApplication(api, { programCode }) {
  const {
    account,
    tokenId,
    token,
    isKiosk,
    applications,
    allDocTypes,
    allScreeningQuestions
  } = yield select(getPersist);
  const userId = account && account.userId;
  const locale = yield select(getLocale) || 'en';

  // const newId = Object.keys(applications).length + 1 || 1;
  const newId = new Date().getTime();

  const newApplication = {};

  newApplication.id = isKiosk ? `OFFLINE_${newId}` : '-1';
  newApplication.programCode = programCode;
  newApplication.applicant = { id: '-1' };
  // newApplication.damagedAddress = { id: '-1' };

  newApplication.originCode =
    Platform.OS === 'web' ? ORGIN_CODE_WEB : ORGIN_CODE_MOBILE;

  newApplication.languageCode = locale === 'en' ? '1' : '2';
  newApplication.status = STATUS_IN_PROGRESS;

  console.log('createApplication', newApplication);

  if (isKiosk) {
    // Set Options By Program Code
    yield put(
      PersistActions.PersistSetDocTypes(allDocTypes[programCode][locale])
    );
    yield put(
      PersistActions.PersistSetScreeningQuestions(
        allScreeningQuestions[programCode][locale]
      )
    );
    yield put(PersistActions.PersistSetProgramCode(programCode));

    yield put(PersistActions.PersistSetApplication(newApplication));
    yield put(ApplicationActions.ApplicationSuccess(newApplication));
  } else {
    // Load Options by Program Code

    console.log('starting getOptions');
    yield call(getOptions, api);
    console.log('ending getOptions');

    console.log('starting getScreeningQuestions', programCode);
    yield call(getScreeningQuestions, api, { programCode });
    console.log('ending getScreeningQuestions');

    console.log('starting getDocTypes', programCode);
    yield call(getDocTypes, api, { programCode });
    console.log('ending getDocTypes');

    yield put(PersistActions.PersistSetApplication(newApplication));
    yield put(ApplicationActions.ApplicationSuccess(newApplication));

    // let results = '';
    // console.log(
    //   'api.createApplication',
    //   userId,
    //   tokenId,
    //   token,
    //   newApplication
    // );

    // try {
    //   results = yield call(
    //     api.createApplication,
    //     userId,
    //     tokenId,
    //     token,
    //     newApplication
    //   );
    // } catch (error) {
    //   if (R.path(['data', 'payload', 0, 'id'], error)) {
    //     results = error;
    //   }
    // }

    // if (R.path(['data', 'error', 0, 'message'], results)) {
    //   yield put(
    //     ApplicationActions.ApplicationFailure(results.data.error[0].message)
    //   );
    //   return;
    // } else if (!R.path(['data', 'payload', 0, 'applicationId'], results)) {
    //   yield put(ApplicationActions.ApplicationFailure('Unknown Error'));
    //   return;
    // }

    // const applicationId = R.path(
    //   ['data', 'payload', 0, 'applicationId'],
    //   results
    // );
    // yield call(getApplication, api, { applicationId });
  }
}

export function* updateApplication(api, { application }) {
  const {
    account,
    tokenId,
    token,
    applications,
    programCode,
    isKiosk
  } = yield select(getPersist);
  const userId = account && account.userId;
  const locale = yield select(getLocale) || 'en';

  // const newId = Object.keys(applications).length + 1 || 1;
  const newId = new Date().getTime();

  // console.log('updateApplication saga', JSON.stringify(application, null, 2));
  let newApplication = JSON.parse(JSON.stringify(application));

  // Set Create Row Defaults
  if (!newApplication.id)
    newApplication.id = isKiosk ? `OFFLINE_${newId}` : '-1';

  if (!newApplication[APPLICANT_BASE]) {
    newApplication[APPLICANT_BASE] = { id: '-1' };
  }
  if (!newApplication[DAMAGED_ADDRESS_BASE]) {
    newApplication[DAMAGED_ADDRESS_BASE] = { id: '-1' };
  }
  if (!newApplication.languageCode) {
    newApplication.languageCode = locale === 'en' ? '1' : '2';
  }

  if (!newApplication.status) {
    newApplication.status = STATUS_IN_PROGRESS;
  }

  // Hard Code Origin Code
  newApplication.originCode =
    Platform.OS === 'web' ? ORGIN_CODE_WEB : ORGIN_CODE_MOBILE;
  // Hard Code Vendor Name
  newApplication.vendorName = VENDOR_NAME;

  const fieldsRequiringId = [
    APPLICANT_BASE,
    CO_APPLICANT_BASE,
    DAMAGED_ADDRESS_BASE,
    LAND_OWNER_BASE,
    POA_BASE,
    COMMUNICATIONS_DESIGNEE_BASE
  ];

  fieldsRequiringId.forEach(ele => {
    if (newApplication[ele] && !newApplication[ele].id) {
      newApplication[ele].id = '-1';
    }
  });

  const subfieldsRequiringId = [
    HOUSEHOLD_MEMBERS_BASE,
    LIEN_HOLDERS_BASE,
    ADDITIONAL_FUNDS_BASE,
    TENANTS_BASE,
    COMMUNICATIONS_DESIGNEE_BASE
  ];
  subfieldsRequiringId.forEach(base => {
    if (
      newApplication[base] &&
      typeof newApplication[base].forEach === 'function'
    ) {
      newApplication[base].forEach((ele, idx) => {
        if (!newApplication[base][idx].id) {
          newApplication[base][idx].id = '-1';
        }
      });
    }
  });

  if (
    newApplication[DAMAGED_ADDRESS_BASE] &&
    newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE]
  ) {
    if (
      newApplication[DAMAGED_ADDRESS_BASE] &&
      typeof newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE].forEach ===
        'function'
    ) {
      newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE].forEach(
        (ele, idx) => {
          if (!newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx].id) {
            newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx].id = '-1';
          }
        }
      );
    }
  }

  // Check to see if programCode has changed
  if (
    newApplication.programCode &&
    newApplication.programCode !== programCode
  ) {
    // Load Program Code Dependant APIs
  }

  if (isKiosk || !token) {
    yield put(PersistActions.PersistSetApplication(newApplication));
    yield put(ApplicationActions.ApplicationSuccess(newApplication));
  } else {
    // console.log('start update', JSON.stringify(newApplication, null, 2));

    let results = '';
    try {
      results = yield call(
        api.updateApplication,
        userId,
        tokenId,
        token,
        newApplication
      );
      // console.log('results', results);
    } catch (error) {
      console.log('error', error);
      if (R.path(['data', 'payload', 0, 'id'], error)) {
        results = error;
      }
    }

    if (R.path(['data', 'error', 0, 'message'], results)) {
      yield put(
        ApplicationActions.ApplicationFailure(results.data.error[0].message)
      );
      return;
    } else if (!R.path(['data', 'payload', 0, 'applicationId'], results)) {
      yield put(ApplicationActions.ApplicationFailure('Unknown Error'));
      return;
    }
    // console.log('update success');
    const applicationId = R.path(
      ['data', 'payload', 0, 'applicationId'],
      results
    );
    yield call(getApplication, api, {
      applicationId,
      diffApplication: newApplication
    });

    // try {
    //   results = yield call(getApplication, api, { applicationId });
    //   console.log('get application results', applicationId, results);
    // } catch (error) {
    //   console.log('error', error);
    //   if (R.path(['data', 'payload', 0, 'id'], error)) {
    //     results = error;
    //   }
    // }

    // if (R.path(['data', 'error', 0, 'message'], results)) {
    //   yield put(
    //     ApplicationActions.ApplicationFailure(results.data.error[0].message)
    //   );
    //   return;
    // } else if (!R.path(['data', 'payload', 0, 'id'], results)) {
    //   yield put(ApplicationActions.ApplicationFailure('Unknown Error'));
    //   return;
    // }

    // console.log('get application success');

    // yield put(PersistActions.PersistSetApplication(results.data.payload[0]));
    // yield put(ApplicationActions.ApplicationSuccess(results.data.payload[0]));

    // const newPersist = yield select(getPersist);
    // const retreivedApplication = newPersist.application;
  }
}

export function* deleteRecord(api, { recordType, memberId, idx }) {
  const { application, account, tokenId, token } = yield select(getPersist);
  const userId = account && account.userId;

  console.log('deleteRecord', recordType, memberId, idx);

  let newApplication = JSON.parse(JSON.stringify(application));

  if (!isNumeric(newApplication.id)) {
    // const base = DATA_STRUCTURE[recordType];
    // console.log(base, recordType, memberId, idx);
    console.log(JSON.stringify(newApplication, null, 2));
    if (idx === undefined || newApplication[recordType].length === 1) {
      delete newApplication[recordType];
    } else {
      newApplication[recordType].splice(idx, 1);
    }

    // console.log(JSON.stringify(newApplication, null, 2))

    yield put(PersistActions.PersistSetApplication(newApplication));
    yield put(ApplicationActions.ApplicationSuccess(newApplication));
  } else {
    console.log(
      'api.deleteRecord',
      DELETE_STRUCTURE[recordType],
      userId,
      tokenId,
      token,
      application.id,
      memberId
    );
    const results = yield call(
      api.deleteRecord,
      DELETE_STRUCTURE[recordType],
      userId,
      tokenId,
      token,
      application.id,
      memberId
    );
    console.log(JSON.stringify(results, null, 2));
    if (R.path(['data', 'payload', 0, 'applicationId'], results)) {
      yield call(getApplication, api, { applicationId: application.id });
    } else {
      yield put(ApplicationActions.ApplicationFailure('Error Deleting Record'));
    }
  }
}

export function* createDocusign(api, { application }) {
  const { account, tokenId, token } = yield select(getPersist);
  const { documents } = yield select(getDocumentState);
  const userId = account && account.userId;

  const locale = yield select(getLocale);

  if (Platform.OS === 'web') {
    let body = {
      id: '-1',
      programCode: application.programCode,
      locale: locale || 'en',
      applicationId: application.id,
      documents: []
    };
    let applicantDocuments = {
      personId: application[APPLICANT_BASE].id,
      documentTypeCode: [],
      envelopeId: '',
      returnURL: 'https://example.com'
    };

    if (application[APPLICANT_BASE].doYouReceiveIncomeCode === NO_OPTION) {
      // No Proof of Income Uploaded.
      applicantDocuments.documentTypeCode.push(
        DOCUMENT_TYPES.docusignNoIncome.code
      );
    } else if (
      application[APPLICANT_BASE].isTaxReturn4506TRequested === TRUE_OPTION
    ) {
      // 4506T Form
      applicantDocuments.documentTypeCode.push(
        DOCUMENT_TYPES.docusign4506T.code
      );
      // } else if (
      //   !hasDocument(
      //     documents,
      //     application[APPLICANT_BASE].id,
      //     DOCUMENT_TYPES.proofOfIncome.code
      //   )
      // ) {
      //   // No Proof of Income Uploaded.
      //   applicantDocuments.documentTypeCode.push(
      //     DOCUMENT_TYPES.docusignNoIncome.code
      //   );
    }

    // If there are no insurance entities
    if (
      application.hadInsuranceCode === NO_OPTION ||
      !application[INSURANCE_BASE]
    ) {
      applicantDocuments.documentTypeCode.push(
        DOCUMENT_TYPES.docusignNoInsurance.code
      );
    }

    // If there is a comm designee
    if (application.communicationsDesignee) {
      applicantDocuments.documentTypeCode.push(
        DOCUMENT_TYPES.docusignCommunicationDesignee.code
      );
    }

    // always add subrogation agreement
    applicantDocuments.documentTypeCode.push(
      DOCUMENT_TYPES.docusignSubrogationAgreement.code
    );

    // always add lawful presence
    applicantDocuments.documentTypeCode.push(
      DOCUMENT_TYPES.docusignLawfulPresence.code
    );

    body.documents.push(applicantDocuments);

    let coapplicantDocuments = null;
    if (application[CO_APPLICANT_BASE]) {
      coapplicantDocuments = {
        personId: application[CO_APPLICANT_BASE].id,
        documentTypeCode: [],
        envelopeId: '',
        returnURL: 'https://example.com'
      };

      if (application[CO_APPLICANT_BASE].doYouReceiveIncomeCode === NO_OPTION) {
        // No Proof of Income Uploaded.
        coapplicantDocuments.documentTypeCode.push(
          DOCUMENT_TYPES.docusignNoIncome.code
        );
      } else if (
        application[CO_APPLICANT_BASE].isTaxReturn4506TRequested === TRUE_OPTION
      ) {
        coapplicantDocuments.documentTypeCode.push(
          DOCUMENT_TYPES.docusign4506T.code
        );
        // } else if (
        //   !hasDocument(
        //     documents,
        //     application[CO_APPLICANT_BASE].id,
        //     DOCUMENT_TYPES.proofOfIncome.code
        //   )
        // ) {
        //   // No Proof of Income Uploaded.
        //   coapplicantDocuments.documentTypeCode.push(
        //     DOCUMENT_TYPES.docusignNoIncome.code
        //   );
      }

      if (coapplicantDocuments.documentTypeCode.length > 0) {
        body.documents.push(coapplicantDocuments);
      }
    }

    if (
      application[HOUSEHOLD_MEMBERS_BASE] &&
      application[HOUSEHOLD_MEMBERS_BASE].length &&
      application[HOUSEHOLD_MEMBERS_BASE].length > 0
    ) {
      application[HOUSEHOLD_MEMBERS_BASE].forEach(member => {
        let householdMemberDocuments = {
          personId: member.id,
          documentTypeCode: [],
          envelopeId: '',
          returnURL: 'https://example.com'
        };
        const dateOfBirth = moment(member.dateOfBirth, 'M/D/YYYY');
        const age = member.dateOfBirth
          ? moment().diff(dateOfBirth, 'years')
          : 0;
        if (age >= 18) {
          if (member.doYouReceiveIncomeCode === NO_OPTION) {
            // No Proof of Income Uploaded.
            householdMemberDocuments.documentTypeCode.push(
              DOCUMENT_TYPES.docusignNoIncome.code
            );
          } else if (member.isTaxReturn4506TRequested === TRUE_OPTION) {
            householdMemberDocuments.documentTypeCode.push(
              DOCUMENT_TYPES.docusign4506T.code
            );
            // } else if (
            //   !hasDocument(
            //     documents,
            //     member.id,
            //     DOCUMENT_TYPES.proofOfIncome.code
            //   )
            // ) {
            //   // No Proof of Income Uploaded.
            //   householdMemberDocuments.documentTypeCode.push(
            //     DOCUMENT_TYPES.docusignNoIncome.code
            //   );
          }
        }
        if (householdMemberDocuments.documentTypeCode.length > 0) {
          body.documents.push(householdMemberDocuments);
        }
      });
    }

    console.log(
      'api.createDocusign',
      userId,
      tokenId,
      token,
      JSON.stringify(body, null, 2)
    );
    const results = yield call(
      api.createDocusign,
      userId,
      tokenId,
      token,
      body
    );
    console.log(JSON.stringify(results, null, 2));
    if (R.path(['data', 'success'], results) === 'true') {
      // yield put(ApplicationActions.ApplicationSuccess(application));

      // TODO: Set Status Complete
      yield call(updateApplication, api, {
        application
      });
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      yield put(
        ApplicationActions.ApplicationFailure(results.data.error[0].message)
      );
    } else {
      yield put(
        ApplicationActions.ApplicationFailure('Error Creating Docusign')
      );
    }
    // NOT WEB
  } else if (!isNumeric(application.id)) {
    // Offline
    yield put(ApplicationActions.ApplicationSuccess(application));
  } else {
    // TODO: Set Status Complete
    yield call(updateApplication, api, {
      application
    });
  }
}

/*
 * When we set the application, save it in the persistant store as well.
 */
export function* saveApplicationId({ application }) {
  if (application && application.id) {
    const { applications } = yield select(getPersist);
    if (!applications[application.id]) {
      applications[application.id] = {};
    }
    yield put(PersistActions.PersistSetApplications(applications));
    yield put(PersistActions.PersistSetApplication(application));
    yield put(PersistActions.PersistSetApplicationId(application.id));
  } else {
    yield put(PersistActions.PersistSetApplication(null));
    yield put(PersistActions.PersistSetApplicationId(null));
  }
}
