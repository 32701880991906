// @flow

import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ApplicationsGet: null,
  ApplicationGet: ['applicationId'],
  ApplicationCreate: ['programCode'],
  ApplicationUpdate: ['application'],
  ApplicationDeleteRecord: ['recordType', 'memberId', 'idx'],
  ApplicationCreateDocusign: ['application'],
  ApplicationSuccess: ['data'],
  ApplicationFailure: ['error'],

  ApplicationSync: ['applicationKey'],

  ApplicationReset: null
});

export const ApplicationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  action: null,
  data: null,
  error: null,
  fetching: false
});

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = state =>
  state.merge({ error: null, fetching: true, data: null, action: null });

// we've successfully completed an API call.
export const success = (state, { data, action }) =>
  state.merge({ fetching: false, error: null, data, action });

// export const setApplications = (state, { applications }) =>
//   state.merge({ applications });

// export const setDocuments = (state, { offlineDocuments }) =>
//   state.merge({ offlineDocuments });

// we've had a problem with our API call.
export const failure = (state, { error, action }) =>
  state.merge({
    fetching: false,
    error: error || 'Unknown Error',
    data: null,
    action
  });

export const reset = state => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.APPLICATIONS_GET]: request,
  [Types.APPLICATION_GET]: request,

  [Types.APPLICATION_CREATE]: request,
  [Types.APPLICATION_UPDATE]: request,

  [Types.APPLICATION_SUCCESS]: success,
  [Types.APPLICATION_FAILURE]: failure,

  [Types.APPLICATION_DELETE_RECORD]: request,

  [Types.APPLICATION_CREATE_DOCUSIGN]: request,

  [Types.APPLICATION_SYNC]: request,

  [Types.APPLICATION_RESET]: reset
});

/* ------------- Selectors ------------- */

// Do we have an application state?
export const hasApplication = loginState => loginState.application !== null;
