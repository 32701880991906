import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Platform,
  Image,
  ImageBackground,
  TouchableOpacity,
  View,
  Text
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withFormik, Field } from 'formik';

import Icon from 'react-native-vector-icons/dist/Ionicons';
import FAIcon from 'react-native-vector-icons/dist/FontAwesome';

import ErrorMessage from './ErrorMessage';

import { Grid, Row, Col } from './Grid';

import { isRequired } from '../Lib/Utils';

import DocumentActions, {
  getDocumentURL,
  DocumentTypes,
  getDocumentIcon
} from '../Redux/DocumentRedux';

import FormattedMessage from './FormattedMessage';
import FormGroupRadio from './FormGroupRadio';
import FullButton from './FullButton';
import CaptureUploadDocuments from './CaptureUploadDocuments';

import { getPersonId } from '../Lib/Utils';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
// import styles from '../Styles/ScreenStyles';

// import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

class SelectUploadDocumentType extends Component {
  static propTypes = {
    onComplete: PropTypes.func.isRequired,
    scrollToTop: PropTypes.func,
    documentType: PropTypes.string.isRequired,
    base: PropTypes.string.isRequired,
    index: PropTypes.number,
    filterKeywordValues: PropTypes.array,
    buttonLabelId: PropTypes.string,
    labelId: PropTypes.string,
    hideButton: PropTypes.bool,
    hideDocTypes: PropTypes.bool,
    defaultDocuments: PropTypes.array,
    defaultKeywordValue: PropTypes.string,
    multiple: PropTypes.bool,
    horiz: PropTypes.bool
  };

  static defaultProps = {
    scrollToTop: () => {},
    index: undefined,
    filterKeywordValues: [],
    buttonLabelId: 'form.continueNextStep',
    labelId: '',
    hideButton: false,
    hideDocTypes: false,
    defaultDocuments: [],
    defaultKeywordValue: null,
    multiple: true,
    horiz: false
  };

  renderInput = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    deleteDocuments,
    documentType,
    multiple,
    horiz,
    disabled
  }) => (
    <CaptureUploadDocuments
      documentType={documentType}
      documents={value}
      setDocuments={e => {
        // console.log('setDocuments', name, JSON.stringify(e));
        // onChange(JSON.stringify(e));
        setFieldValue(name, e);
      }}
      deleteDocuments={deleteDocuments}
      disabled={disabled}
      multiple={multiple}
      horiz={horiz}
    />
  );

  state = {
    errorText: ''
  };

  formInitilized = false;

  loadedDocuments = false;
  loadingDocuments = false;
  loadingIdentityProofDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  componentDidMount() {
    // this.loadingDocuments = true;
    // this.props.loadDocuments(this.props.documentType);
  }

  componentWillReceiveProps(nextProps) {
    const {
      application,
      base,
      index,
      defaultDocuments,
      defaultKeywordValue,
      documentType,
      setFieldValue,
      fetching,
      errorText,
      resetForm,
      isSubmitting,
      setSubmitting,
      scrollToTop,
      documents,
      status,
      setStatus
    } = nextProps;

    // if (this.loadingDocuments && !fetching) {
    //   this.loadingDocuments = false;
    //   const personId = getPersonId(application, base, index);

    //   // Search Documents for default
    //   let found = false;
    //   if (defaultKeywordValue && documents) {
    //     documents.forEach(ele => {
    //       if (
    //         ele &&
    //         documentType === ele.documentType &&
    //         ele.personId === personId &&
    //         ele.keywordValue === defaultKeywordValue
    //       ) {
    //         found = true;
    //       }
    //     });
    //     if (!found) {
    //       console.log('setting default documents');
    //       setFieldValue('Documents', defaultDocuments);
    //       setFieldValue('KeywordValue', defaultKeywordValue);
    //     } else {
    //       console.log('skipping setting default documents');
    //     }
    //   }
    // }

    if (isSubmitting && status === 'uploadingDocuments' && !fetching) {
      scrollToTop();
      setSubmitting(false);
      setStatus('');
      if (errorText) {
        console.log('componentWillReceiveProps error', errorText);
        this.setState({ errorText });
      } else {
        console.log('componentWillReceiveProps success');
        resetForm();
      }
    }

    // if (this.uploadingDocuments && !fetching) {
    //   this.uploadingDocuments = false;
    //   if (errorText) {
    //     this.refs.scroll.scrollTo({ x: 0, y: 0 });
    //     this.setState({ errorText });
    //   } else {
    //     this.refs.scroll.scrollTo({ x: 0, y: 0 });
    //     reset();
    //   }
    // }
  }

  handleDeleteFiles(keywordValueCode) {
    const {
      application,
      deleteDocuments,
      base,
      index,
      documentType
    } = this.props;

    const personId = getPersonId(application, base, index);

    this.deletingDocuments = true;
    console.log('deleteDocuments', personId, documentType, keywordValueCode);
    deleteDocuments(personId, documentType, keywordValueCode);
  }

  handleReset = () => {
    console.log('handleReset resetForm');
    this.props.setFieldValue('Documents', []);
    this.props.setFieldValue('KeywordValue', null);
  };

  renderDocuments(documents) {
    const {
      docTypes,
      userId,
      tokenId,
      token,
      application,
      base,
      index,
      horiz,
      documentType,
      filterKeywordValues
    } = this.props;
    const filteredDocuments = {};
    const personId = getPersonId(application, base, index);

    // Filter Loaded Documents
    if (documents) {
      documents.forEach(ele => {
        if (
          ele &&
          ele.documentType === documentType &&
          ele.personId === personId &&
          !filterKeywordValues.includes(ele.keywordValue)
        ) {
          if (!filteredDocuments[ele.keywordValue]) {
            filteredDocuments[ele.keywordValue] = {
              fileName: ele.fileName,
              keywordValue: ele.keywordValue,
              fileDownloadURL: ele.fileDownloadURL,
              count: 1
            };
          } else {
            filteredDocuments[ele.keywordValue].count++;
          }
        }
      });
    }

    const rows = Object.keys(filteredDocuments).map((key, idx) => {
      const ele = filteredDocuments[key];

      const plusCount = ele.count - 1;
      const plusText = ele.count > 1 && (
        <View>
          <Text
            style={{
              ...Fonts.style.normal,
              // position: 'absolute',
              fontFamily: Fonts.type.bold,
              color: Colors.white,
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: Metrics.baseMargin
            }}
          >
            + {plusCount}
          </Text>
        </View>
      );

      const photo = getDocumentURL(ele.fileDownloadURL, userId, tokenId, token);
      const icon = getDocumentIcon(ele.fileName, userId, tokenId, token);
      return (
        <Row
          key={idx}
          style={{
            borderWidth: 1,
            padding: Metrics.baseMargin,
            marginHorizontal: 0,
            borderColor: Colors.lightGrey,
            marginBottom: Metrics.baseMargin,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Col
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 90
            }}
          >
            {icon !== 'file-image-o' ? (
              <View
                style={{
                  width: 90,
                  height: 90,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <FAIcon name={icon} size={80} />
                <View style={{ position: 'absolute' }}>{plusText}</View>
              </View>
            ) : (
              <ImageBackground
                source={{ uri: photo }}
                style={{
                  // position: 'absolute',
                  width: 90,
                  height: 90,
                  // resizeMode: 'contain',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {plusText}
              </ImageBackground>
            )}
          </Col>
          <Col
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            <Text numberOfLines={2} style={Fonts.style.normal}>
              {docTypes[documentType][ele.keywordValue].value ||
                docTypes[documentType][ele.keywordValue].keywordValue}
            </Text>
          </Col>
          <Col
            xs={3}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <TouchableOpacity
              style={{
                // display: 'inline-block',
                paddingTop: Metrics.baseMargin,
                paddingBottom: Metrics.baseMargin,
                paddingLeft:
                  Platform.OS === 'web'
                    ? Metrics.doubleBaseMargin
                    : Metrics.baseMargin,
                paddingRight:
                  Platform.OS === 'web'
                    ? Metrics.doubleBaseMargin
                    : Metrics.baseMargin,
                marginRight: Metrics.baseMargin,
                borderRadius: 5,
                backgroundColor: '#aaa'
              }}
              onPress={this.handleDeleteFiles.bind(this, key)}
            >
              <Icon name="md-trash" size={20} />
            </TouchableOpacity>
          </Col>
        </Row>
      );
      // }
    });

    return (
      rows && (
        <View>
          <Row style={{ padding: Metrics.baseMargin, marginHorizontal: 0 }}>
            <Col
              xs={3}
              style={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <FormattedMessage
                id="Image"
                style={[Fonts.style.normal, { fontWeight: 'bold' }]}
              />
            </Col>
            <Col
              span={{ xs: 9 }}
              style={{ justifyContent: 'center', alignItems: 'flex-start' }}
            >
              <FormattedMessage
                id="Type"
                style={[Fonts.style.normal, { fontWeight: 'bold' }]}
              />
            </Col>
          </Row>
          {rows}
        </View>
      )
    );
  }

  render() {
    const {
      initilized,
      handleSubmit,
      docTypes,
      documents,
      defaultDocuments,
      defaultKeywordValue,
      fetching,
      values,
      isValid,
      onComplete,
      labelId,
      buttonLabelId,
      hideDocTypes,
      hideButton,
      horiz,
      multiple,
      documentType,
      filterKeywordValues
    } = this.props;

    const { errorText } = this.state;

    // console.log(JSON.stringify(values, null, 2));
    // console.log(JSON.stringify(docTypes[documentType], null, 2));
    // console.log('values', JSON.stringify(values, null, 2));

    const filteredOptions = {};

    Object.keys(docTypes[documentType] || {}).forEach(key => {
      if (!filterKeywordValues.includes(key)) {
        filteredOptions[key] = docTypes[documentType][key];
      }
    });

    // console.log('SelectUploadDocumentType values', values);
    const hasDocument = values.Documents && values.Documents.length > 0;

    if (!initilized) return null;

    return (
      <View>
        {this.renderDocuments(documents)}
        <Row>
          <Col sm={12}>
            <ErrorMessage errorText={errorText} />
            <Field
              name="Documents"
              documentType={documentType}
              deleteDocuments={this.handleReset}
              horiz={horiz}
              multiple={multiple}
              disabled={fetching}
              component={this.renderInput}
            />
          </Col>
        </Row>
        {(hasDocument || !hideDocTypes) && (
          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field="KeywordValue"
                labelId={labelId}
                disabled={!hasDocument}
                options={filteredOptions}
                validate={isRequired}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col sm={12}>
            {hasDocument && (
              <FullButton
                text={I18n.t('buttons.SaveDocument')}
                onPress={() => {
                  this.uploadingDocuments = true;
                  handleSubmit();
                }}
                disabled={!isValid || fetching}
              />
            )}
            {!hasDocument && !hideButton && (
              <FullButton text={I18n.t(buttonLabelId)} onPress={onComplete} />
            )}

            <View style={{ marginTop: Metrics.doubleBaseMargin }} />
          </Col>
        </Row>
      </View>
    );
  }
}

SelectUploadDocumentType = withFormik({
  mapPropsToValues: props => {
    const {
      application,
      base,
      index,
      documents,
      documentType,
      defaultDocuments,
      defaultKeywordValue
    } = props;

    // return {
    //   Documents: defaultDocuments,
    //   KeywordValue: defaultKeywordValue
    // };

    const personId = getPersonId(application, base, index);
    // console.log('mapPropsToValues', documents);
    // Search Documents for default
    let found = false;
    if (defaultKeywordValue) {
      documents.forEach(ele => {
        if (
          ele &&
          documentType === ele.documentType &&
          ele.personId === personId &&
          ele.keywordValue === defaultKeywordValue
        ) {
          found = true;
        }
      });
    }

    // If found, don't set default documents.
    if (found) {
      console.log('found', found);
      return {
        Documents: [],
        KeywordValue: null
      };
    } else {
      return {
        Documents: defaultDocuments,
        KeywordValue: defaultKeywordValue
      };
    }
  },
  isInitialValid: props =>
    props.defaultKeywordValue &&
    props.defaultDocuments &&
    props.defaultDocuments.length > 0,
  handleSubmit: (values, { setStatus, props }) => {
    // props.updateApplication(values);
    const {
      application,
      uploadDocuments,
      base,
      index,
      setFieldValue,
      documentType
    } = props;

    const personId = getPersonId(application, base, index);

    console.log('handleSubmit', application, base, index, personId);

    const { Documents, KeywordValue } = values;

    // this.uploadingDocuments = true;
    // console.log(
    //   'SelectUploadDocumentType handleSubmit uploadDocuments',
    //   personId,
    //   Documents,
    //   documentType,
    //   KeywordValue
    // );

    uploadDocuments(personId, Documents, documentType, KeywordValue);
    setStatus('uploadingDocuments');
  }
})(SelectUploadDocumentType);

const mapStateToProps = ({
  app: { initilized },
  persist: { options, docTypes, application, account, tokenId, token },
  document: { documents, fetching, error, action }
  // form
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  action,
  fetching,
  errorText: error,
  initilized,
  application
  // initialValues: application
  // currentForm: form.OtherIncome
});

const mapDispatchToProps = dispatch => ({
  // saveApplication: (application) => dispatch(ApplicationActions.ApplicationSuccess(application)),
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadDocuments: (
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  ) =>
    dispatch(
      DocumentActions.DocumentUpload(
        personId,
        documents,
        documentTypeCode,
        keywordValueCode,
        documentId
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectUploadDocumentType);
