import { call, put, select } from 'redux-saga/effects';
import * as R from 'ramda';
import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';
import AccountActions from '../Redux/AccountRedux';

import { updateApplication } from './ApplicationSagas';

import { isNumeric } from '../Lib/Utils';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;
const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const BASE_DAMAGED_ADDRESS = DATA_STRUCTURE.damagedAddress;

export const getPersist = state => state.persist;
export const getLocale = state => state.i18n.locale;

export function* login(api, { credentials }) {
  try {
    console.log('calling login', credentials);
    const results = yield call(api.login, credentials);
    console.log('results', results);

    // Error Check
    if (R.path(['data', 'error', 0, 'message'], results)) {
      throw new Error(results.data.error[0].message || 'Unknown Error');
    } else if (!R.path(['data', 'payload', 0, 'userId'], results)) {
      throw new Error('Unknown Error');
    }

    // Success
    console.log('ok', results.data);
    const userId = results.data.payload[0].userId;
    const emailAddress = results.data.payload[0].emailAddress;
    const isActiveKioskUser = results.data.payload[0].isActiveKioskUser;
    const account = { userId, emailAddress, isActiveKioskUser };

    // Save Account
    yield put(PersistActions.PersistSetAccount(account));

    // Done
    console.log('success!!', account);
    yield put(AccountActions.AccountSuccess(account));
  } catch (error) {
    console.log('error', error.message);
    yield put(AccountActions.AccountFailure(error.message));
  }
}

export function* createAccount(api, { credentials }) {
  try {
    console.log('calling create', credentials);
    const results = yield call(api.createAccount, credentials);
    console.log('results', results);

    // Error Check
    if (R.path(['data', 'error', 0, 'message'], results)) {
      throw new Error(results.data.error[0].message || 'Unknown Error');
    } else if (!R.path(['data', 'payload', 0, 'userId'], results)) {
      throw new Error('Unknown Error');
    }

    // Success
    console.log('ok', results.data);
    const userId = results.data.payload[0].userId;
    const emailAddress = results.data.payload[0].emailAddress;
    const account = { userId, emailAddress };

    // Save Account
    yield put(PersistActions.PersistSetAccount(account));

    // Done
    console.log('success!!', account);
    yield put(AccountActions.AccountSuccess(account));
  } catch (error) {
    console.log('error', error.message || error);
    yield put(AccountActions.AccountFailure(error.message || error));
  }
}

export function* requestOtp(api, { userId }) {
  try {
    console.log('calling requestOtp', userId);
    const results = yield call(api.requestOTP, userId);
    // console.log('results', results)

    // Error Check
    if (results.data && results.data.error) {
      throw new Error(results.data.error[0].message || 'Unknown Error');
    } else if (!R.path(['data', 'payload', 0, 'otpId'], results)) {
      throw new Error('Unknown Error');
    }

    // Done
    console.log('success!!', results.data.payload[0]);
    yield put(AccountActions.AccountSuccess(results.data.payload[0]));
  } catch (error) {
    console.log('error', error.message || error);
    yield put(AccountActions.AccountFailure(error.message || error));
  }
}

export function* verifyOtp(api, { userId, otpId, otp }) {
  try {
    console.log('calling verifyOtp', userId, otpId, otp);
    const results = yield call(api.verifyOTP, userId, otpId, otp);
    // console.log('results', results)

    // Error Check
    if (results.data && results.data.error) {
      throw new Error(results.data.error[0].message || 'Unknown Error');
    } else if (!R.path(['data', 'payload', 0, 'token'], results)) {
      throw new Error('Unknown Error');
    }

    // Success
    yield put(
      PersistActions.PersistSetToken(
        results.data.payload[0].tokenId,
        results.data.payload[0].token
      )
    );

    // See if we need to upload an offline application.
    const { isKiosk, application, applications } = yield select(getPersist);
    if (!isKiosk && application && application.id) {
      const oldApplicationId = application.id;
      if (application.id.substring(0, 3) === 'OFF' || application.id === '-1') {
        const offlineApplicationId = application.id;
        // This is an Offline Application
        let newApplication = JSON.parse(JSON.stringify(application));
        // Flag This Application to be Created
        newApplication.id = '-1';
        console.log('calling internal update application', newApplication);
        yield call(updateApplication, api, { application: newApplication });

        const { application: updatedApplication } = yield select(getPersist);
        console.log(
          'finished calling internal update application',
          updatedApplication
        );

        // Verify We have an *new* application id.
        if (updatedApplication.id.substring(0, 3) !== 'OFF') {
          // Remove OffLine Application from Store
          yield put(
            PersistActions.PersistSetApplications(
              applications.without(offlineApplicationId)
            )
          );
        }
      }
    }
    // Done
    console.log('success!!', results.data.payload[0]);
    yield put(AccountActions.AccountSuccess(results.data.payload[0]));
  } catch (error) {
    console.log('error', error.message || error);
    yield put(AccountActions.AccountFailure(error.message || error));
  }
}

export function* forgotPassword(api, { email }) {
  const locale = yield select(getLocale) || 'en';

  try {
    console.log('calling forgotPassword', email, locale);
    const results = yield call(api.forgotPassword, email, locale);
    console.log('results', results);

    // Error Check
    if (results.data.success === 'true') {
      yield put(AccountActions.AccountSuccess(true));
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      throw new Error(
        `${results.data.error[0].code}: ${results.data.error[0].message}`
      );
    } else {
      throw new Error('Unknown Error');
    }
  } catch (error) {
    console.log('error', error.message || error);
    yield put(AccountActions.AccountFailure(error.message || error));
  }
}

export function* changePassword(api, { password, token }) {
  const locale = yield select(getLocale) || 'en';

  try {
    console.log('calling changePassword', password, token, locale);
    const results = yield call(api.changePassword, password, token, locale);
    console.log('results', results);

    // Error Check
    if (results.data.success === 'true') {
      yield put(AccountActions.AccountSuccess(true));
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      throw new Error(
        `${results.data.error[0].code}: ${results.data.error[0].message}`
      );
    } else {
      throw new Error('Unknown Error');
    }
  } catch (error) {
    console.log('error', error.message || error);
    yield put(AccountActions.AccountFailure(error.message || error));
  }
}
