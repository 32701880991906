import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Icon from 'react-native-vector-icons/dist/Ionicons';

import { Grid, Row, Col } from './Grid';
import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions, { getDocumentURL } from '../Redux/DocumentRedux';
import FormattedMessage from './FormattedMessage';
import FullButton from './FullButton';
import CaptureUploadDocuments from './CaptureUploadDocuments';
import FormGroupCheckbox from './FormGroupCheckbox';

import { getPersonId, isRequired } from '../Lib/Utils';
// Styles
import { Fonts, Colors, Metrics } from '../Themes/';
import styles from '../Containers/Styles/ScreenStyles';

// import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';
// const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfIncome.code;
// const DOCUMENT_KEYWORD_VALUE = DOCUMENT_TYPES.proofOfIncome.keywords.TaxReturn;

class CaptureSignatureWeb extends Component {
  static propTypes = {
    base: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    documentType: PropTypes.string.isRequired,
    documentKeywordValue: PropTypes.string,
    labelId: PropTypes.string.isRequired,
    checkboxLabelId: PropTypes.string,
    onComplete: PropTypes.func.isRequired
  };
  static defaultProps = {
    documentKeywordValue: '',
    checkboxLabelId: 'form.IAgree',
    index: null
  };

  state = {
    errorText: null,
    signed: false,

    loading: true,
    uploading: false
  };

  formInitilized = false;
  loadedDocuments = false;
  loadingDocuments = false;
  loadingIdentityProofDocuments = false;
  uploadingDocuments = false;
  deletingDocuments = false;

  componentDidMount() {
    // const { documentType } = this.props;
    // this.loadingDocuments = true;
    // this.props.loadDocuments(documentType);
  }

  componentWillReceiveProps(nextProps) {
    const {
      isSubmitting,
      setSubmitting,
      data,
      errorText,
      fetching,
      initilized,
      onComplete,
      currentForm,
      resetForm
    } = nextProps;

    if (this.loadingDocuments && !fetching) {
      this.setState({ loading: false });
    } else if (this.uploadingDocuments && !fetching) {
      this.uploadingDocuments = false;
      if (errorText) {
        this.refs.scroll.scrollTo({ x: 0, y: 0 });
        this.setState({ errorText, uploading: false });
      } else {
        this.refs.scroll.scrollTo({ x: 0, y: 0 });
        this.setState({ uploading: false });
        // resetForm();
        console.log('onComplete', data);
        // onComplete(data);
      }
    }

    // if (isSubmitting && !fetching && errorText) {
    //   setSubmitting(false);
    //   this.setState({ errorText });
    //   // Scroll to top
    //   this._scroll.scrollTo({ x: 0, y: 0 });
    // } else if (isSubmitting && !fetching && data) {
    //   // setSubmitting(false);
    //   console.log('onComplete', data);
    //   onComplete(data);
    // }
  }

  // handleUploadFiles = images => {
  //   // console.log(images);

  //   const {
  //     application,
  //     uploadDocuments,
  //     base,
  //     index,
  //     documentType,
  //     documentKeywordValue
  //   } = this.props;

  //   const personId = getPersonId(application, base, index);

  //   // if (token) {

  //   this.setState({ uploading: true });
  //   this.uploadDocuments = true;
  //   console.log(
  //     'handleUploadFiles',
  //     personId,
  //     images,
  //     documentType,
  //     documentKeywordValue
  //   );
  //   uploadDocuments(personId, images, documentType, documentKeywordValue);
  // };

  // handleDeleteFiles = () => {
  //   const {
  //     application,
  //     deleteDocuments,
  //     base,
  //     index,
  //     documentType,
  //     documentKeywordValue
  //   } = this.props;

  //   const personId = getPersonId(application, base, index);

  //   this.deletingDocuments = true;
  //   console.log(
  //     'deleteDocuments',
  //     personId,
  //     documentType,
  //     documentKeywordValue
  //   );
  //   deleteDocuments(personId, documentType, documentKeywordValue);
  // };

  // onSubmit = values => {
  //   const { updateApplication } = this.props;

  //   this.savingApplication = true;
  //   updateApplication(values);
  // };

  // handleDeleteFiles = () => {
  //   const {
  //     application,
  //     deleteDocuments,
  //     base,
  //     index,
  //     documentType,
  //     documentKeywordValue
  //   } = this.props;

  //   const personId = getPersonId(application, base, index);

  //   this.deletingDocuments = true;
  //   console.log(
  //     'deleteDocuments',
  //     personId,
  //     documentType,
  //     documentKeywordValue
  //   );
  //   deleteDocuments(personId, documentType, documentKeywordValue);
  // };

  handleDragEvent = () => {
    if (!this.state.signed) {
      this.setState({ signed: true });
    }
  };

  handleResetSign = () => {
    this.handleDeleteFiles();
    this.refs.sign.resetImage();
    this.setState({ signed: false });
  };

  // handleSaveSign = () => {
  //   const {
  //     documents,
  //     onComplete,
  //     application,
  //     base,
  //     index,
  //     documentType
  //   } = this.props;

  //   const personId = getPersonId(application, base, index);
  //   let signatureImage = null;
  //   documents.forEach(ele => {
  //     if (ele && ele.documentType === documentType && ele.personId === personId) {
  //       signatureImage = ele.fileDownloadURL;
  //     }
  //   });

  //   if (signatureImage) {
  //     onComplete();
  //   } else {
  //     this.refs.sign.saveImage();
  //   }
  // };

  handleSavedSign = result => {
    const {
      application,
      uploadSignature,
      base,
      index,
      documentType,
      documentKeywordValue
    } = this.props;

    const personId = getPersonId(application, base, index);

    this.uploadingDocuments = true;
    uploadSignature(personId, result, documentType, documentKeywordValue);
  };

  render() {
    const {
      application,
      base,
      index,
      userId,
      tokenId,
      token,
      initilized,
      documents,
      fetching,
      onComplete,
      onCancel,
      onSkip,
      checkboxLabelId,
      isValid,
      documentType,
      documentKeywordValue
    } = this.props;
    const { signed } = this.state;

    if (!initilized) return null;

    const personId = getPersonId(application, base, index);

    let signatureImage = null;
    documents &&
      documents.forEach(ele => {
        if (
          ele &&
          ele.documentType === documentType &&
          ele.personId === personId
        ) {
          signatureImage = getDocumentURL(
            ele.fileDownloadURL,
            userId,
            tokenId,
            token
          );
          console.log('found signatureImage', signatureImage);
        }
      });

    return (
      <View>
        <Row>
          <Col
            sm={12}
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <FormGroupCheckbox
              labelId={checkboxLabelId}
              field="signatureChecked"
              validate={isRequired}
            />
            {/* <SignatureCapture
                ref="sign"
                style={{ flex: 1, height: 200 }}
                viewMode="portrait"
                showTitleLabel={false}
                saveImageFileInExtStorage={false}
                showNativeButtons={false}
                onDragEvent={this.handleDragEvent}
                onSaveEvent={this.handleSavedSign}
              /> */}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FullButton
              text={I18n.t('form.continueNextStep')}
              onPress={onComplete}
              disabled={!isValid}
            />
          </Col>
        </Row>
      </View>
    );
  }
}

// const validationSchema = yup.object().shape({
//   email: yup
//     .string()
//     .email('error.invalidEmail')
//     .required('error.required'),
//   password: yup.string().required('error.required')
// });

CaptureSignatureWeb = withFormik({
  mapPropsToValues: props => ({
    signatureChecked: 0
  }),

  // validate: (values, props) => {
  //   // console.log('validating', values);
  //   const errors = {};
  //   // if (
  //   //   R.path(['contact', 'preferredContactMethod'], values) &&
  //   //   values.contact.preferredContactMethod === '3' &&
  //   //   !R.path(['contact', 'email'], values)
  //   // ) {
  //   //   errors[CONTACT_BASE] = {};
  //   //   errors[CONTACT_BASE].email = 'emailRequiredMethod';
  //   // }
  //   const { DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear } = values.tmp;
  //   if (DateOfBirthMonth && DateOfBirthDay && DateOfBirthYear) {
  //     const dob = `${DateOfBirthMonth}-${DateOfBirthDay}-${DateOfBirthYear}`;
  //     const dateOfBirth = moment(dob, 'M-D-YYYY');
  //     if (!dateOfBirth.isValid()) {
  //       errors.DateOfBirth = 'error.invalidDate';
  //     }
  //   }

  //   return errors;
  // },

  // validationSchema,
  // Submission handler
  handleSubmit: (values, { props }) => {
    // const { base, index } = props;
    // // console.log('handleSubmit', JSON.stringify(values, null, 2));
    // // let newValues = Immutable.asMutable(values) // Does not work
    // const newValues = Object.assign({}, values);
    // console.log('updateApplication', JSON.stringify(newValues, null, 2));
    // props.updateApplication(newValues);
  }
})(CaptureSignatureWeb);

const mapStateToProps = ({
  app: { initilized },
  persist: { account, tokenId, token, application, options, docTypes },
  document: { documents, fetching, error }
}) => ({
  userId: R.path(['userId'], account),
  tokenId,
  token,
  options,
  docTypes,
  documents,
  fetching,
  errorText: error,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  loadDocuments: docType =>
    dispatch(DocumentActions.DocumentLoadDocuments(docType)),
  uploadSignature: (personId, signature, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentUploadSignature(
        personId,
        signature,
        documentTypeCode,
        keywordValueCode
      )
    ),
  deleteDocuments: (personId, documentTypeCode, keywordValueCode) =>
    dispatch(
      DocumentActions.DocumentDelete(
        personId,
        documentTypeCode,
        keywordValueCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaptureSignatureWeb);
