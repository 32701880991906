import { createStore, applyMiddleware, compose } from 'redux';
import { Platform } from 'react-native';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore
} from 'react-redux-i18n';
import { composeWithDevTools } from 'redux-devtools-extension';

// import createBrowserHistory from 'history/createBrowserHistory';
// import createMemoryHistory from 'history/createMemoryHistory';

import Rehydration from '../Services/Rehydration';
import ReduxPersist from '../Config/ReduxPersist';
import Config from '../Config/DebugConfig';
import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';

// import ScreenTracking from './ScreenTrackingMiddleware'

// export const history =
//   Platform.OS === 'web' ? createBrowserHistory() : createMemoryHistory();

// creates the store
export default (history, rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Analytics Middleware ------------- */
  // middleware.push(ScreenTracking)

  /* ------------- Saga Middleware ------------- */

  const sagaMonitor =
    Config.useReactotron && !Platform.OS === 'web'
      ? console.tron.createSagaMonitor()
      : null;
  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor
  });
  middleware.push(sagaMiddleware);

  /* ------------- Routing Middleware ------------- */

  middleware.push(routerMiddleware(history));

  /* ------------- Thunk Middleware for react-redux-18n ------------- */

  middleware.push(thunk);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  const devTools =
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__();

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  const createAppropriateStore =
    console.tron && Config.useReactotron
      ? console.tron.createStore
      : createStore;

  const store = createAppropriateStore(
    rootReducer,
    composeWithDevTools(compose(...enhancers))
  );

  // configure persistStore and check reducer version number
  if (ReduxPersist.active) {
    Rehydration.updateReducers(store);
  }

  //start up react-redux-18n sync
  syncTranslationWithStore(store);
  store.dispatch(setLocale(DEFAULT_LANGUAGE));

  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga);

  return { store, sagasManager, sagaMiddleware };
};
