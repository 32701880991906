import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text, TouchableOpacity } from 'react-native';
import { RNCamera, FaceDetector } from 'react-native-camera';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import FormattedMessage from './FormattedMessage';
import FullButton from './FullButton';

import PersistActions from '../Redux/PersistRedux';

// Styles
import { Fonts, Colors, Metrics } from '../Themes/';

const SAMPLE_TX_DL_SCAN = `@

ANSI 636015030002DL00410241ZT02820015DLDCAC
DCBNONE
DCDNONE
DBA03152023
DCSCAFFREY
DCTJANE ELIZABETH
DBD06152016
DBB03151986
DBC2
DAYBRO
DAU 68 IN
DAG802 S CARANCAHUA ST # 433
DAICORPUS CHRISTI
DAJTX
DAK78401      
DAQ41114662
DCF18310610068115788163
DCGUSA
DCHNONE
DAZBRO
DCU
ZTZTA135
ZTBW
`;

// Source: http://www.aamva.org/DL-ID-Card-Design-Standard/
const CODE_TO_KEY = {
  DCS: 'lastName',
  DAC: 'firstName',
  DAD: 'middleName',
  DBB: 'dateOfBirth',
  DBC: 'gender',
  DAG: 'address1',
  DAI: 'city',
  DAJ: 'state',
  DAK: 'zip'

  // DCL: 'race',
  // DBD: 'DateOfIssue',
  // DCA: 'jurisdictionVehicleClass',
  // DCB: 'jurisdictionRestrictionCodes',
  // DCD: 'jurisdictionEndorsementCodes',
  // DBA: 'dateOfExpiry',
  // DCT: 'givenName',
  // DAY: 'eyeColor',
  // DAU: 'height',
  // DAQ: 'documentNumber',
  // DCF: 'documentDiscriminator',
  // DCG: 'issuer',
  // DDE: 'lastNameTruncated',
  // DDF: 'firstNameTruncated',
  // DDG: 'middleNameTruncated',

  // // optional
  // DAZ: 'hairColor',
  // DAH: 'addressStreet2',
  // DCI: 'placeOfBirth',
  // DCJ: 'auditInformation',
  // DCK: 'inventoryControlNumber',
  // DBN: 'otherlastName',
  // DBG: 'otherfirstName',
  // DBS: 'otherSuffixName',
  // DCU: 'nameSuffix', // e.g. jr, sr
  // DCE: 'weightRange',
  // DCM: 'standardVehicleClassification',
  // DCN: 'standardEndorsementCode',
  // DCO: 'standardRestrictionCode',
  // DCP: 'jurisdictionVehicleClassificationDescription',
  // DCQ: 'jurisdictionEndorsementCodeDescription',
  // DCR: 'jurisdictionRestrictionCodeDescription',
  // DDA: 'complianceType',
  // DDB: 'dateCardRevised',
  // DDC: 'dateOfExpiryHazmatEndorsement',
  // DDD: 'limitedDurationDocumentIndicator',
  // DAW: 'weightLb',
  // DAX: 'weightKg',
  // DDH: 'dateAge18',
  // DDI: 'dateAge19',
  // DDJ: 'dateAge21',
  // DDK: 'organDonor',
  // DDL: 'veteran'
};

class ScanBarcode extends Component {
  static propTypes = {
    onComplete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = {
    orientation: null,
    torchOn: null
  };

  completedScan = false;

  formInitilized = false;

  componentDidMount() {
    const { initilized } = this.props;

    if (initilized && !this.formInitilized) {
      this.initilizeForm(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { initilized, torch } = nextProps;

    if (initilized && !this.formInitilized) {
      this.initilizeForm(nextProps);
    }

    if (torch !== null && this.state.torchOn !== torch) {
      this.setState({ torchOn: torch });
    }
  }

  initilizeForm(props) {
    const { torch } = props;

    this.formInitilized = true;
    this.setState({ torchOn: torch });
  }
  parseDL(str) {
    const props = {};
    const lines = str.trim().split('\n');
    let started = false;
    console.log(`${lines.length} lines`);
    for (let i = 0; i < lines.length - 1; i++) {
      let line = lines[i];

      if (!started) {
        // Non Compliant Fix for MS.  ANSI should have a trailing space.
        if (line.indexOf('ANSI') !== -1) {
          console.log('started');
          started = true;
        }
        continue;
      }

      let code = line.slice(0, 3);
      let value = line.slice(3);
      let key = CODE_TO_KEY[code];

      console.log(code, value, key);

      // DOES NOT COMPLY WITH AAMV STANDARDS (State: TX).  Manually Extract First and Middle Name
      if (code === 'DCT') {
        let firstName = value.split(' ')[0];
        let middleName = value.split(' ')[1];

        // Only Replace if not found
        if (!props.firstName) {
          props.firstName = firstName;
        }

        // Only Replace if not found
        if (!props.middleName && middleName) {
          props.middleName = middleName;
        }
      }

      // DOES NOT COMPLY WITH AAMV STANDARDS (State: MS).  Manually Extract First, Middle Name and Last Name
      if (code === 'DAA') {
        let lastName = value.split(',')[0];
        let firstName = value.split(',')[1];
        let middleName = value.split(',')[2];

        // Only Replace if not found
        if (!props.firstName) {
          props.firstName = firstName;
        }

        // Only Replace if not found
        if (!props.middleName && middleName) {
          props.middleName = middleName;
        }

        // Only Replace if not found
        if (!props.lastName) {
          props.lastName = lastName;
        }
      }

      if (code === 'DBC') {
        if (value === '1' || value === 'M') {
          // MS Uses M / F rather than 1 / 2
          value = '2'; // Male Key
        } else if (value === '2' || value === 'F') {
          value = '1'; // Female Key
        }
      }

      // Convert Dates to Timestamps
      if (key && key.indexOf('date') === 0) {
        let parts = [value.slice(0, 2), value.slice(2, 4), value.slice(4)];
        const dob = new Date(parts.join('/'));
        // If this is a valid date, parse it otherwise set undefined
        value = dob.getTime() || undefined;
      }
      if (key) {
        props[key] = value;
      }
    }

    return props;
  }

  fakeScan = () => {
    this.onBarCodeRead({ data: SAMPLE_TX_DL_SCAN });
  };

  onBarCodeRead = e => {
    const { onComplete } = this.props;

    if (!this.completedScan) {
      this.completedScan = true;

      console.log('onBarCodeRead', e);

      const data = this.parseDL(e.data);
      // Send Data back to Parent Component
      onComplete(data);
    }
  };

  onGVBarCodeRead = barcodes => {
    const { onComplete } = this.props;

    if (!this.completedScan) {
      this.completedScan = true;

      console.log('onGVBarCodeRead', barcodes);

      const data = this.parseDL(barcodes);
      // Send Data back to Parent Component
      onComplete(data);
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleToggleTorch = () => {
    this.props.setTorch(!this.props.torch);
  };

  render() {
    const { onClose, hasCamera, torch, initilized } = this.props;

    if (!initilized) return null;
    const { torchOn } = this.state;

    // if (!hasCamera) {
    //   return (
    //     <View
    //       style={{
    //         flexDirection: 'column',
    //         flex: 1,
    //         justifyContent: 'center',
    //         alignItems: 'center'
    //       }}
    //     >
    //       <FormattedMessage
    //         id="error.noCamera"
    //         style={[
    //           Fonts.style.subtitle,
    //           { textAlign: 'center', margin: Metrics.doubleBaseMargin }
    //         ]}
    //       />
    //       <View style={{ marginTop: Metrics.doubleBaseMargin }} />
    //       <FullButton text={I18n.t('buttons.Close')} onPress={onClose} />
    //     </View>
    //   );
    // }

    const torchMode = torchOn
      ? RNCamera.Constants.FlashMode.on
      : RNCamera.Constants.FlashMode.off;

    return (
      <RNCamera
        ref={cam => {
          this.camera = cam;
        }}
        flashMode={torchMode}
        // captureTarget={Camera.constants.CaptureTarget.disk}
        barCodeTypes={[RNCamera.Constants.BarCodeType.pdf417]}
        onBarCodeRead={this.onBarCodeRead}
        // googleVisionBarcodeType={
        //   RNCamera.Constants.GoogleVisionBarcodeDetection.BarcodeType.PDF417
        // }
        // onGoogleVisionBarcodesDetected={this.onGVBarCodeRead}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
          // flex: 1,

          // justifyContent: 'center',
          // alignItems: 'center',

          // padding: Metrics.doubleBaseMargin
        }}
        // aspect={Camera.constants.Aspect.fill}
      >
        {({ camera, status }) => {
          if (status !== 'READY') return null;
          return (
            <View
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                flex: 1,

                justifyContent: 'center',
                alignItems: 'center',

                padding: Metrics.doubleBaseMargin
              }}
            >
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  top: Metrics.doubleBaseMargin,
                  right: Metrics.doubleBaseMargin
                }}
                onPress={this.handleClose}
              >
                <Icon
                  name="ios-close"
                  size={50}
                  style={{
                    color: 'white',
                    backgroundColor: Colors.transparent
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  top: Metrics.doubleBaseMargin + 60,
                  right: Metrics.doubleBaseMargin
                }}
                onPress={this.handleToggleTorch}
              >
                <Icon
                  name={torch ? 'ios-flash' : 'ios-flash-off'}
                  size={50}
                  style={{
                    color: 'white',
                    backgroundColor: Colors.transparent
                  }}
                />
              </TouchableOpacity>

              <View style={{ height: 170, flexDirection: 'row' }}>
                <View style={{ flexDirection: 'column', flex: 1 }}>
                  <View
                    style={{
                      flex: 0.2,
                      borderColor: '#FFF',
                      borderLeftWidth: 1,
                      borderTopWidth: 1
                    }}
                  />
                  <View style={{ flex: 1 }} />
                  <View
                    style={{
                      flex: 0.2,
                      borderColor: '#FFF',
                      borderLeftWidth: 1,
                      borderBottomWidth: 1
                    }}
                  />
                </View>
                <View style={{ flex: 1 }} />
                <View style={{ flexDirection: 'column', flex: 1 }}>
                  <View
                    style={{
                      flex: 0.2,
                      borderColor: '#FFF',
                      borderRightWidth: 1,
                      borderTopWidth: 1
                    }}
                  />
                  <View style={{ flex: 1 }} />
                  <View
                    style={{
                      flex: 0.2,
                      borderColor: '#FFF',
                      borderRightWidth: 1,
                      borderBottomWidth: 1
                    }}
                  />
                </View>
              </View>

              <View
                style={{
                  position: 'absolute',
                  bottom: Metrics.doubleBaseMargin * 2,
                  borderRadius: Metrics.buttonRadius,
                  backgroundColor: 'rgba(0,0,0,0.4)'
                }}
              >
                <Text
                  style={[
                    Fonts.style.normal,
                    {
                      padding: Metrics.baseMargin,
                      color: '#FFF'
                    }
                  ]}
                >
                  {I18n.t('barcodeInstructions')}
                </Text>
              </View>
            </View>
          );
        }}
      </RNCamera>
    );
  }
}

const mapStateToProps = ({
  app: { initilized, hasCamera },
  persist: { torch }
}) => ({
  initilized,
  hasCamera,
  torch
});

const mapDispatchToProps = dispatch => ({
  setTorch: torch => dispatch(PersistActions.PersistSetTorch(torch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanBarcode);
