import { put, select, call } from 'redux-saga/effects';
import { Platform } from 'react-native';
import { push } from 'connected-react-router';
import { I18n, loadTranslations, setLocale } from 'react-redux-i18n';
// import Permissions from 'react-native-permissions';

import { DEFAULT_LANGUAGE } from '../Config/ApplicationConfig';

import en from '../I18n/languages/en.json';
import es from '../I18n/languages/es.json';

import AppActions from '../Redux/AppRedux';
import { isKiosk } from '../Redux/AccountRedux';
import ApplicationActions from '../Redux/ApplicationRedux';
import OptionsActions from '../Redux/OptionsRedux';
import { getApplication } from './ApplicationSagas';
import { getDocuments } from './DocumentSagas';

import { history } from '../Redux/CreateStore';
import { persistCombineReducers } from 'redux-persist';

export const getPersist = state => state.persist;
export const getLocale = state => state.i18n.locale;

// process STARTUP actions
export function* startup(api) {
  const locale = yield select(getLocale);
  const persist = yield select(getPersist);

  const { isKiosk } = persist;

  console.log('locale', locale);

  // If Mobile, get Locale from Device
  const translationsObject = {
    en,
    es
  };

  yield put(loadTranslations(translationsObject));

  if (!locale) {
    yield put(setLocale(locale || DEFAULT_LANGUAGE));
    console.log('locale loaded', locale || DEFAULT_LANGUAGE);
  }

  if (persist.application && persist.application.id) {
    if (!isKiosk) {
      // Load Application
      yield call(getApplication, api, {
        applicationId: persist.application.id
      });
    }
    yield call(getDocuments, api, { docType: null });

    // TODO: Handle Errors
  }
  // if (Platform.OS !== 'web') {
  //   const cameraPermission = yield call(Permissions.request, 'camera');
  //   yield put(AppActions.AppSetHasCamera(cameraPermission === 'authorized'));
  // }

  // Done.  Tell the Application that it is initilized.
  console.log('set Initilized', Platform.OS);
  yield put(AppActions.AppSetInitilized(true));

  // Navigate to saved screen on mobile/kiosk if not home
  if (
    Platform.OS !== 'web' &&
    persist.screen &&
    persist.screen.pathname &&
    persist.screen.pathname !== '/'
  ) {
    console.log('INIT: navigating to ', persist.screen.pathname);
    // TODO: Set other screen attributes
    // history.replace(persist.screen.pathname);

    yield put(push(persist.screen.pathname));
  }

  // In the background, load options
  yield put(OptionsActions.OptionsLoadOptions());

  if (!isKiosk) {
    if (persist.application && persist.application.programCode) {
      yield put(
        OptionsActions.OptionsLoadDocTypes(persist.application.programCode)
      );
      yield put(
        OptionsActions.OptionsLoadScreeningQuestions(
          persist.application.programCode
        )
      );
    }
  }
}

// export function* rehydrated(api) {
//   const locale = yield select(getLocale);
//   const persist = yield select(getPersist);

//   // If Mobile, get Locale from Device
//   if (!locale) {
//     const translationsObject = {
//       en,
//       es
//     };
//     yield put(loadTranslations(translationsObject));
//     yield put(setLocale('en'));
//     console.log('locale loaded');
//   }

//   // if (Platform.OS !== 'web') {
//   //   const cameraPermission = yield call(Permissions.request, 'camera');
//   //   yield put(AppActions.AppSetHasCamera(cameraPermission === 'authorized'));
//   // }

//   // Navigate to saved screen on mobile/kiosk if not home
//   if (
//     Platform.OS !== 'web' &&
//     persist.screen &&
//     persist.screen.pathname &&
//     persist.screen.pathname !== '/'
//   ) {
//     console.log('INIT: navigating to ', persist.screen.pathname);
//     // TODO: Set other screen attributes
//     // history.replace(persist.screen.pathname);
//   }

//   // Done.  Tell the Application that it is initilized.
//   console.log('set Initilized', persist);
//   yield put(AppActions.AppSetInitilized(true));
// }
