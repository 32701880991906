import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import ContactInfo from './ContactInfo';

// Styles
import styles from './Styles/ScreenStyles';
import { RENTAL_PROGRAM_CODE } from '../Config/ApplicationConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';

const BASE = DATA_STRUCTURE.tenants;

class TenantInformationScreen extends Component {
  onComplete = data => {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;

    history.push('/TenantsScreen');
  };

  render() {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;

    return (
      <View style={styles.mainContainer}>
        <ContactInfo base={BASE} index={index} onComplete={this.onComplete} />
      </View>
    );
  }
}

const mapStateToProps = ({ persist: { application } }) => ({
  application
});

export default connect(mapStateToProps)(TenantInformationScreen);
