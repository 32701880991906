import React, { Component } from 'react';
import { Platform, Text, View, StatusBar, SafeAreaView } from 'react-native';
import { connect } from 'react-redux';

import Navigation from '../Navigation';
import LaunchScreen from '../Containers/LaunchScreen';
import StartupActions from '../Redux/StartupRedux';
import ReduxPersist from '../Config/ReduxPersist';

// Styles
import styles from './Styles/RootContainerStyles';

// let Router, Route, Link;
// if (Platform.OS === 'web') {
//   // This is a variable so that React Native Doesn't try and import
//   var WebRouterModule = 'react-router-dom';
//   const WebRouter = require('react-router-dom');
//   Router = WebRouter.BrowserRouter;
//   Route = WebRouter.Route;
//   Link = WebRouter.Link;
// } else {
//   const RMRouterModule = 'react-router-native';
//   RNRouter = require('react-router-native');
//   Router = RNRouter.NativeRouter;
//   Route = RNRouter.Route;
//   Link = RNRouter.Link;
// }
// Web
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
// RN
// import { NativeRouter as Router, Route, Link } from 'react-router-native';

const Home = () => <Text style={styles.header}>Home</Text>;

class RootContainer extends Component {
  componentDidMount() {
    // if redux persist is not active fire startup action
    if (!ReduxPersist.active) {
      this.props.startup();
    }
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    const { initilized, history } = this.props;
    if (!initilized) return null;
    return (
      <View style={styles.applicationView}>
        <StatusBar barStyle="light-content" />
        <Navigation history={history} />
      </View>
    );
  }
}

const mapStateToProps = ({ app: { initilized } }) => ({
  initilized
});

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = dispatch => ({
  startup: () => dispatch(StartupActions.startup())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainer);
