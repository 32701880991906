import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import styles from './Styles/FormStyles';
import ExamplesRegistry from '../Services/ExamplesRegistry';

// Note that this file (App/Components/ErrorMessage) needs to be
// imported in your app somewhere, otherwise your component won't be
// compiled and added to the examples dev screen.

// Ignore in coverage report
/* istanbul ignore next */
ExamplesRegistry.addComponentExample('Full Button', () => (
  <ErrorMessage errorText="Error Text" />
));

export default class ErrorMessage extends Component {
  static propTypes = {
    errorText: PropTypes.string,
    onDismiss: PropTypes.func
  };
  static defaultProps = {
    errorText: null,
    onDismiss: () => {}
  };

  render() {
    const { errorText } = this.props;

    return (
      !!errorText && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{errorText}</Text>
        </View>
      )
    );
  }
}
