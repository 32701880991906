import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';

import ScanID from '../Components/ScanID';
import { getPersonId, hasDocument } from '../Lib/Utils';

// Styles
import styles from './Styles/ScreenStyles';
import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const BASE = DATA_STRUCTURE.applicant;

export const ApplicantIdentificationScreenComplete = (
  application,
  documents
) => {
  const applicantPersonId = getPersonId(application, BASE);
  return hasDocument(
    documents,
    applicantPersonId,
    DOCUMENT_TYPES.proofOfIdentification.code
  );
};

class ApplicantIdentificationScreen extends Component {
  onComplete = data => {
    const { history } = this.props;
    history.push('ApplicantInformationScreen');
  };

  render() {
    return (
      <View style={styles.mainContainer}>
        <ScanID
          base={BASE}
          onComplete={this.onComplete}
          onCancel={this.onComplete}
        />
      </View>
    );
  }
}

const mapStateToProps = ({
  application: { application },
  app: { initilized }
}) => ({
  application,
  initilized
});

export default connect(mapStateToProps)(ApplicantIdentificationScreen);
