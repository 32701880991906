import { API_VERSION } from './ApplicationConfig';

const DATA_STRUCTURE = {
  application: 'application',
  applicant: 'applicant',
  contact: 'contact',
  coApplicant: 'coApplicant',
  damagedAddress: 'damagedAddress',
  landOwner: 'landOwner',
  powerOfAttorney: 'powerOfAttorney',
  communicationsDesignee: 'communicationsDesignee',
  household_members: API_VERSION > 1 ? 'householdMembers' : 'household_members',
  householdMembers: API_VERSION > 1 ? 'householdMembers' : 'household_members',
  tenants: 'tenants',
  lien_holders: 'lienHolder',
  insurance: 'insurance',
  additionalFunds: 'additionalFunds'
};
export const DELETE_STRUCTURE = {
  coApplicant: 'Co-Applicant',
  landOwner: 'Land Owner',
  powerOfAttorney: 'Power of Attorney',
  communicationsDesignee: 'Communication Designee',
  householdMembers: 'Household Member',
  household_members: 'Household Member',
  tenants: 'Tenant',
  lienHolder: 'Lien Holder',
  insurance: 'Insurance',
  additionalFunds: 'Additional Funds',
  document: 'Document'
};

export default DATA_STRUCTURE;
