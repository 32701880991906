import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import RNFS from 'react-native-fs';
import base64 from 'base-64';
// import Permissions from 'react-native-permissions';
import moment from 'moment';

import * as R from 'ramda';
import { all, call, put, select } from 'redux-saga/effects';
import ApplicationActions from '../Redux/ApplicationRedux';
import AccountActions, { isKiosk } from '../Redux/AccountRedux';
import PersistActions from '../Redux/PersistRedux';
import AppActions from '../Redux/AppRedux';

// import { getApplication } from './ApplicationSagas';
// import { getDocuments } from './DocumentSagas';

// import { getOptions, getDocTypes } from './OptionsSagas';

import { isNumeric } from '../Lib/Utils';

import {
  ORGIN_CODE_WEB,
  ORGIN_CODE_KIOSK,
  ORGIN_CODE_MOBILE,
  STATUS_SUBMITTED,
  STATUS_IN_PROGRESS
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;

const LAND_OWNER_BASE = DATA_STRUCTURE.landOwner;
const POA_BASE = DATA_STRUCTURE.powerOfAttorney;
const COMMUNICATIONS_DESIGNEE_BASE = DATA_STRUCTURE.communicationsDesignee;

const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;
const TENANTS_BASE = DATA_STRUCTURE.tenants;
const LIEN_HOLDERS_BASE = DATA_STRUCTURE.lien_holders;
const INSURANCE_BASE = DATA_STRUCTURE.insurance;
const ADDITIONAL_FUNDS_BASE = DATA_STRUCTURE.additionalFunds;
const DAMAGED_ADDRESS_BASE = DATA_STRUCTURE.damagedAddress;

export const getNetwork = state => state.network;
export const getPersist = state => state.persist;
export const getAccount = state => state.account;
export const getAccountToken = state => state.account.token;
export const getOfflineDocuments = state => state.application.offlineDocuments;
export const getApplicationsState = state => state.application.applications;
export const getApplicationState = state => state.application.application;
export const getLocale = state => state.i18n.locale;
export const getLocation = state => state.router.location;

/**
 * Save Location on kiosk/mobile each page change
 */
export function* locationChange() {
  if (Platform.OS !== 'web') {
    const location = yield select(getLocation);
    yield put(PersistActions.PersistSetScreen(location));
  }
}

// export function* rehydrated(api) {
//   const persist = yield select(getPersist);
//   console.log('rehydrating', persist);
//   const token = persist.token;
//   const applicationId = persist.applicationId;

//   // 'authorized', 'denied'
//   const cameraPermission = yield call(Permissions.request, 'camera');
//   yield put(AppActions.AppSetHasCamera(cameraPermission === 'authorized'));

//   // If we are logged in and have a current application, reload it.
//   if (token && applicationId && isNumeric(applicationId)) {
//     console.log(`loading application ${applicationId}`);
//     // Load Application and Documents in parallel
//     yield all([
//       call(getApplication, api, { applicationId }),
//       call(getDocuments, api, { docType: null })
//     ]);

//     // TODO: Load DocTypes
//     // const loadedApplication = yield select(getPersist).application;
//     // if (loadedApplication.programCode) {

//     // }
//   } else if (applicationId) {
//     // Not Logged in, but most likely have an offline document.
//     yield call(getDocuments, api, { docType: null });
//   }

//   // const network = yield select(getNetwork);
//   // if (network.fetching === false && network.status === true) {
//   // We are online, load pickers and doctypes
//   console.log('loading options');
//   yield call(getOptions, api);
//   yield call(getDocTypes, api);
//   // } else {
//   //   console.log('offline, not loading options');
//   // }

//   yield put(AppActions.AppSetInitilized(true));
// }

export const getSyncProgress = (application, documents) => {
  let progress = 0;
  let progressMax = 2; // Application Uploaded + Application Complete
  if (application.id.substring(0, 3) !== 'OFF') {
    progress += 1;
    if (application.status === STATUS_SUBMITTED) progress += 1;
  }
  documents &&
    documents.forEach(document => {
      // Make sure PersonID is valid
      let personId = document.personId;
      if (!isNumeric(document.personId)) {
        const [base, index] = document.personId.split('_');
        if (index && index >= 0) {
          personId = R.pathOr(null, [base, index, 'id'], application);
        } else {
          personId = R.pathOr(null, [base, 'id'], application);
        }
      }

      if (personId) {
        if (document.uploaded) {
          progress += 1;
        }
        progressMax += 1;
      }
    });
  return {
    progress,
    progressMax
  };
};

export function* applicationSync(api, params) {
  const log = {
    applicationId: '',
    timestamp: moment().format('YYYY-MM-DD HH:mm:ss ZZ'),
    device: {
      manufacturer: DeviceInfo.getManufacturer(),
      brand: DeviceInfo.getBrand(),
      model: DeviceInfo.getModel(),
      systemName: DeviceInfo.getSystemName(),
      systemVersion: DeviceInfo.getSystemVersion(),
      bundleId: DeviceInfo.getBundleId(),
      version: DeviceInfo.getVersion(),
      buildNumber: DeviceInfo.getBuildNumber(),
      isTablet: DeviceInfo.isTablet()
    },
    initialApplication: '',
    log: [],
    result: {}
  };

  const status = {
    applicationId: '',
    log: '',
    status: '',
    progress: 0,
    progressMax: 1
  };

  const { applicationKey, submitted } = params;
  console.log('calling applicationSync applicationKey', applicationKey);
  const {
    applications,
    offlineDocuments,
    account,
    tokenId,
    token,
    isKiosk
  } = yield select(getPersist);

  const locale = yield select(getLocale) || 'en';
  const userId = account && account.userId;

  try {
    let results;

    let newApplication = JSON.parse(
      JSON.stringify(applications[applicationKey])
    );
    let newDocuments = offlineDocuments[applicationKey]
      ? JSON.parse(JSON.stringify(offlineDocuments[applicationKey]))
      : [];

    console.log(
      'Starting Application',
      JSON.stringify(newApplication, null, 2)
    );

    log.applicationId = applicationKey;
    log.initialApplication = newApplication;

    status.applicationId = applicationKey;
    status.status = `Starting Sync for ${applicationKey}`;
    status.log = `${status.status}\n`;
    yield put(AppActions.AppSetStatus(status));

    if (newApplication.id.substring(0, 3) === 'OFF') {
      // Step 1. Get a New Application ID
      newApplication.id = '-1';
    }

    // Start application out as in progess until upload is complete
    newApplication.status = STATUS_IN_PROGRESS;

    newApplication.originCode =
      Platform.OS === 'web' ? ORGIN_CODE_WEB : ORGIN_CODE_MOBILE;

    const fieldsRequiringId = [
      APPLICANT_BASE,
      CO_APPLICANT_BASE,
      DAMAGED_ADDRESS_BASE,
      LAND_OWNER_BASE,
      POA_BASE,
      COMMUNICATIONS_DESIGNEE_BASE
    ];

    fieldsRequiringId.forEach(ele => {
      if (newApplication[ele] && !newApplication[ele].id) {
        newApplication[ele].id = '-1';
      }
    });

    const subfieldsRequiringId = [
      HOUSEHOLD_MEMBERS_BASE,
      LIEN_HOLDERS_BASE,
      ADDITIONAL_FUNDS_BASE,
      TENANTS_BASE,
      COMMUNICATIONS_DESIGNEE_BASE
    ];
    subfieldsRequiringId.forEach(base => {
      if (
        newApplication[base] &&
        typeof newApplication[base].forEach === 'function'
      ) {
        newApplication[base].forEach((ele, idx) => {
          if (!newApplication[base][idx].id) {
            newApplication[base][idx].id = '-1';
          }
        });
      }
    });

    if (
      newApplication[DAMAGED_ADDRESS_BASE] &&
      newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE]
    ) {
      if (
        newApplication[DAMAGED_ADDRESS_BASE] &&
        typeof newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE].forEach ===
          'function'
      ) {
        newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE].forEach(
          (ele, idx) => {
            if (!newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx].id) {
              newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx].id =
                '-1';
            }
            // Remove Non Digits from Amounts
            if (
              newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][idx]
                .amountReceived
            ) {
              newApplication[DAMAGED_ADDRESS_BASE][INSURANCE_BASE][
                idx
              ].amountReceived = newApplication[DAMAGED_ADDRESS_BASE][
                INSURANCE_BASE
              ][idx].amountReceived.replace(/[^\d.-]/g, '');
            }
          }
        );
      }
    }

    status.status = 'Creating/Updating Application';
    status.log += `${status.status}\n`;

    if (newApplication.id.substring(0, 3) === 'OFF') {
      console.log(
        'Creating Application',
        userId,
        tokenId,
        token,
        JSON.stringify(newApplication, null, 2)
      );
      log.log.push({
        action: 'createApplication',
        api: 'api.createApplication',
        payload: newApplication
      });
      yield put(AppActions.AppSetLog(log));

      results = yield call(
        api.createApplication,
        userId,
        tokenId,
        token,
        newApplication
      );
    } else {
      console.log(
        'Updating Application',
        userId,
        tokenId,
        token,
        JSON.stringify(newApplication, null, 2)
      );
      log.log.push({
        action: 'updateApplication',
        api: 'api.updateApplication',
        payload: newApplication
      });
      yield put(AppActions.AppSetLog(log));

      results = yield call(
        api.updateApplication,
        userId,
        tokenId,
        token,
        newApplication
      );
    }
    console.log('results', results, null, 2);

    log.log.push({
      action:
        newApplication.id.substring(0, 3) === 'OFF'
          ? 'createApplication Results'
          : 'updateApplication Results',
      api:
        newApplication.id.substring(0, 3) === 'OFF'
          ? 'api.createApplication'
          : 'api.updateApplication',
      results
    });
    yield put(AppActions.AppSetLog(log));

    // Verify Success
    if (results.status !== 200) {
      throw new Error(`${results.status}: ${results.problem}`);
    }

    if (results.data.success !== 'true') {
      throw new Error(
        R.pathOr('Unknown error', ['data', 'error', 0, 'message'], results)
      );
    }
    // Verify ApplicationID
    if (!R.pathOr(null, ['data', 'payload', 0, 'applicationId'], results)) {
      throw new Error('applicationId not received');
    }

    console.log(
      'api.getApplication',
      userId,
      tokenId,
      token,
      results.data.payload[0].applicationId
    );

    log.log.push({
      action: 'getApplication',
      api: 'api.getApplication',
      payload: {
        userId,
        tokenId,
        token,
        applicationId: results.data.payload[0].applicationId
      }
    });
    yield put(AppActions.AppSetLog(log));

    // Retreive Updated Application
    results = yield call(
      api.getApplication,
      userId,
      tokenId,
      token,
      results.data.payload[0].applicationId
    );

    log.log.push({
      action: 'getApplication Results',
      api: 'api.getApplication',
      results
    });
    yield put(AppActions.AppSetLog(log));

    // Verify ApplicationID
    if (!R.pathOr(null, ['data', 'payload', 0, 'id'], results)) {
      throw new Error('applicationId not received');
    }
    newApplication = results.data.payload[0];

    console.log(
      'TCL: exportfunction*applicationSync -> newApplication',
      JSON.stringify(newApplication, null, 2)
    );

    yield put(
      PersistActions.PersistSetApplications(
        applications.set(applicationKey, newApplication)
      )
    );
    yield put(AppActions.AppSetStatus(status));

    console.log('Setting up uploads');

    // This will contain form data
    let uploadDocuments = [];

    let data = null;
    if (newDocuments && newDocuments.length > 0) {
      console.log('offline documents exist for app', applicationKey);
      for (let i = 0; i < newDocuments.length; i++) {
        const doc = newDocuments[i];
        const ext = doc.fileDownloadURL
          .split('.')
          .pop()
          .toLowerCase();
        const mimeType =
          ext === 'jpg'
            ? 'image/jpeg'
            : ext === 'jpeg'
            ? 'image/jpeg'
            : ext === 'png'
            ? 'image/png'
            : null;

        let personId = doc.personId;
        if (!isNumeric(doc.personId)) {
          const [base, index] = doc.personId.split('_');
          if (index && index >= 0) {
            personId = R.pathOr(null, [base, index, 'id'], newApplication);
          } else {
            personId = R.pathOr(null, [base, 'id'], newApplication);
          }
        }
        console.log(doc.personId, personId);
        if (personId) {
          data = new FormData();
          data.append('PersonID', personId);
          data.append('DocumentTypeCode', doc.documentType);
          data.append('KeywordValueCode', doc.keywordValue || ''); // is optional
          data.append('KeywordSortOrder', doc.pageOrder);
          data.append('PageOrder', doc.pageOrder);

          data.append('File', {
            uri:
              Platform.OS === 'android' &&
              doc.fileDownloadURL.indexOf('/') === 0
                ? `file://${doc.fileDownloadURL}`
                : doc.fileDownloadURL,
            type: mimeType,
            name: doc.fileName
          });
          uploadDocuments.push(data);
        } else {
          // Invalid PersonID
          console.log(
            `Ignore Document: Couldn't load personId ${
              doc.personId
            } for documentType ${doc.documentType}`
          );
          log.log.push({
            action: 'uploadDocument',
            api: 'api.uploadDocument',
            payload: {
              id: doc.id,
              personId: doc.personId,
              documentType: doc.documentType,
              keywordValue: doc.keywordValue
            },
            results: `Couldn't load personId ${doc.personId} for documentType ${
              doc.documentType
            }`
          });
          yield put(AppActions.AppSetLog(log));
        }
      }
    }

    // Upload Documents
    status.progressMax = uploadDocuments.length;

    console.log('starting uploads');
    let needToUpload = 0;
    for (let i = 0; i < uploadDocuments.length; i++) {
      if (!newDocuments[i].uploaded) {
        needToUpload++;
      }
    }

    log.log.push({
      log: `Found ${
        uploadDocuments.length
      } total documents.  Need to upload ${needToUpload} documents.`
    });
    yield put(AppActions.AppSetLog(log));

    for (let i = 0; i < uploadDocuments.length; i++) {
      if (!newDocuments[i].uploaded) {
        const exists = yield call(RNFS.exists, newDocuments[i].fileDownloadURL);
        if (exists) {
          data = uploadDocuments[i];
          status.status = `Uploading ${i + 1} of ${
            status.progressMax
          } documents`;
          status.log += `${status.status}\n`;
          status.progress = i;
          yield put(AppActions.AppSetStatus(status));

          const payload = {
            userId,
            tokenId,
            token,
            applicationId: newApplication.id,
            data
          };

          console.log(status.status, JSON.stringify(payload, null, 2));

          log.log.push({
            action: 'uploadDocument',
            api: 'api.uploadDocuments',
            payload: data
          });
          yield put(AppActions.AppSetLog(log));

          results = yield call(
            api.uploadDocuments,
            userId,
            tokenId,
            token,
            newApplication.id,
            null,
            data
          );
          console.log(
            'uploadDocument results',
            JSON.stringify(results, null, 2)
          );

          // Save call to log
          log.log.push({
            action: 'uploadDocument Results',
            api: 'api.uploadDocuments',
            results
          });
          yield put(AppActions.AppSetLog(log));

          if (
            !results.ok ||
            R.pathOr(
              'N/A',
              ['data', 'payload', 0, 'documents', 0, 'id'],
              results
            ) === 'N/A'
          ) {
            status.status = `Failed to upload document ${results.status}: ${
              results.problem
            }`;
            status.log += `${status.status}\n`;
            status.progress = i + 1;
            yield put(AppActions.AppSetStatus(status));
            throw new Error(status.status);
          } else if (R.path(['data', 'error', 0, 'message'], results)) {
            status.status = `Failed to upload document ${
              results.data.error[0].message
            }`;
            status.log += `${status.status}\n`;
            status.progress = i + 1;
            yield put(AppActions.AppSetStatus(status));
            throw new Error(status.status);
          } else if (
            R.pathOr(
              'N/A',
              ['data', 'payload', 0, 'documents', 0, 'id'],
              results
            ) === 'N/A'
          ) {
            status.status = 'Failed to upload document';
            status.log += `${status.status}\n`;
            status.progress = i + 1;
            yield put(AppActions.AppSetStatus(status));
            throw new Error(status.status);
          }

          log.log.push({
            log: `Successful Upload`
          });
          yield put(AppActions.AppSetLog(log));

          status.status = 'Successful Upload';
          status.log += `${status.status}\n`;
          status.progress = i + 1;
          yield put(AppActions.AppSetStatus(status));

          // Set Document Uploaded
          newDocuments[i].uploaded = true;

          // Update Persistant Store
          yield put(
            PersistActions.PersistSetOfflineDocuments(
              offlineDocuments.set(applicationKey, newDocuments)
            )
          );

          // Delete Image File
          if (exists) {
            log.log.push({
              log: `Deleting File ${
                newDocuments[i].fileDownloadURL
              } from local storage`
            });
            yield put(AppActions.AppSetLog(log));
            yield call(RNFS.unlink, newDocuments[i].fileDownloadURL);
            log.log.push({
              log: `Deleted File ${
                newDocuments[i].fileDownloadURL
              } from local storage`
            });
            yield put(AppActions.AppSetLog(log));
          }
        } else {
          log.log.push({
            log: `Skipped ${
              newDocuments[i].fileDownloadURL
            } since it does not exist`
          });
          yield put(AppActions.AppSetLog(log));
        }
      }
    }

    // Finally Update Remote Application with submitted
    newApplication.status = STATUS_SUBMITTED;

    console.log(
      'Updating Application',
      userId,
      tokenId,
      token,
      JSON.stringify(newApplication, null, 2)
    );
    log.log.push({
      action: 'Setting Application to Submitted',
      api: 'api.updateApplication',
      payload: newApplication
    });
    yield put(AppActions.AppSetLog(log));

    results = yield call(
      api.updateApplication,
      userId,
      tokenId,
      token,
      newApplication
    );
    console.log('results', results, null, 2);

    log.log.push({
      action: 'updateApplication',
      api: 'api.updateApplication',
      results
    });
    yield put(AppActions.AppSetLog(log));

    // Verify Success
    if (results.status !== 200) {
      throw new Error(`${results.status}: ${results.problem}`);
    }

    if (results.data.success !== 'true') {
      throw new Error(
        R.pathOr('Unknown error', ['data', 'error', 0, 'message'], results)
      );
    }
    // Verify ApplicationID
    if (!R.pathOr(null, ['data', 'payload', 0, 'applicationId'], results)) {
      throw new Error('applicationId not received');
    }

    if (applicationKey.substring(0, 3) === 'OFF') {
      log.log.push({
        log: `Deleting ${applicationKey} from device`
      });
      yield put(AppActions.AppSetLog(log));

      // Remove OffLine Application from Store
      yield put(
        PersistActions.PersistSetApplications(
          applications.without(applicationKey)
        )
      );

      // Remove Offline Documents from Store
      if (offlineDocuments) {
        yield put(
          PersistActions.PersistSetOfflineDocuments(
            offlineDocuments.without(applicationKey)
          )
        );
      }
    }

    yield put(ApplicationActions.ApplicationSuccess({ success: true }));

    status.status = 'Complete';
    status.log += `${status.status}\n`;
    status.progress = status.progressMax;

    console.log('Complete', JSON.stringify(status, null, 2));
    yield put(AppActions.AppSetStatus(status));

    log.log.push({
      log: `Complete Application Upload`
    });
    log.result = newApplication;
    yield put(AppActions.AppSetLog(log));
  } catch (error) {
    status.status = `Error: ${error.message}` || error;
    status.log += `${error.message || error}\n`;
    yield put(AppActions.AppSetStatus(status));

    log.log.push({ action: 'ERROR', results: error.message || error });
    log.result = { error };
    yield put(AppActions.AppSetLog(log));
    yield put(ApplicationActions.ApplicationFailure(error.message || error));
  }

  try {
    console.log('sendLogs', status.status);

    const results = yield call(api.sendLog, userId, tokenId, token, log);
    console.log('results', results);
    // fetch('https://nc-recovery-app-dev.firebaseio.com/logs.json', {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(log)
    // })
  } catch (error) {
    // Fail Silently
    console.log('sendLogs error', error.message || error);
  }
}
