import React, { Component } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment';
import ContactInfo from './ContactInfo';

// Styles
import styles from './Styles/ScreenStyles';
import { RENTAL_PROGRAM_CODE } from '../Config/ApplicationConfig';
import DATA_STRUCTURE from '../Config/DataStructureConfig';

const BASE = DATA_STRUCTURE.household_members;

class HouseholdMemberInformationScreen extends Component {
  onComplete = data => {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;

    const member = data[BASE][index] || {};

    const dateOfBirth = moment(member.dateOfBirth, 'M/D/YYYY');
    const age = member.dateOfBirth ? moment().diff(dateOfBirth, 'years') : 0;

    console.log(data, member, age);

    // If Household Member is 18 or older, go to income else go to Household Members List
    if (age >= 18) {
      history.push(`/HouseholdMember/${index}/Income`);
    } else {
      history.push('/HouseholdMembersScreen');
    }

    // if (data.programCode.toString() === RENTAL_PROGRAM_CODE.toString()) {
    //   history.push('TenantsScreen');
    // } else {
    //   history.push('CoapplicantIncomeScreen');
    // }
  };

  render() {
    const {
      history,
      match: {
        params: { index }
      }
    } = this.props;

    return (
      <View style={styles.mainContainer}>
        <ContactInfo base={BASE} index={index} onComplete={this.onComplete} />
      </View>
    );
  }
}

const mapStateToProps = ({ persist: { application } }) => ({
  application
});

export default connect(mapStateToProps)(HouseholdMemberInformationScreen);
