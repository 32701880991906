import React, { Component } from 'react';
import * as R from 'ramda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withFormik, Field } from 'formik';
import * as yup from 'yup';
import { View, Text, Platform } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Icon from 'react-native-vector-icons/dist/Ionicons';

import { isKiosk } from '../Redux/AccountRedux';
import ApplicationActions from '../Redux/ApplicationRedux';
import DocumentActions from '../Redux/DocumentRedux';

import { Grid, Row, Col } from '../Components/Grid';
import FormattedMessage from '../Components/FormattedMessage';
import FormGroupText from '../Components/FormGroupText';
import FormGroupCheckbox from '../Components/FormGroupCheckbox';
// import FormGroupSelect from '../Components/FormGroupSelect';
import FormGroupRadio from '../Components/FormGroupRadio';
import FullButton from '../Components/FullButton';
import CaptureUploadDocuments from '../Components/CaptureUploadDocuments';

import {
  isRequired,
  isEmail,
  isRequiredEmail,
  isMonth,
  isDay,
  isPastYear,
  isSsnNumber,
  ssnFormat,
  isPhoneNumber,
  phoneFormat,
  isZipcode,
  getPersonId,
  getBase,
  hasDocument,
  personHasIncome,
  getBaseString
} from '../Lib/Utils';

// Styles
import { Fonts, Metrics, Colors } from '../Themes';
import styles from './Styles/ScreenStyles';

import {
  DEFAULT_STATE_CODE,
  RENTAL_PROGRAM_CODE,
  YES_OPTION
} from '../Config/ApplicationConfig';

import DATA_STRUCTURE from '../Config/DataStructureConfig';
import DOCUMENT_TYPES from '../Config/DocumentTypesConfig';

const APPLICANT_BASE = DATA_STRUCTURE.applicant;
const CO_APPLICANT_BASE = DATA_STRUCTURE.coApplicant;
const CONTACT_BASE = DATA_STRUCTURE.contact;
const HOUSEHOLD_MEMBERS_BASE = DATA_STRUCTURE.household_members;

const DOCUMENT_TYPE = DOCUMENT_TYPES.proofOfIdentification.code;
const DRIVERS_LICENSE_KEYWORD_VALUE =
  DOCUMENT_TYPES.proofOfIdentification.keywords.DriversLicense;

const BASE = DATA_STRUCTURE.household_members;

export const HouseholdMemberIncomeScreenComplete = (application, documents) => {
  return personHasIncome(application, documents, BASE);
};

class HouseholdMemberIncomeScreen extends Component {
  state = {
    errorText: ''
  };

  componentWillReceiveProps(nextProps) {
    const {
      data,
      errorText,
      fetching,
      application,
      initilized,
      onComplete,
      currentForm,
      submitCount,
      isSubmitting,
      status,
      setStatus,
      setSubmitting,
      history
    } = nextProps;

    // if (initilized && R.path(['values'], currentForm) && !this.formInitilized) {
    //   this.formInitilized = true;
    //   this.initilizeForm(nextProps);
    // }
    // console.log('status', status);
    // console.log('isSubmitting', isSubmitting);
    // console.log('fetching', fetching);

    if (isSubmitting && status === 'updatingApplication' && !fetching) {
      setStatus('');
      setSubmitting(false);
      if (errorText) {
        this.setState({ errorText });
      } else {
        history.push('TaxReturn');
      }
    } else if (
      submitCount > 0 &&
      submitCount !== this.props.submitCount &&
      !this.props.isValid
    ) {
      this.setState({ errorText: errorText || I18n.t('error.formError') });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.submitCount > 0 &&
      prevProps.submitCount !== this.props.submitCount &&
      !this.props.isValid
    ) {
      console.log(
        'this.props.submitCount',
        prevProps.submitCount,
        this.props.submitCount
      );
      if (Platform.OS === 'web') {
        if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0 });
      } else {
        setTimeout(() => {
          if (this._scroll) this._scroll.scrollTo({ x: 0, y: 0 });
        }, 300);
      }
    }
  }

  render() {
    const {
      options,
      errors,
      base,
      application,
      initilized,
      handleSubmit,
      documents,
      isValid,

      fetching,
      values,
      match: {
        params: { index }
      }
    } = this.props;

    const appBase = getBase(values, BASE, index);
    const baseString = getBaseString(BASE, index);

    const receiveIncome = appBase.doYouReceiveIncomeCode === YES_OPTION;
    // console.log('receiveIncome', receiveIncome);
    const { errorText } = this.state;

    const errorView = errorText && (
      <View style={styles.errorContainer}>
        <FormattedMessage id="error.submissionError" />
        <Text style={styles.errorText}>{errorText}</Text>
      </View>
    );

    return (
      <KeyboardAwareScrollView
        style={styles.scrollViewContainer}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Grid>
          <Row>
            <Col>
              <FormattedMessage
                id={`${BASE}.incomeTitle`}
                style={Fonts.style.title}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <FormGroupRadio
                field={`${baseString}.doYouReceiveIncomeCode`}
                labelId={`Income.doYouReceiveIncomeCode`}
                optionsKey="yes_no"
                reverse
                required
                validate={isRequired}
                disabled={fetching}
              />
            </Col>
          </Row>

          {receiveIncome && (
            <View>
              <Row>
                <Col>
                  <FormattedMessage
                    id="Income.selectSourceOfIncome"
                    style={Fonts.style.subtitle}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupCheckbox
                    // key={`${baseString}.receiveIncomeWages`}
                    field={`${baseString}.receiveIncomeWages`}
                    labelId="Income.receiveIncomeWages"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupCheckbox
                    // key={`${baseString}.receiveIncomeDisability`}
                    field={`${baseString}.receiveIncomeDisability`}
                    labelId="Income.receiveIncomeDisability"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupCheckbox
                    // key={`${baseString}.receiveIncomeSocialSecurity`}
                    field={`${baseString}.receiveIncomeSocialSecurity`}
                    labelId="Income.receiveIncomeSocialSecurity"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroupCheckbox
                    // key={`${baseString}.receiveIncomePension`}
                    field={`${baseString}.receiveIncomePension`}
                    labelId="Income.receiveIncomePension"
                  />
                </Col>
              </Row>
            </View>
          )}

          <Row>
            <Col sm={12}>
              <FullButton
                text={I18n.t('form.continueNextStep')}
                onPress={() => {
                  // this.props.updateApplication(this.props.application);
                  // console.log('handleSubmit');
                  handleSubmit();
                }}
                disabled={!isValid || fetching}
              />
              <View style={{ marginTop: Metrics.doubleBaseMargin }} />
            </Col>
          </Row>
        </Grid>
      </KeyboardAwareScrollView>
    );
  }
}

HouseholdMemberIncomeScreen = withFormik({
  mapPropsToValues: props => {
    return props.application;
  },
  isInitialValid: props => props.application[BASE],
  // validationSchema,
  // Submission handler
  handleSubmit: (values, { setStatus, props }) => {
    const {
      base,
      index,
      history,
      application,
      updateApplication,
      deleteRecord
    } = props;
    // console.log('handleSubmit', JSON.stringify(values, null, 2));
    // const newValues = Object.assign({}, values);

    // console.log('updatingApplication', JSON.stringify(values, null, 2));
    updateApplication(values);
    setStatus('updatingApplication');
  }
})(HouseholdMemberIncomeScreen);

const mapStateToProps = ({
  app: { initilized },
  application: { fetching, error, data },
  persist: { options, application, account, token, tokenId }
}) => ({
  options,
  account,
  token,
  tokenId,
  errorText: error,
  fetching,
  data,
  initilized,
  application
});

const mapDispatchToProps = dispatch => ({
  updateApplication: application =>
    dispatch(ApplicationActions.ApplicationUpdate(application)),
  deleteRecord: memberId =>
    dispatch(ApplicationActions.ApplicationDeleteRecord(BASE, memberId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdMemberIncomeScreen);
