import { Platform, Image } from 'react-native';
import { call, put, select } from 'redux-saga/effects';
import * as R from 'ramda';

import uuid from 'uuid/v1';
import RNFS from 'react-native-fs';
import RNFetchBlob from 'react-native-fetch-blob';
import ImageResizer from 'react-native-image-resizer';
import { Upload } from 'react-native-tus-client';
import base64 from 'base-64';

import DocumentActions, { DocumentTypes } from '../Redux/DocumentRedux';
import { getDocumentURL } from '../Redux/DocumentRedux';
import ApplicationActions from '../Redux/ApplicationRedux';
import PersistActions from '../Redux/PersistRedux';
import {
  IMAGE_RESIZE_MAX,
  IMAGE_RESIZE_QUALITY
} from '../Config/ApplicationConfig';

function downloadFileFromUrlToPath(url, path) {
  return RNFetchBlob.config({ path }).fetch('GET', url, {
    'Cache-Control': 'no-store'
  });
}

const getImageSize = imageUri =>
  new Promise((resolve, reject) => {
    Image.getSize(
      imageUri,
      (width, height) => {
        console.log('TCL: results', { width, height });
        resolve({ width, height });
      },
      error => {
        reject(error);
      }
    );
  });

const uploadTus = absoluteFilePath =>
  new Promise((resolve, reject) => {
    const upload = new Upload(absoluteFilePath, {
      endpoint: 'http://192.168.1.170:1080/files/', // use your tus server endpoint instead
      onError: error => {
        console.log('uploadTus error', error);
        reject(error);
      },
      onSuccess: () => {
        console.log('uploadTus success', upload.url);
        resolve(upload.url);
      }
      // onProgress: (uploaded, total) =>
      // console.log(`Progress: ${((uploaded / total) * 100) | 0}%`)
    });
    console.log(
      'starting upload http://192.168.1.140:1080/files/',
      absoluteFilePath
    );
    upload.start();
  });

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getPersist = state => state.persist;
export const getDocumentsState = state => state.document.documents;

// const getAccount = state => state.account;
// const getApplication = state => state.application;

export function* getDocuments(api, { docType }) {
  const {
    application,
    account,
    token,
    tokenId,
    offlineDocuments
  } = yield select(getPersist);

  const existingDocuments = yield select(getDocumentsState);

  const applicationId = application && application.id;
  const userId = account && account.userId;

  console.log('getDocuments applicationId', applicationId);

  if (applicationId) {
    if (!isNumeric(applicationId)) {
      yield put(
        DocumentActions.DocumentSuccess(offlineDocuments[applicationId])
      );

      // Filter Only DocType Documents
      // if (docType) {
      //   const filteredDocuments = (offlineDocuments[applicationId] || []).filter(
      //     ele => ele.documentType === docType
      //   );
      //   yield put(
      //     DocumentActions.DocumentSuccess(
      //       existingDocuments.set(docType.toString(), filteredDocuments)
      //     )
      //   );
      // } else {
      //   // let newDocuments = {};
      //   // const applicationDocuments = offlineDocuments[applicationId] || [];
      //   // applicationDocuments.forEach(ele => {
      //   //   if (!newDocuments[ele.documentType]) {
      //   //     newDocuments[ele.documentType] = [];
      //   //   }
      //   //   newDocuments[ele.documentType].push(ele);
      //   // });
      //   yield put(
      //     DocumentActions.DocumentSuccess(offlineDocuments[applicationId])
      //   );
      // }
      // yield put(DocumentActions.DocumentSuccess(filteredDocuments));
      // yield put(
      //   DocumentActions.DocumentSuccess(offlineDocuments[applicationId] || [])
      // );
    } else {
      let results = '';
      try {
        // console.log('api.getDocuments', userId, tokenId, token, applicationId, docType)
        results = yield call(
          api.getDocuments,
          userId,
          tokenId,
          token,
          applicationId,
          null
          // docType
        );
      } catch (error) {
        if (R.path(['data', 'payload'], error)) {
          results = error;
        }
      }
      console.log('getDocuments', docType, results);

      // Fix for No Documents Error
      if (R.path(['data', 'error', 0, 'code'], results) === '200') {
        yield put(DocumentActions.DocumentSuccess([]));
      } else if (R.path(['data', 'payload', 0, 'documents'], results)) {
        yield put(
          DocumentActions.DocumentSuccess(results.data.payload[0].documents)
        );

        // if (docType) {
        //   // yield put(
        //   //   DocumentActions.DocumentSuccess(
        //   //     existingDocuments.set(
        //   //       docType.toString(),
        //   //       results.data.payload[0].documents
        //   //     )
        //   //   )
        //   // );
        // } else {
        //   // let allDocs = {};
        //   // results.data.payload[0].documents.forEach(ele => {
        //   //   if (ele && ele.documentType) {
        //   //     if (!allDocs[ele.documentType]) {
        //   //       allDocs[ele.documentType] = [];
        //   //     }
        //   //     allDocs[ele.documentType].push(ele);
        //   //   }
        //   // });
        //   // // console.log('allDocs', allDocs);
        //   // yield put(DocumentActions.DocumentSuccess(allDocs));
        //   yield put(
        //     DocumentActions.DocumentSuccess(results.data.payload[0].documents)
        //   );
        // }
        // yield put(
        //   DocumentActions.DocumentSuccess(results.data.payload[0].documents)
        // );
      } else if (R.path(['data', 'error', 0, 'message'], results)) {
        yield put(
          DocumentActions.DocumentFailure(results.data.error[0].message)
        );
      } else {
        yield put(DocumentActions.DocumentFailure('Unknown Error'));
      }
    }
  }
}

export function* uploadSignature(
  api,
  { personId, signature, documentTypeCode, keywordValueCode, documentId }
) {
  // const filePathBase = Platform.OS === 'ios'
  //   ? RNFS.TemporaryDirectoryPath
  //   : RNFS.DocumentDirectoryPath

  console.log('uploadSignature personId', personId);
  const filePathBase = RNFS.DocumentDirectoryPath;

  const filename = `${filePathBase}/${uuid()}.png`;

  // Write Encoded Contents to file for Cross Platform Compatibility
  console.log('writing signature', filename, signature);
  yield call(RNFS.writeFile, filename, signature.encoded, 'base64');

  // const file = yield call(RNFS.readFile, filename, 'base64')
  // console.log(file)

  const documents = [{ uri: filename }];

  yield call(uploadDocuments, api, {
    personId,
    documents,
    documentTypeCode,
    keywordValueCode,
    documentId
  });
}

export function* uploadDocuments(
  api,
  { personId, documents, documentTypeCode, keywordValueCode }
) {
  const {
    application,
    account,
    token,
    tokenId,
    offlineDocuments
  } = yield select(getPersist);

  const applicationId = application && application.id;
  const userId = account && account.userId;
  console.log('uploadDocuments applicationId', applicationId);
  console.log('userId', userId);
  console.log('personId', personId);

  // Resize/Recompress Images
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    for (let i = 0; i < documents.length; i++) {
      // height:4032
      // iOS: uri:"file:///var/mobile/Containers/Data/Application/A8FAFC31-D8F2-4D6B-8EBA-D75E2051F313/Library/Caches/Camera/F1A7AFCC-EE43-4C23-A29F-17BC6547D796.jpg"
      // width:2268
      if (
        documents[i].height > IMAGE_RESIZE_MAX ||
        documents[i].width > IMAGE_RESIZE_MAX
      ) {
        const sourcePath = documents[i].uri.replace('file://', '');
        console.log(documents[i]);
        const results = yield call(RNFetchBlob.fs.stat, sourcePath);
        const originalSize = results.size;
        console.log('TCL: originalSize', originalSize);

        let resizedResults = yield call(
          ImageResizer.createResizedImage,
          sourcePath, // imageUri
          IMAGE_RESIZE_MAX, // documents[i].width, // newWidth
          IMAGE_RESIZE_MAX, // documents[i].height, // newHeight
          'JPEG', // or 'PNG' // compressFormat
          IMAGE_RESIZE_QUALITY, // quality - ignored for PNG
          0, // rotation
          RNFS.DocumentDirectoryPath // outputPath
        );
        const newSize = resizedResults;
        console.log('TCL: resizedResults', resizedResults);

        if (newSize > originalSize) {
          console.log('File got bigger.  Delete new file.');
          // File got bigger.  Delete new file.
          yield call(RNFS.unlink, resizedResults.path);
        } else {
          console.log(
            'File is smaller.  Delete original file and update document.'
          );
          // File is smaller.  Delete original file and update document.
          const newFileStats = yield call(getImageSize, resizedResults.uri);

          yield call(RNFS.unlink, sourcePath);
          documents[i].width = newFileStats.width;
          documents[i].height = newFileStats.height;
          documents[i].uri = resizedResults.uri;
        }
      }
    }
  }

  if (!isNumeric(applicationId)) {
    // Offline Documents
    let newOfflineDocuments = JSON.parse(JSON.stringify(offlineDocuments));
    if (JSON.stringify(offlineDocuments) === '[]') {
      console.log('fixing object');
      newOfflineDocuments = {};
    }

    // Set Default offlineDocuments
    if (!newOfflineDocuments[applicationId]) {
      newOfflineDocuments[applicationId] = [];
    }
    console.log(
      'uploadDocuments saga',
      documentTypeCode,
      keywordValueCode,
      documents
    );

    documents.forEach((ele, idx) => {
      const pageOrder = idx + 1;
      const offlineFilename = ele.uri.replace(/^.*[\\\/]/, '');

      // Don't store the base64 encode.
      delete ele.base64;

      newOfflineDocuments[applicationId].push({
        id: '-1',
        personId,
        documentType: documentTypeCode,
        keywordValue: keywordValueCode,
        pageOrder,
        documentPageId: '-1',
        fileName: offlineFilename,
        file: ele,
        fileDownloadURL: ele.uri
      });
    });

    // Filter Only DocType Documents
    // const filteredDocuments = (newOfflineDocuments[applicationId] || []).filter(ele => ele.documentType === documentTypeCode);
    yield put(PersistActions.PersistSetOfflineDocuments(newOfflineDocuments));
    yield put(
      DocumentActions.DocumentSuccess(newOfflineDocuments[applicationId])
    );
  } else {
    let results = '';
    try {
      console.log('uploadDocuments', keywordValueCode, documents);

      const data = new FormData();

      data.append('PersonID', personId); // Applicant?
      data.append('DocumentTypeCode', documentTypeCode); // Proof of Primary Residence
      // data.append('DocumentID', documentId || '-1')
      data.append('KeywordValueCode', keywordValueCode); // Driver's Lience
      data.append('KeywordSortOrder', '1');

      const filePathBase = RNFS.DocumentDirectoryPath;

      const allDocuments = yield select(getDocumentsState);
      // Find existing documentId if exists.  We are assuming that Documents have already been fetched.
      let documentId = '-1';
      let pageOrder = 1;
      if (allDocuments) {
        allDocuments.forEach(file => {
          if (
            file &&
            file.keywordValue === keywordValueCode &&
            file.documentType === documentTypeCode &&
            file.personId === personId
          ) {
            documentId = file.id || '-1';
            pageOrder++;
          }
        });
      }

      for (let i = 0; i < documents.length; i++) {
        let ele = documents[i];
        let filename;
        console.log('ele', ele);

        data.append('PageOrder', (i + pageOrder).toString());
        if (Platform.OS === 'web') {
          // If photo is remote, then download and re-upload it
          // if (typeof ele === 'string' && ele.indexOf('http') === 0) {
          //   filename = `${uuid()}.jpg`;
          //   const sourceFile = getDocumentURL(ele, userId, tokenId, token);
          //   const destFile = `${filePathBase}/${filename}`;
          //   console.log('downloading', sourceFile, destFile);
          //   const res = yield call(
          //     downloadFileFromUrlToPath,
          //     sourceFile,
          //     destFile
          //   );
          //   console.log('done downloading', res);
          //   ele = destFile;
          // }
          console.log('WEB: remote url:', ele);
          data.append('file', ele);
        } else {
          // Standardize Filenames between Android and iOS.
          filename = ele.uri.replace(/^.*[\\\/]/, '');

          // If photo is remote, then download and re-upload it
          if (ele.uri.indexOf('http') === 0) {
            filename = `${uuid()}.jpg`;
            const sourceFile = getDocumentURL(ele.uri, userId, tokenId, token);
            const destFile = `${filePathBase}/${filename}`;
            console.log('downloading', sourceFile, destFile);
            const res = yield call(
              downloadFileFromUrlToPath,
              sourceFile,
              destFile
            );
            console.log('done downloading', res);
            ele.uri = destFile;
          }

          const ext = filename
            .split('.')
            .pop()
            .toLowerCase();
          const mimeType =
            ext === 'jpg'
              ? 'image/jpeg'
              : ext === 'jpeg'
              ? 'image/jpeg'
              : ext === 'png'
              ? 'image/png'
              : null;

          data.append('file', {
            uri:
              Platform.OS === 'android' && ele.uri.indexOf('/') === 0
                ? `file://${ele.uri}`
                : ele.uri,
            type: mimeType,
            name: filename
          });
        }
      }

      // console.log(
      //   'api.uploadDocuments',
      //   userId,
      //   tokenId,
      //   token,
      //   applicationId,
      //   documentId,
      //   data
      //   // JSON.stringify(data, null, 2)
      // );

      console.log('api.uploadDocuments userId', userId);
      console.log('tokenId', tokenId);
      console.log('token', token);
      console.log('applicationId', applicationId);
      console.log('documentId', documentId);
      if (Platform.OS === 'web') {
        // Display the key/value pairs
        for (var pair of data.entries()) {
          console.log(pair[0] + ', ' + pair[1]);
        }
      } else {
        console.log(data);
      }

      results = yield call(
        api.uploadDocuments,
        userId,
        tokenId,
        token,
        applicationId,
        documentId,
        data
      );
      console.log('upload results', results);
    } catch (error) {
      console.log('error', error);
      if (R.path(['data', 'payload', 0, 'documents', 0, 'id'], error)) {
        results = error;
      }
    }

    if (R.path(['data', 'payload', 0, 'documents', 0, 'id'], results)) {
      // yield put(DocumentActions.DocumentSuccess(results.data.payload[0]))
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      console.log('error', results.data.error[0].message);
      yield put(DocumentActions.DocumentFailure(results.data.error[0].message));
      return;
    } else {
      console.log('error', 'unknown');
      yield put(DocumentActions.DocumentFailure('Unknown Error'));
      return;
    }

    // yield call(getDocuments, api, { docType: documentTypeCode });
    yield call(getDocuments, api, { docType: null });

    //   try {
    //     results = yield call(
    //       api.getDocuments,
    //       userId,
    //       tokenId,
    //       token,
    //       applicationId
    //     );
    //   } catch (error) {
    //     if (R.path(['data', 'payload'], error)) {
    //       results = error;
    //     }
    //   }
    //   console.log('refresh', results);

    //   if (R.path(['data', 'payload', 0, 'documents'], results)) {
    //     let newDocuments = {};
    //     newDocuments[documentTypeCode] = results.data.payload[0].documents;
    //     yield put(
    //       DocumentActions.DocumentSuccess(
    //         newDocuments,
    //         DocumentTypes.DOCUMENT_UPLOAD
    //       )
    //     );
    //   } else if (R.path(['data', 'error', 0, 'message'], results)) {
    //     yield put(
    //       DocumentActions.DocumentFailure(results.data.error[0].message),
    //       DocumentTypes.DOCUMENT_UPLOAD
    //     );
    //   } else {
    //     yield put(
    //       DocumentActions.DocumentFailure('Unknown Error'),
    //       DocumentTypes.DOCUMENT_UPLOAD
    //     );
    //   }
  }
}

export function* deleteDocuments(
  api,
  { personId, documentTypeCode, keywordValueCode }
) {
  const {
    application,
    account,
    token,
    tokenId,
    offlineDocuments
  } = yield select(getPersist);
  const applicationId = application && application.id;
  const userId = account && account.userId;

  if (!isNumeric(applicationId)) {
    // Offline Documents
    const newOfflineDocuments =
      JSON.parse(JSON.stringify(offlineDocuments)) || {};

    if (!newOfflineDocuments[applicationId]) {
      newOfflineDocuments[applicationId] = [];
    }
    // Search Documents for Matching ApplicationId, PersonId and DocType
    const newDocs = newOfflineDocuments[applicationId].filter(
      ele =>
        !(ele.personId === personId && ele.documentType === documentTypeCode)
    );

    newOfflineDocuments[applicationId] = newDocs;

    // Filter Only DocType Documents
    // const filteredDocuments = (newOfflineDocuments[applicationId] || []).filter(ele => ele.documentType === documentTypeCode);

    yield put(PersistActions.PersistSetOfflineDocuments(newOfflineDocuments));
    // yield put(DocumentActions.DocumentSuccess(filteredDocuments));
    yield put(
      DocumentActions.DocumentSuccess(newOfflineDocuments[applicationId] || [])
    );
  } else {
    let results = '';
    // Load Existing Documents
    try {
      console.log(
        'api.getDocuments',
        userId,
        tokenId,
        token,
        applicationId,
        documentTypeCode
      );
      results = yield call(
        api.getDocuments,
        userId,
        tokenId,
        token,
        applicationId,
        documentTypeCode
      );
    } catch (error) {
      if (R.path(['data', 'payload'], error)) {
        results = error;
      }
    }

    let documents = [];
    // Fix for No Documents Error
    if (R.path(['data', 'error', 0, 'code'], results) === '200') {
      documents = [];
    } else if (R.path(['data', 'payload', 0, 'documents'], results)) {
      documents = results.data.payload[0].documents;
    } else if (R.path(['data', 'error', 0, 'message'], results)) {
      yield put(DocumentActions.DocumentFailure(results.data.error[0].message));
    } else {
      yield put(DocumentActions.DocumentFailure('Unknown Error'));
    }

    console.log('documents', documents);
    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      if (
        doc.personId === personId &&
        doc.documentType === documentTypeCode &&
        (keywordValueCode === '' || doc.keywordValue === keywordValueCode)
      ) {
        console.log(
          'api.deleteRecord',
          'Document',
          userId,
          tokenId,
          token,
          applicationId,
          doc.id
        );
        results = yield call(
          api.deleteRecord,
          'Document',
          userId,
          tokenId,
          token,
          applicationId,
          doc.id
        );
        console.log(results);
      }
    }

    yield call(getDocuments, api, { documentTypeCode });
  }
}
