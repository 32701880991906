import React, { Component } from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scrollview';
import Immutable from 'seamless-immutable';

import { View, Text } from 'react-native';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Grid, Row, Col } from '../Components/Grid';

import FormattedMessage from '../Components/FormattedMessage';
import FullButton from '../Components/FullButton';

// Styles
import { Fonts, Metrics } from '../Themes/';
import styles from './Styles/ScreenStyles';

class YouDontQualifyScreen extends Component {
  renderCheckboxes() {
    const {
      screeningQuestions,
      location: { search }
    } = this.props;

    const uncheckedArray = search.replace('?unchecked=', '').split(',');
    console.log(uncheckedArray);

    // Filter out objects without SortOrder
    const newArray = Immutable.asMutable(
      screeningQuestions.filter(ele => ele.SortOrder)
    );
    // Sort
    newArray.sort((a, b) =>
      a.SortOrder > b.SortOrder ? 1 : b.SortOrder > a.SortOrder ? -1 : 0
    );

    return newArray.map((ele, idx) => {
      return (
        uncheckedArray.includes(idx.toString()) && (
          <View style={styles.liWrapper} key={`screening_question_${idx}`}>
            <Text style={styles.liBullet}>{'\u2022'}</Text>
            <Text style={styles.liText}>{ele.ScreeningQuestion}</Text>
          </View>
        )
      );
    });
  }

  render() {
    const {
      initilized,
      invalid,
      screeningQuestions,
      history,
      location: { search }
    } = this.props;

    const uncheckedArray = search.replace('?unchecked=', '').split(',');
    console.log(uncheckedArray);
    return (
      <View style={styles.mainContainer}>
        <KeyboardAwareScrollView
          ref={scroll => {
            this._scroll = scroll;
          }}
          keyboardShouldPersistTaps="handled"
          style={styles.scrollViewContainer}
        >
          <Grid>
            <Row>
              <Col>
                <FormattedMessage
                  id="YouDontQualify.title"
                  style={Fonts.style.title}
                />
                <FormattedMessage
                  id="YouDontQualify.subtitle"
                  style={Fonts.style.subtitle}
                />
                <FormattedMessage
                  id="YouDontQualify.subtitle2"
                  style={Fonts.style.subtitle}
                />
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>{this.renderCheckboxes()}</Col>
            </Row>
            <Row>
              <Col sm={12}>
                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
                <FullButton
                  text={I18n.t('buttons.ApplyAnyway')}
                  onPress={() => history.push('/ProgramReferralScreen')}
                />

                <FullButton
                  text={I18n.t('buttons.NotifyMe')}
                  onPress={() => history.push('/NotifyMeOfOtherProgramsScreen')}
                  type="small"
                />

                <FullButton
                  text={I18n.t('buttons.CancelApplication')}
                  onPress={() => history.push('/')}
                  type="small"
                />

                <FullButton
                  text={I18n.t('buttons.WhyDontIQualify')}
                  onPress={() =>
                    history.push('/WhyDontIQualifyScreen' + search)
                  }
                  type="link"
                />

                <View style={{ marginTop: Metrics.doubleBaseMargin }} />
              </Col>
            </Row>
          </Grid>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const mapStateToProps = ({ persist: { application, screeningQuestions } }) => ({
  application,
  screeningQuestions
});

export default connect(mapStateToProps)(YouDontQualifyScreen);
