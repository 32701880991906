import isDev from '../Lib/isDev';

const dev = isDev();

export default {
  useFixtures: false,
  ezLogin: false,
  yellowBox: false,
  reduxLogging: dev,
  includeExamples: false,
  useReactotron: dev
};
